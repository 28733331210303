import { useState } from "react";
import Button from "../../../components/form/Button";
import FormTextarea from "../../../components/form/FormTextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const CarNotes = ({ car, handleNotesSubmit }) => {
  const [notes, setNotes] = useState(car.notes);
  const [isEditingNotes, setEditingNotes] = useState(false);

  const handleSubmit = () => {
    handleNotesSubmit(notes, car.id);
    setEditingNotes(!isEditingNotes);
  };

  return (
    <div>
      <div className='flex items-center justify-between mb-2'>
        <span className='text-lg font-bold'>Notes</span>
        <div
          className='flex items-center gap-1 cursor-pointer'
          onClick={() => setEditingNotes(!isEditingNotes)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
          Edit
        </div>
      </div>
      <div className='mt-2'>
        <FormTextarea
          rows={12}
          value={notes}
          disabled={!isEditingNotes}
          handleChange={(value) => setNotes(value)}
        />
        {isEditingNotes && (
          <div className='flex gap-2 justify-end'>
            <Button
              classes={"bg-gray-200 text-gray-900 px-2 py-1"}
              handleClick={() => setEditingNotes(!isEditingNotes)}
            >
              Cancel
            </Button>
            <Button classes={"bg-blue-500 text-white px-2 py-1"} handleClick={handleSubmit}>
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarNotes;
