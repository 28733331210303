import Filters from "../components/Filters";
import DamagesTable from "../components/DamagesTable";

const DamagesPageView = ({
  damages,
  sorting,
  filters,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleToggleFixed,
  handleFilterChange,
  handleClearFilters,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <div className='page-title'>
            <span className='title'>DAMAGES</span>
          </div>
        )}
        <Filters
          filters={filters}
          isExternalPage={isExternalPage}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
        <div className='table-wrapper'>
          <DamagesTable
            damages={damages}
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handleToggleFixed={handleToggleFixed}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DamagesPageView;
