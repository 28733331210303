import { TYPES } from "../actions/session";

export const session = (state = null, action = {}) => {
  switch (action.type) {
    case TYPES.USER_LOGIN:
      return { ...state, session: action.session };
    case TYPES.USER_LOGOUT:
      return null;
    default:
      return state;
  }
};

export default session;
