import Parse from "parse";
import { useState } from "react";
import "../../../styles/global.css";
import Utils from "../../../utils/Utils";
import Modal from "../../../components/Modal";
import Discount from "../../../models/Discount";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { userSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const AddUserDiscountsModal = ({
  users,
  isOpen,
  isLoading,
  toggleModal,
  setSelection,
  handleAddDiscounts,
  handleRemoveRecipient,
}) => {
  const [addDiscountsSuccess, setSAddDiscountsSucces] = useState(false);
  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });

  const typeOptions = Object.keys(Discount.TYPE).map((key) => ({
    value: key,
    label: Utils.textFirstOnlyUpper(key),
  }));

  const [form, setForm] = useState({
    type: "",
    amount: "",
  });

  const handleFormChange = (field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    handleAddDiscounts(users, form).then((r) => {
      if (r) {
        setSAddDiscountsSucces(true);
        setTimeout(() => {
          setForm({
            type: "",
            amount: "",
          });
          toggleModal(false);
          setSelection && setSelection([]);
          setSAddDiscountsSucces(false);
        }, 1500);
      } else {
        setSAddDiscountsSucces(false);
        setResponseState({
          type: false,
          message: "Something went wrong. Try again.",
        });
      }
    });
  };

  const handleCancel = () => {
    toggleModal(false);
    setForm({
      type: "",
      amount: "",
    });
    setResponseState({ type: null, message: null });
  };

  const getUserData = (userId) => {
    return userSerializer(new Parse.Object("_User").set("objectId", userId));
  };

  return (
    <Modal
      open={isOpen}
      title='Add Discount'
      classes={"w-4/5 md:w-1/2 xl:w-1/3"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='grid grid-cols-1 gap-1'>
        {!!users && (
          <>
            <div className='mb-2'>
              <span className='text-text-main'>Recepients:</span>
            </div>
            <div className='flex flex-wrap items-center gap-1 mb-1 p-1 max-h-20 overflow-scroll bg-bg-input rounded border border-solid border-border-input'>
              {users.map((id) => {
                const user = getUserData(id);
                return (
                  <div
                    key={id}
                    className='flex items-center rounded-full bg-gray-100 bg-opacity-70 px-2 py-1 transition-all ease-in-out duration-200 hover:bg-blue-100 hover:bg-opacity-50'
                  >
                    <span className='text-sm'>{user.username}</span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={`ml-1 w-[10px] h-[10px] ${
                        handleRemoveRecipient ? "cursor-pointer" : "cursor-not-allowed"
                      } p-1 rounded-full hover:bg-white`}
                      onClick={() => (handleRemoveRecipient ? handleRemoveRecipient(id) : null)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        <FormInput
          required
          type='number'
          label='Ammount'
          value={form.amount}
          handleChange={(e) => handleFormChange("amount", e.target.value)}
        />
        <FormSelect
          label='Type'
          value={!Utils.isNull(form.type) ? findOption(typeOptions, form.type) : null}
          options={typeOptions}
          handleChange={(value) => handleFormChange("type", value.value)}
        />
        {!Utils.isNull(responseState.type) && (
          <div className='mt-1'>
            <span
              className={`text-sm font-bold ${
                responseState.type ? "text-green-400" : "text-red-400"
              }`}
            >
              {responseState.message}
            </span>
          </div>
        )}
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            disabled={isLoading || addDiscountsSuccess}
            classes={`bg-blue-500 py-2 ${isLoading ? "pr-7" : "pr-2"}`}
          >
            <span className='text-white'>Add</span>
            {addDiscountsSuccess && (
              <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserDiscountsModal;
