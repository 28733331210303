import { BorderColor } from "@mui/icons-material";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const FormSelect = ({
  value,
  label,
  errors,
  isAsync,
  options,
  classes,
  disabled,
  loadOptions,
  isClearable,
  isSearchable,
  placeholder,
  handleChange,
  isOptionDisabled,
  handleInputChange,
}) => {
  const customStyles = {
    singleValue: (base) => ({
      ...base,
      color: "rgba(var(--text-main))",
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      backgroundColor: "rgba(var(--bg-input))",
      border: !!errors ? "1px solid rgba(211, 47, 47, 1)" : "1px solid rgba(var(--border-input))",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        border: "1px solid rgba(var(--border-input-hover))",
      },
      "&:focus": {
        border: "1px solid rgba(var(--border-input-focus))",
      },
    }),
    menu: (base) => ({
      ...base,
      margin: "2px 0",
      color: "rgba(var(--text-main))",
      backgroundColor: "rgba(var(--bg-input))",
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        cursor: "pointer",
        color: isSelected && "rgba(var(--text-main))",
        backgroundColor: isFocused && "rgba(var(--bg-select-option-hover))",
      };
    },
  };

  return (
    <div className='w-full h-full form-control relative'>
      {label && <label className='pl-1 mb-1'>{label}</label>}
      {isAsync ? (
        <AsyncSelect
          className={classes}
          isDisabled={disabled}
          onChange={handleChange}
          isClearable={isClearable}
          loadOptions={loadOptions}
          placeholder={placeholder}
          onInputChange={handleInputChange}
        />
      ) : (
        <Select
          value={value}
          options={options}
          className={classes}
          menuPlacement='bottom'
          isDisabled={disabled}
          styles={customStyles}
          onChange={handleChange}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          onInputChange={handleInputChange}
          isOptionDisabled={isOptionDisabled}
        />
      )}
      {errors && (
        <div className='error'>
          <span>{errors}</span>
        </div>
      )}
    </div>
  );
};

export default FormSelect;
