import EuroIcon from "@mui/icons-material/Euro";

const RevenueMetricsBlock = ({ revenueTotals }) => {
  return (
    <div className='dashboard-block'>
      <div className='title'>
        <span>Revenue</span>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Bill total</span>
          </div>
          {revenueTotals?.totalBill ? (
            <div className='flex items-center gap-1'>
              <span className='text-semibold'>{revenueTotals?.totalBill}</span>
              <EuroIcon fontSize='small' />
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Unpaid total</span>
          </div>
          {revenueTotals?.totalUnpaid ? (
            <div className='flex items-center gap-1'>
              <span className='text-semibold'>{revenueTotals?.totalUnpaid}</span>
              <span>
                {"(" +
                  parseFloat((revenueTotals?.totalUnpaid / revenueTotals?.totalBill) * 100).toFixed(
                    2
                  ) +
                  "%)"}
              </span>
              <EuroIcon fontSize='small' />
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Paid total</span>
          </div>
          {revenueTotals?.totalPaid ? (
            <div className='flex items-center gap-1'>
              <span className='text-semibold'>{revenueTotals?.totalPaid}</span>
              <span>
                {"(" +
                  parseFloat((revenueTotals?.totalPaid / revenueTotals?.totalBill) * 100).toFixed(
                    2
                  ) +
                  "%)"}
              </span>
              <EuroIcon fontSize='small' />
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueMetricsBlock;
