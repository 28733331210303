import { useState } from "react";
import "./styles/ImageLightbox.scss";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";

const ImageLightbox = ({ index, slides, setIndex }) => {
  const [rotationAngle, setRotationAngle] = useState(0);
  const handleView = ({ index }) => {
    setIndex(index);
  };

  const handleExited = () => {
    setIndex(-1);
    setRotationAngle(0);
  };

  const handleRotateLeft = () => {
    setIndex((prevIndex) => {
      return prevIndex;
    });
    setRotationAngle((prevAngle) => (prevAngle <= 0 ? 270 : prevAngle - 90));
  };

  const handleRotateRight = () => {
    setRotationAngle((prevAngle) => (prevAngle >= 270 ? 0 : prevAngle + 90));
  };

  return (
    <Lightbox
      plugins={[Zoom]}
      index={index}
      open={index >= 0}
      slides={slides}
      className={`rotated${rotationAngle}`}
      on={{ view: handleView, exited: handleExited }}
      toolbar={{
        buttons: [
          <button
            key='rotate-left'
            type='button'
            className='yarl__button'
            onClick={handleRotateLeft}
          >
            <FontAwesomeIcon icon={faRotateLeft} size='lg' />
          </button>,
          <button
            key='rotate-right'
            type='button'
            className='yarl__button'
            onClick={handleRotateRight}
          >
            <FontAwesomeIcon icon={faRotateRight} size='lg' />
          </button>,
          "close",
        ],
      }}
    />
  );
};

export default ImageLightbox;
