import dayjs from "dayjs";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import { getViewportWidth } from "../../../utils/Viewport";
import FormSelect from "../../../components/form/FormSelect";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const windowSize = getViewportWidth();
  const userMessagesTypeOptions = Object.keys(User.MESSAGE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const userMessagesDefaultOption = !Utils.isNull(filters.type)
    ? findOption(userMessagesTypeOptions, String(filters.type)?.toUpperCase())
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormInput
                type='text'
                label='Recipient'
                classes='w-full'
                placeholder='Recipient'
                value={Utils.isNull(filters?.recipient) ? "" : filters?.recipient}
                handleChange={(e) => handleFilterChange("recipient", e.target.value)}
              />
            </div>
            <div>
              <FormInput
                type='text'
                label='Sender'
                classes='w-full'
                placeholder='Sender'
                value={Utils.isNull(filters?.sender) ? "" : filters?.sender}
                handleChange={(e) => handleFilterChange("sender", e.target.value)}
              />
            </div>
            <div>
              <FormSelect
                label='Type'
                classes='w-full'
                isClearable={true}
                options={userMessagesTypeOptions}
                value={userMessagesDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("type", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
            <div className='col-span-1 md:col-span-2 lg:col-span-4 flex items-end'>
              <FormCheckbox
                label='Is Seen'
                value={filters.isSeen}
                handleChange={(checked) => handleFilterChange("isSeen", checked)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
