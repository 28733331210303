export const getViewportWidth = () => {
  const windowWidth = window.innerWidth;
  switch (true) {
    case windowWidth < 640:
      return "MOBILE";
    case windowWidth >= 640 && windowWidth < 768:
      return "SM";
    case windowWidth >= 768 && windowWidth < 1024:
      return "MD";
    case windowWidth >= 1024 && windowWidth < 1280:
      return "LG";
    case windowWidth >= 1280 && windowWidth < 1536:
      return "XL";
    case windowWidth >= 1536:
      return "2XL";
    default:
      return false;
  }
};

export const isBelow = (breakpoint) => {
  const windowWidth = window.innerWidth;

  switch (true) {
    case breakpoint === "SM":
      return windowWidth <= 640;
    case breakpoint === "MD":
      return windowWidth <= 768;
    case breakpoint === "LG":
      return windowWidth <= 1024;
    case breakpoint === "XL":
      return windowWidth <= 1280;
    default:
      return false;
  }
};

export const isAbove = (breakpoint) => {
  const windowWidth = window.innerWidth;

  switch (true) {
    case breakpoint === "SM":
      return windowWidth >= 640;
    case breakpoint === "MD":
      return windowWidth >= 768;
    case breakpoint === "LG":
      return windowWidth >= 1024;
    default:
      return false;
  }
};
