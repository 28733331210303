import Parse from "parse";
import { useFormik } from "formik";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import { useParams } from "react-router-dom";
import { object, date, ref, string } from "yup";
import Loader from "../../../components/Loader";
import useRenting from "../../../hooks/useRenting";
import RentingModel from "../../../models/Renting";
import RentingPageView from "../view/RentingPageView";
import useToaster from "../../../hooks/useToaster.js";
import useRentalReport from "../../../hooks/useRentalReport.js";
import { countrySerializer, rentingSerializer } from "../../../models/serializers";

const RentingPageState = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const rentingHook = useRenting();
  const [car, setCar] = useState();
  const { rentingId } = useParams();
  const [trip, setTrip] = useState(null);
  const [driver, setDriver] = useState();
  const [renting, setRenting] = useState();
  const rentalReportHook = useRentalReport();
  const session = userHook.getCurrentSession();
  const [totalBill, setTotalBill] = useState();
  const [rentingRaw, setRentingRaw] = useState();
  const [isLoading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingReport, setLoadingReport] = useState(false);

  const validationSchema = object().shape({
    startLocation: string().required("Start coordinates is required"),
    endLocation: string().required("End coordinates is required"),
    startTime: date().required("Start time is required"),
    endTime: date()
      .required("End time is required")
      .min(ref("startTime"), "End time cannot be earlier than start time"),
  });

  const formik = useFormik({
    initialValues: {
      bill: "",
      totalTime: "",
      distance: "",
      startAddress: "",
      endAddress: "",
      startTime: null,
      endTime: null,
      startLocation: "",
      endLocation: "",
      peopleCount: "",
      reasonOfUse: "",
      starRating: "",
    },
    validationSchema: validationSchema,
  });

  const getRenting = async () => {
    setLoading(true);
    const Renting = Parse.Object.extend("Rentings");
    const query = new Parse.Query(Renting);
    query.equalTo("objectId", rentingId);
    query.includeAll();

    try {
      const response = await query.first({ useMasterKey: true });
      setRentingRaw(response);
      const billCalculated = (await RentingModel.provideTotalBill([response]))[0];
      setTotalBill({
        bill: billCalculated.attributes.bill,
        discount: billCalculated.attributes.discount,
        totalBill: billCalculated.attributes.totalBill,
      });

      let r = rentingSerializer(response);

      if (r.driver.country) {
        const country = countrySerializer(
          await new Parse.Query("Continentscountriescities_Country")
            .equalTo("objectId", r.driver.country.id)
            .first()
        );
        r = { ...r, driver: { ...r.driver, country: country } };
      }

      const u = r.driver;
      const c = r.car;
      setRenting(r);
      setDriver(u);
      setCar(c);

      formik.setValues({
        bill: r.bill,
        totalTime: r.time.totalTime,
        distance: r.distance,
        startAddress: r.address.startAddress,
        endAddress: r.address.endAddress,
        startTime: r.time.startRaw,
        endTime: r.time.endRaw ? r.time.endRaw : null,
        startLocation: r.location.start.latitude + "," + r.location.start.longitude,
        endLocation:
          r.location.end.latitude && r.location.end.longitude
            ? r.location.end.latitude + "," + r.location.end.longitude
            : null,
        peopleCount: r.feedback.peopleCount,
        reasonOfUse: r.feedback.reasonOfUse,
        starRating: r.feedback.starRating,
      });
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const getTrip = async () => {
    const rentingPointer = {
      __type: "Pointer",
      className: "Rentings",
      objectId: rentingId,
    };

    const trip = await new Parse.Query("Trip").equalTo("renting", rentingPointer).first();

    setTrip(trip);
  };

  const getIncidents = async () => {
    try {
      const rentingPointer = {
        __type: "Pointer",
        className: "Rentings",
        objectId: rentingId,
      };

      const incidents = await new Parse.Query("Incident").equalTo("renting", rentingPointer).find();

      incidents.length > 0 && setIncidents(incidents);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSave = async () => {
    if (!formik.isValid) {
      Object.keys(formik.errors).forEach((key) => toaster.error(formik.errors[key]));
    } else {
      setLoading(true);
      try {
        const carPrice = car.price;
        const startTime = new Date(formik.values.startTime);
        const endTime = new Date(formik.values.endTime);
        // Total time is in seconds. This will be stored in the database.
        const totalTime = Math.floor((endTime.getTime() - startTime.getTime()) / 1000);
        // Passed min is total time in minutes. This will be sent to cloud function to calculate total bill.
        const passedMin = Math.ceil(totalTime / 60);

        const startLatitude = formik.values.startLocation.split(",")[0].trim();
        const startLongitude = formik.values.startLocation.split(",")[1].trim();
        const endLatitude = formik.values.endLocation.split(",")[0].trim();
        const endLongitude = formik.values.endLocation.split(",")[1].trim();

        const startAddress = await Parse.Cloud.run("getAddress", {
          latitude: startLatitude,
          longitude: startLongitude,
        });
        const endAddress = await Parse.Cloud.run("getAddress", {
          latitude: endLatitude,
          longitude: endLongitude,
        });

        const distance = (
          await Parse.Cloud.run("calculateDistanceTwoPoints", {
            startAddress: encodeURIComponent(startAddress.trim()),
            endAddress: encodeURIComponent(endAddress.trim()),
          })
        ).distance.value;

        const params = { passedMin: passedMin, price: carPrice };
        const bill = await Parse.Cloud.run("billCalculator", params);

        const Renting = new Parse.Object("Rentings");
        Renting.set("objectId", renting.id);
        Renting.set("bill", bill);
        Renting.set("startTime", startTime);
        Renting.set("endTime", endTime);
        Renting.set("totalTime", totalTime);
        Renting.set("distance", Number(distance));
        Renting.set("startLatitude", Number(startLatitude));
        Renting.set("startLongitude", Number(startLongitude));
        Renting.set("endLatitude", Number(endLatitude));
        Renting.set("endLongitude", Number(endLongitude));
        Renting.set("startAddress", startAddress);
        Renting.set("endAddress", endAddress);
        Renting.set("isFinished", true);
        Renting.set("status", 1);
        await Renting.save().then(async () => {
          await Logger.editRenting(session, Renting);

          const carId = rentingSerializer(Renting).car.id;
          const Car = new Parse.Object("Car");
          Car.set("objectId", carId);
          Car.set("isRiding", false);
          await Car.save();
        });
        await getRenting();
        setIsEditing(false);
      } catch (e) {
        console.error(e.message);
        toaster.error(e.message);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleToggle = async (field, toggle) => {
    setLoading(true);
    const Renting = new Parse.Object("Rentings");
    Renting.set("objectId", renting.id);
    Renting.set(field, toggle);
    try {
      await Renting.save(null, { useMasterKey: true }).finally(() => {
        getRenting();
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmitDocument = async (document, file) => {
    try {
      let fileBase64 = null;
      let fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(console.error("Problem parsing input file."));
        };

        fileReader.onload = async (fileLoadedEvent) => {
          fileBase64 = fileLoadedEvent.target.result;
          const data = fileBase64.split(",")[1];

          const parseFile = new Parse.File(file.name, { base64: data });

          const newDocument = new Parse.Object("Rentings");
          newDocument.set("objectId", rentingId);
          newDocument.set(document, parseFile);

          await newDocument.save(null, { useMasterKey: true }).then(() => {
            getRenting().then(() => {
              resolve("ok");
            });
          });
        };

        fileReader.readAsDataURL(file);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleNotesSubmit = async (value) => {
    await rentingHook.handleNotesSubmit(value, rentingId);
  };

  const handleDownloadPDF = async () => {
    setLoadingReport(true);

    const PDF = await rentalReportHook.generate(rentingRaw, false);
    const blob = await pdf(PDF).toBlob();
    saveAs(blob, `${renting.driver.username} rentalReport.pdf`);
    await Logger.downloadRentalReport(session, rentingRaw);
    setLoadingReport(false);
    toaster.success("Rental Report downloaded successfully.");
  };

  useEffect(() => {
    getRenting();
    getTrip();
    getIncidents();
  }, []);

  return (
    <>
      {renting && driver && car ? (
        <RentingPageView
          car={car}
          trip={trip}
          form={formik}
          driver={driver}
          renting={renting}
          totalBill={totalBill}
          incidents={incidents}
          isLoading={isLoading}
          isEditing={isEditing}
          rentingRaw={rentingRaw}
          handleEdit={handleEdit}
          handleSave={handleSave}
          handleToggle={handleToggle}
          isLoadingReport={isLoadingReport}
          handleFormChange={handleFormChange}
          handleNotesSubmit={handleNotesSubmit}
          handleDownloadPDF={handleDownloadPDF}
          handleSubmitDocument={handleSubmitDocument}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default RentingPageState;
