import User from "../../models/User";
import Authorizer from "../../components/Authorizer";
import UserMessagesPageState from "./state/UserMessagesPageState";

const UserMessagesPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <UserMessagesPageState {...props} />
    </Authorizer>
  );
};

export default UserMessagesPage;
