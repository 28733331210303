import User from "../../models/User";
import Authorizer from "../../components/Authorizer";
import RentingsPageState from "./state/RentingsPageState";

const RentingsPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <RentingsPageState {...props} />
    </Authorizer>
  );
};

export default RentingsPage;
