import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import DamagesPageView from "../view/DamagesPageView";

const DamagesPageState = ({ carId = null }) => {
  const [damages, setDamages] = useState([]);
  const [filters, setFilters] = useState({});
  const isExternalPage = !Utils.isNull(carId);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [pagination, setPagination] = useState({ page: 0, size: 10 });
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);

  const getDamages = async () => {
    const Damages = Parse.Object.extend("Damage");
    const query = new Parse.Query(Damages);
    query.includeAll();

    if (!!carId) {
      query.equalTo("car", { __type: "Pointer", className: "Car", objectId: carId });
    }

    try {
      if (Object.keys(filters).length > 0) {
        if (filters?.username) {
          let user = await new Parse.Query("_User")
            .matches("username", filters.username.trim(), "i")
            .first({ useMasterKey: true });

          query.equalTo("user", user);
        }
        if (filters?.carPlate) {
          let car = await new Parse.Query("Car")
            .matches("carPlate", filters.carPlate.trim(), "i")
            .first({ useMasterKey: true });

          query.equalTo("car", car);
        }
        Utils.isDefined(filters.isFixed) && query.equalTo("isFixed", filters.isFixed);
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then((r) => {
          const ds = r.results.map((d) => ({
            ...d,
            frontDamage: d.get("frontDamage")?._url,
            backDamage: d.get("backDamage")?._url,
            leftDamage: d.get("leftDamage")?._url,
            rightDamage: d.get("rightDamage")?._url,
            otherDamage: d.get("otherDamage")?._url,
            description: d.get("description"),
            attributes: d.attributes,
          }));
          setDamages(ds);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleToggleFixed = async (toggle, carId) => {
    setLoading(true);
    const Car = new Parse.Object("Damage");
    Car.set("objectId", carId);
    Car.set("isFixed", toggle);
    try {
      await Car.save(null, { useMasterKey: true });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    getDamages();
  }, [isLoading, sorting, pagination]);

  return (
    <>
      {!!damages ? (
        <DamagesPageView
          damages={damages}
          sorting={sorting}
          filters={filters}
          isLoading={isLoading}
          pagination={pagination}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handlePageChange={handlePageChange}
          handleToggleFixed={handleToggleFixed}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default DamagesPageState;
