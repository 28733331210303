import Utils from "../../../utils/Utils";

const RentingMetricsBlock = ({ rentingTotals }) => {
  return (
    <div className='dashboard-block'>
      <div className='title'>
        <span>Rentings</span>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Total Rentings</span>
          </div>
          {rentingTotals?.totalRentings ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalRentings}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Active</span>
          </div>
          {!Utils.isNull(rentingTotals?.totalActive) ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalActive}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Unpaid</span>
          </div>
          {rentingTotals?.totalUnpaid ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalUnpaid}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Without photos</span>
          </div>
          {rentingTotals?.totalNoPhotos ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalNoPhotos}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>With photos</span>
          </div>
          {rentingTotals?.totalPhotos ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalPhotos}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>With rating</span>
          </div>
          {rentingTotals?.totalRatings ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalRatings}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between'>
          <div>
            <span>Split bill</span>
          </div>
          {rentingTotals?.totalSplitBill ? (
            <div>
              <span className='text-semibold'>{rentingTotals?.totalSplitBill}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentingMetricsBlock;
