import Toggler from "../../../components/Toggler";

const CarAmenities = ({
  car,
  isLoading,
  handleToggleBluetooth,
  handleToggleChildSeat,
  handleTogglePetFriendly,
}) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Car Amenities</span>
      </div>
      <div className='car-details'>
        <span>Bluetooth</span>
        <Toggler
          toggle={car.hasBluetooth}
          handleToggle={(toggle) => handleToggleBluetooth(toggle, car.id)}
          disabled={isLoading}
        />
        <span>Child Seat</span>
        <Toggler
          toggle={car.hasChildSeat}
          handleToggle={(toggle) => handleToggleChildSeat(toggle, car.id)}
          disabled={isLoading}
        />
        <span>Pet Friendly</span>
        <Toggler
          toggle={car.isPetFriendly}
          handleToggle={(toggle) => handleTogglePetFriendly(toggle, car.id)}
          disabled={isLoading}
        />
      </div>
    </>
  );
};

export default CarAmenities;
