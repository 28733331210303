import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import LoginPageView from "../view/LoginPageView";

const LoginPageState = () => {
  const userHook = useUser();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const userRoles = userHook.getUserRoles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [OTPstatus, setOTPstatus] = useState(null);
  const [OTPverified, setOTPverified] = useState(null);

  const handleUsernameChange = (e) => {
    setLoading(false);
    setUsername(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setLoading(false);
    setPassword(e.target.value.trim());
  };

  const handleLogin = async () => {
    setErrors(null);
    setLoading(true);
    try {
      if (!!!OTPverified) {
        Parse.Cloud.run("requestEmailVerification", { userData: username }).then((r) => {
          setOTPstatus(r.status);
        });
      } else {
        userHook.login(username, password).then((r) => {
          setLoading(false);
          if (r) {
            navigate("/cars");
          } else {
            setErrors("Access denied.");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      setErrors(error.message);
    }
  };

  const handleVerifyOTP = async (token) => {
    setOTPverified(null);
    Parse.Cloud.run("verifyOTP", {
      userToken: token,
      userData: username,
      verificationType: "email",
    }).then((r) => {
      setOTPverified(r);
      if (r) setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const user = Parse.User.current();
        const session = await Parse.Session.current();

        if (!Utils.isNull(user) && !Utils.isNull(session)) {
          userRoles.includes("Admin") || userRoles.includes("Viewer")
            ? navigate("/")
            : navigate("/cars");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <LoginPageView
      errors={errors}
      username={username}
      password={password}
      isLoading={isLoading}
      OTPstatus={OTPstatus}
      OTPverified={OTPverified}
      handleLogin={handleLogin}
      handleVerifyOTP={handleVerifyOTP}
      setUsername={handleUsernameChange}
      setPassword={handlePasswordChange}
    />
  );
};

export default LoginPageState;
