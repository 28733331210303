import Status from "./Status";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Renting from "../../../models/Renting";
import Loader from "../../../components/Loader";
import { metersToKM } from "../../../utils/Units";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuro, faMinus } from "@fortawesome/free-solid-svg-icons";

import { dateFormatter, secondsToHoursMinutes } from "../../../utils/Dates";
import { getViewportWidth, isAbove, isBelow } from "../../../utils/Viewport";
import { PagingState, CustomPaging, SortingState } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const UserRentingsTable = ({
  sorting,
  rentings,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = ["bill", "startTime", "endTime", "totalTime", "distance"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className={`sortable-column-label`}>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveId = (data) => {
    return (
      <Link className='font-bold link' to={"/rentings/" + data.id}>
        {data.id}
      </Link>
    );
  };

  const resolveBill = (data) => {
    const bill = Utils.billFormatter(data.attributes.bill);

    if (Utils.isNull(bill)) {
      return (
        <div>
          <FontAwesomeIcon icon={faMinus} />
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(bill).toFixed(2)}
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </div>
      );
    }
  };

  const resolveDiscount = (data) => {
    const bill = data.attributes.bill;
    const hasDiscount = data.attributes.discount ?? null;

    if (!!hasDiscount) {
      const discount = Renting.discountCalculator(bill, hasDiscount);
      return (
        <div>
          <span>{parseFloat(discount).toFixed(2)}</span>
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </div>
      );
    } else {
      return <FontAwesomeIcon icon={faMinus} />;
    }
  };

  const resolveTotal = (data) => {
    const bill = data.attributes.bill;
    const hasDiscount = data.attributes.discount ?? null;

    if (!!hasDiscount) {
      const discount = Renting.discountCalculator(bill, hasDiscount);

      return (
        <div>
          <span>{Renting.totalBillCalculator(bill, discount)}</span>
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </div>
      );
    } else {
      return (
        <div>
          {!!bill ? (
            <>
              <span>{parseFloat(bill).toFixed(2)}</span>
              <FontAwesomeIcon icon={faEuro} className='ml-1' />
            </>
          ) : (
            <FontAwesomeIcon icon={faMinus} />
          )}
        </div>
      );
    }
  };

  const resolveCarPlate = (data) => {
    const isCarDefined = !!data?.attributes?.car;
    if (isCarDefined) {
      return (
        <Link className='link' to={`/cars/${data.attributes.car.id}`}>
          <span className='font-semibold'>{data.attributes.car.attributes.carPlate}</span>
        </Link>
      );
    } else {
      return;
    }
  };

  const resolveUsername = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.driver?.id}`}>
        <span className='font-semibold truncate'>
          {data?.attributes?.driver?.attributes?.username}
        </span>
      </Link>
    );
  };

  const resolveStartTime = (data) => {
    return dateFormatter(data.attributes.startTime, true);
  };

  const resolveEndTime = (data) => {
    const endTime = dateFormatter(data.attributes.endTime, true);
    if (Utils.isNull(endTime)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return endTime;
    }
  };

  const resolveTotalTime = (data) => {
    const totalTime = data.attributes.totalTime;

    if (Utils.isNull(totalTime)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return secondsToHoursMinutes(totalTime) + "'";
    }
  };

  const resolveDistance = (data) => {
    const distance = data.attributes.distance;

    if (Utils.isNull(distance)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return metersToKM(distance) + " km";
    }
  };

  const resolveStatus = (data) => {
    const isFinished = data.attributes.isFinished;
    const isPaid = data.attributes.isPaid;

    return <Status isFinished={isFinished} isPaid={isPaid} />;
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "id",
          title: "Renting",
          getCellValue: resolveId,
        },
      ];
    } else if (isAbove("SM") && isBelow("MD")) {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
      ];
    } else if (isAbove("LG") && isBelow("XL")) {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "bill",
          title: "Amount",
          getCellValue: resolveBill,
        },
        {
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
      ];
    } else {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "endTime",
          title: "End Date",
          getCellValue: resolveEndTime,
        },
        {
          name: "totalTime",
          title: "Time",
          getCellValue: resolveTotalTime,
        },
        {
          name: "distance",
          title: "Distance",
          getCellValue: resolveDistance,
        },
        {
          name: "bill",
          title: "Amount",
          getCellValue: resolveBill,
        },
        {
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (isBelow("SM")) {
      const renting = props.row;
      return (
        <Table.Cell {...props} padding='none'>
          <div className='relative flex flex-col gap-1 py-2'>
            <div className='flex items-center gap-1 justify-between'>
              {columns.find((c) => c.name === "id").getCellValue(renting)}
              {resolveCarPlate(renting)}
            </div>
            <div className='flex items-center gap-1 justify-between'>
              {resolveUsername(renting)}
            </div>
            <div className='flex items-center gap-1 justify-between'>
              <span>Start: {resolveStartTime(renting)}</span>
              {!!renting.attributes.endTime && <span>End: {resolveEndTime(renting)}</span>}
            </div>
            {!!renting.attributes.endTime && (
              <div className='flex items-center gap-2 justify-between'>
                <div className='flex justify-between gap-0.5'>
                  <span className='max-w-12 truncate'>Amount:</span>{" "}
                  <span className='w-max'>{resolveBill(renting)}</span>
                </div>
                <div className='flex justify-between gap-1'>
                  <span className='max-w-12 truncate'>Discount:</span>{" "}
                  <span className='w-max'>{resolveDiscount(renting)}</span>
                </div>
                <div className='flex justify-between gap-1'>
                  <span className='max-w-12 truncate'>Total:</span>{" "}
                  <span className='w-max'>{resolveTotal(renting)}</span>
                </div>
              </div>
            )}
            <div className='flex items-center gap-1 justify-end'>{resolveStatus(renting)}</div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <Grid rows={rentings} columns={columns} getRowId={(row) => row.id}>
          <SortingState sorting={sorting} onSortingChange={handleSorting} />
          <PagingState
            currentPage={pagination.page}
            pageSize={pagination.size}
            onCurrentPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          <CustomPaging totalCount={totalElements} />
          <Table
            columnExtensions={[
              {
                columnName: "id",
                wordWrapEnabled: true,
                width: isBelow("SM") ? "auto" : "120",
              },
              {
                columnName: "carPlate",
                wordWrapEnabled: true,
                width: "80",
              },
              {
                columnName: "username",
                wordWrapEnabled: false,
                width: windowSize === "MOBILE" ? "100" : "auto",
              },
              {
                columnName: "startTime",
                wordWrapEnabled: true,
                width: "140",
              },
              { columnName: "endTime", wordWrapEnabled: true, width: "140" },
              {
                columnName: "totalTime",
                wordWrapEnabled: true,
                width: "50",
                align: "center",
              },
              {
                columnName: "distance",
                wordWrapEnabled: true,
                width: "100",
                align: "center",
              },
              {
                columnName: "bill",
                wordWrapEnabled: false,
                width: "90",
                align: "right",
              },
              {
                columnName: "discount",
                wordWrapEnabled: false,
                width: "80",
                align: "right",
              },
              {
                columnName: "total",
                wordWrapEnabled: false,
                width: "70",
                align: "right",
              },
              {
                columnName: "status",
                wordWrapEnabled: true,
                width: "125",
                align: "center",
              },
            ]}
            cellComponent={cellComponent}
          />
          <TableHeaderRow
            sortLabelComponent={TableHeaderRowSortLabelComponent}
            showSortingControls={true}
          />
          <PagingPanel pageSizes={[10, 25, 50, 100, 1000]} />
        </Grid>
      </div>
    </div>
  );
};

export default UserRentingsTable;
