import Utils from "../../../utils/Utils";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import { CITIES, BOOLEAN } from "../../../models/Baseline";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const AddCarForm = ({
  form,
  errors,
  yearOptions,
  inversDevices,
  carProvidersOptions,
  handleFormChange,
}) => {
  const cityOptions = Object.keys(CITIES).map((key) => ({
    value: key,
    label: Utils.textFirstOnlyUpper(key),
  }));
  const booleanOptions = Object.keys(BOOLEAN)
    .filter((key) => key !== "getValue")
    .map((key) => ({
      value: key,
      label: Utils.textFirstOnlyUpper(key),
    }));

  const getLabel = (device) => {
    const carPlate = device?.vehicle_information?.vehicle_license_plate
      ? device?.vehicle_information?.vehicle_license_plate
      : "No plate";
    return (
      <div className='flex items-center'>
        <FontAwesomeIcon
          icon={faCircle}
          size='xs'
          className={`${device.active ? "text-green-400" : "text-red-400"}`}
        />
        <span className='ml-1'>
          {device.qnr} - {carPlate}
        </span>
      </div>
    );
  };

  const inversDevicesOptions = inversDevices.map((device) => {
    return {
      value: device.qnr,
      label: getLabel(device),
    };
  });

  return (
    <>
      <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5'>
        <FormInput
          type='text'
          label='Brand'
          value={form.brand}
          placeholder='Car Brand'
          handleChange={(e) => handleFormChange("brand", e.target.value)}
        />
        <FormInput
          type='text'
          label='Model'
          value={form.model}
          placeholder='Car Model'
          handleChange={(e) => handleFormChange("model", e.target.value)}
        />
        <FormInput
          type='text'
          label='Car Plate'
          value={form.carPlate}
          placeholder='Car Plate'
          handleChange={(e) => handleFormChange("carPlate", e.target.value)}
        />
        <FormSelect
          options={booleanOptions}
          label='Auto Transmission'
          handleChange={(value) => handleFormChange("autoTransmission", value.value)}
          value={
            !Utils.isNull(form.autoTransmission)
              ? findOption(booleanOptions, form.autoTransmission)
              : null
          }
        />
        <FormSelect
          label='Invers QNR'
          options={inversDevicesOptions}
          handleChange={(value) => handleFormChange("inversQNR", value.value)}
          value={
            !Utils.isNull(form.inversQNR) ? findOption(inversDevicesOptions, form.inversQNR) : null
          }
        />
        <FormInput
          type='number'
          label='Mileage'
          value={form.kmTraveled}
          placeholder='Car Registered KMs'
          handleChange={(e) => handleFormChange("kmTraveled", e.target.value)}
        />
        <FormInput
          type='text'
          label='Price'
          value={form.price}
          handleChange={(e) => handleFormChange("price", e.target.value)}
        />
        <FormSelect
          label='City'
          options={cityOptions}
          handleChange={(value) => handleFormChange("city", value.label)}
          value={!Utils.isNull(form.city) ? findOption(cityOptions, form.city.toUpperCase()) : null}
        />
        <FormSelect
          label='Car Provider'
          options={carProvidersOptions}
          handleChange={(value) => handleFormChange("owner", value.value)}
          value={!Utils.isNull(form.owner) ? findOption(carProvidersOptions, form.owner) : null}
        />
        <FormSelect
          label='Is Available'
          options={booleanOptions}
          handleChange={(value) => handleFormChange("isAvailable", value.value)}
          value={
            !Utils.isNull(form.isAvailable) ? findOption(booleanOptions, form.isAvailable) : null
          }
        />
        <FormSelect
          label='Is Pet Friendly'
          options={booleanOptions}
          handleChange={(value) => handleFormChange("isPetFriendly", value.value)}
          value={
            !Utils.isNull(form.isPetFriendly)
              ? findOption(booleanOptions, form.isPetFriendly)
              : null
          }
        />
        <FormSelect
          label='Has Bluetooth'
          options={booleanOptions}
          handleChange={(value) => handleFormChange("hasBluetooth", value.value)}
          value={
            !Utils.isNull(form.hasBluetooth) ? findOption(booleanOptions, form.hasBluetooth) : null
          }
        />
        <FormSelect
          label='Has Child Seat'
          options={booleanOptions}
          handleChange={(value) => handleFormChange("hasChildSeat", value.value)}
          value={
            !Utils.isNull(form.hasChildSeat) ? findOption(booleanOptions, form.hasChildSeat) : null
          }
        />
        <FormInput
          min='0'
          max='5'
          type='number'
          label='Number of Seats'
          value={form.seatsNum}
          placeholder='Number of Seats'
          handleChange={(e) => handleFormChange("seatsNum", e.target.value)}
        />
        <FormSelect
          options={yearOptions}
          label='Manufacturing Date'
          handleChange={(value) => handleFormChange("manufacturingDate", value.value)}
          value={
            !Utils.isNull(form.manufacturingDate)
              ? findOption(yearOptions, form.manufacturingDate)
              : null
          }
        />
      </div>
      {errors && (
        <div className='mt-2'>
          <span className='text-red-500'>
            {errors === "Not Found" ? "Invers QNR not found" : errors}
          </span>
        </div>
      )}
    </>
  );
};

export default AddCarForm;
