import Utils from "../../../utils/Utils";
import CarFuelIndicator from "./CarFuelIndicator";
import Toggler from "../../../components/Toggler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faCheckToSlot,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const CarStatus = ({
  car,
  carStatus,
  isLoading,
  isLoadingInvers,
  handleToggleRiding,
  handleToggleAvailable,
  handleToggleUnderMaintenance,
}) => {
  const isBatteryLow = carStatus?.invers?.LowBatteryLevelAlarm;

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Car Status</span>
      </div>
      <div className='car-details'>
        <span>Fuel</span>
        <CarFuelIndicator fuel={car.fuel} />
        <span>Ignition</span>
        <div>
          {!carStatus || isLoadingInvers ? (
            <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          ) : (
            <div className={`px-1.5 py-1 text-sm rounded inline-block bg-gray-500 text-white`}>
              <span>{Utils.textFirstOnlyUpper(carStatus?.invers?.ignition)}</span>
            </div>
          )}
        </div>
        <span className='flex items-center'>Central Lock</span>
        <div className='flex items-center'>
          {!carStatus || isLoadingInvers ? (
            <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          ) : (
            <div className={`px-1.5 py-1 text-sm rounded inline-block bg-gray-500 text-white`}>
              <FontAwesomeIcon
                size='sm'
                icon={carStatus?.invers?.central_lock === "locked" ? faLock : faLockOpen}
              />
              <span className='ml-1'>
                {Utils.textFirstOnlyUpper(carStatus?.invers?.central_lock)}
              </span>
            </div>
          )}
        </div>
        <span className='flex items-center'>Immobilizer</span>
        <div className='flex items-center'>
          {!carStatus || isLoadingInvers ? (
            <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          ) : (
            <div className={`px-1.5 py-1 text-sm rounded inline-block bg-gray-500 text-white`}>
              <FontAwesomeIcon
                icon={carStatus?.invers?.immobilizer === "locked" ? faLock : faLockOpen}
              />
              <span className='ml-1'>
                {Utils.textFirstOnlyUpper(carStatus?.invers?.immobilizer)}
              </span>
            </div>
          )}
        </div>
        <span className='flex items-center'>Battery Level</span>
        <div className='flex items-center'>
          {!carStatus || isLoadingInvers ? (
            <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          ) : (
            <div
              className={`px-1.5 py-1 text-sm text-white ${
                isBatteryLow ? "bg-red-500" : "bg-green-500"
              } rounded inline-block`}
            >
              <FontAwesomeIcon icon={isBatteryLow ? faExclamationTriangle : faCheckToSlot} />
              <span className='ml-2'>{isBatteryLow ? "Low" : "Good"}</span>
            </div>
          )}
        </div>
        <span>Available</span>
        <Toggler
          disabled={isLoading}
          toggle={car.isAvailable}
          handleToggle={(toggle) => handleToggleAvailable(toggle, car.id)}
        />
        <span>Riding</span>
        <Toggler
          disabled={isLoading}
          toggle={car.isRiding}
          handleToggle={(toggle) => handleToggleRiding(toggle, car.id)}
        />
        <span>Under Maintenance</span>
        <Toggler
          disabled={isLoading}
          toggle={car.underMaintenance}
          handleToggle={(toggle) => handleToggleUnderMaintenance(toggle, car.id)}
        />
      </div>
    </>
  );
};

export default CarStatus;
