import Parse from "parse";
import Admin from "./Admin";
import Utils from "../utils/Utils";
import {
  carSerializer,
  userSerializer,
  commSerializer,
  rentingSerializer,
  discountSerializer,
  userMessageSerializer,
} from "./serializers";

export default class Logger {
  static login = async (session) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.id);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.LOGIN);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} logged in.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static logout = async (session) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.LOGOUT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} logged out.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addCar = async (session, car) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_CAR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added new car: ${cS.carPlate} - ${cS.brand} ${cS.model}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editRenting = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_RENTING);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited renting: ${rS.id} of user ${rS.driver.id} - ${rS.driver.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static authorizeUser = async (session, user, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.AUTHORIZE_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} authorized user: ${uS.id} - ${uS.username} with action ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editUser = async (session, user, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited user: ${uS.id} - ${uS.username} with action ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editCar = async (session, car, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_CAR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited car: ${cS.id} - ${cS.carPlate} with action ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static sendMessage = async (session, message) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const umS = userMessageSerializer(message);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.SEND_USER_MESSAGE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} sent user message to: ${umS.reciever.id} - ${umS.reciever.username} of type ${
        umS.type
      } and title: ${umS.title}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addDiscount = async (session, discount) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const dS = discountSerializer(discount);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_DISCOUNT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added discount to: ${dS.user.id} - ${dS.user.username} of ${dS.amount} type ${dS.type}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addUserNotes = async (session, user) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_USER_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to user: ${uS.id} - ${uS.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addRentingNotes = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_RENTING_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to renting: ${rS.id} - User: ${rS.driver.id} ${rS.driver.username} Car: ${
        rS.car.carPlate
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addCarNotes = async (session, car) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_CAR_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to car: ${cS.id} - ${cS.carPlate}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addUserComm = async (session, comm, isEdit) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const commS = commSerializer(comm);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", isEdit ? Admin.ACTIONS.EDIT_USER_COMM : Admin.ACTIONS.ADD_USER_COMM);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(aS.lastName)} ${
        isEdit ? "edited" : "added"
      } communication to user: ${commS.user.id} - ${commS.user.username} with details: ${
        commS.type
      } ${commS.priority} ${commS.status} and subject ${commS.subject}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUserComm = async (session, comm) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const commS = commSerializer(comm);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER_COMM);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted a communication record from user: ${commS.user.id} - ${
        commS.user.username
      } with details: ${commS.type} ${commS.priority} ${commS.status} and subject ${commS.subject}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUser = async (session, user) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted the user: ${uS.id} - ${uS.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUserPaymentMethod = async (session, user, paymentMethod) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER_PAYMENT_METHOD);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted user's: ${user.id} - ${user.username} payment method ${paymentMethod.id} - ${
        paymentMethod.type
      } ${paymentMethod.card.brand} ${paymentMethod.card.last4} ${Utils.prefillZero(
        paymentMethod.card.exp_month
      )}/${paymentMethod.card.exp_year}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static downloadRentalReport = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DOWNLOAD_RENTAL_REPORT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} downloaded rental report of: ${rS.driver.username} - ${rS.car.carPlate} ${
        rS.time.startTime
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };
}
