import { useState } from "react";
import Filters from "../components/UserMessagesFilters";
import UserMessagesTable from "../components/UserMessagesTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import SendUserMessagesModal from "../components/SendUserMessagesModal";

const UserMessagesPageView = ({
  filters,
  sorting,
  isLoading,
  pagination,
  userMessages,
  totalElements,
  handleSorting,
  isExternalPage,
  isLoadingMessage,
  handlePageChange,
  handleSendMessage,
  handleClearFilters,
  handleFilterChange,
  handlePageSizeChange,
}) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <>
            <div className='page-title'>
              <span className='title'>USER MESSAGES</span>
            </div>
            <Filters
              filters={filters}
              isLoading={isLoading}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          </>
        )}
        <div className='table-wrapper'>
          <div className='external-header'>
            <div className='title-inner'>
              <span>Messages</span>
            </div>
            <div
              className='flex items-center gap-1 text-text-main cursor-pointer'
              onClick={() => toggleModal(true)}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
              <span className='text-sm sm:text-base'>New Message</span>
            </div>
          </div>
          <UserMessagesTable
            filters={filters}
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            userMessages={userMessages}
            handleSorting={handleSorting}
            totalElements={totalElements}
            isExternalPage={isExternalPage}
            handlePageChange={handlePageChange}
            handleFilterChange={handleFilterChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <SendUserMessagesModal
        users={null}
        setSelection={null}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        handleRemoveRecipient={null}
        isLoading={isLoadingMessage}
        handleSendMessages={handleSendMessage}
      />
    </div>
  );
};

export default UserMessagesPageView;
