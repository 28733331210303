import Parse from "parse";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import useToaster from "../../../hooks/useToaster";
import FormInput from "../../../components/form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faSpinner,
  faLocationDot,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const UserCarDetails = ({
  car,
  driver,
  renting,
  form = null,
  isEditing = null,
  handleFormChange = null,
}) => {
  const toaster = useToaster();
  const [isLoading, setLoading] = useState(false);

  const getCarLocation = async () => {
    setLoading(true);
    try {
      await Parse.Cloud.run("getInverseDeviceStatus", {
        inversQNR: car.inversQNR,
      }).then((r) => {
        setLoading(false);
        handleFormChange("endLocation", r.position.lat + "," + r.position.lon);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>User & Car Details</span>
      </div>
      <div className='renting-details'>
        <span>Username</span>
        <span className='link'>
          <Link to={`/users/${driver.id}`}>{driver.username}</Link>
        </span>
        <span>Car Plate</span>
        <span className='link'>
          <Link to={`/cars/${car.id}`}>{car.carPlate}</Link>
        </span>

        <span>Start at</span>
        <span>{renting.address.startAddress}</span>
        <span>End at</span>
        {Utils.isNull(renting.address.endAddress) ? (
          <div>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        ) : (
          <span className='relative'>
            {renting.address.endAddress}
            {isEditing && (
              <Tooltip
                title="The End address will be calculated automatically upon changing the renting's end geolocation."
                placement='top'
              >
                <FontAwesomeIcon
                  className='absolute text-blue-500 cursor-pointer ml-1'
                  icon={faQuestionCircle}
                  size='2xs'
                />
              </Tooltip>
            )}
          </span>
        )}
        <span>Start Coordinates</span>
        {isEditing ? (
          <div>
            <FormInput
              type='text'
              value={form.values.startLocation}
              errors={form.touched.startLocation && form.errors.startLocation}
              handleChange={(e) => handleFormChange("startLocation", e.target.value)}
            />
          </div>
        ) : (
          <span>
            {renting.location.start.latitude}, {renting.location.start.longitude}
          </span>
        )}
        <span>End Coordinates</span>
        {isEditing ? (
          <div className='relative'>
            <FormInput
              type='text'
              value={form.values.endLocation}
              errors={form.touched.endLocation && form.errors.endLocation}
              handleChange={(e) => handleFormChange("endLocation", e.target.value)}
            />
            <FontAwesomeIcon
              icon={isLoading ? faSpinner : faLocationDot}
              onClick={isLoading ? null : getCarLocation}
              className={`absolute w-6 h-5 right-1 top-2 bg-bg-input ${
                isLoading ? "animate-spin cursor-not-allowed" : "cursor-pointer"
              }`}
            />
          </div>
        ) : (
          <>
            {Utils.isNull(renting.location.end.latitude) ? (
              <div>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            ) : (
              <span>
                {renting.location.end.latitude}
                {", "}
                {renting.location.end.longitude}
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserCarDetails;
