import CryptoJS from "crypto-js";

export const encryptMessage = (message) => {
  const messageEncryptionKey = process.env.REACT_APP_MESSAGE_ENCRYPTION_KEY;
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(message, messageEncryptionKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return encrypted.toString() + ":" + CryptoJS.enc.Base64.stringify(iv);
};

export const decryptMessage = (encryptedMessage) => {
  const messageEncryptionKey = process.env.REACT_APP_MESSAGE_ENCRYPTION_KEY;

  const message = encryptedMessage.split(":")[0];
  const iv = encryptedMessage.split(":")[1];

  const decrypted = CryptoJS.AES.decrypt(message, messageEncryptionKey, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
