import Parse from "parse";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CarDocsPageView from "../view/CarDocsPageView";

const CarDocsPageState = () => {
  const { carId } = useParams();
  const [car, setCar] = useState(null);
  const [kteo, setKteo] = useState(null);
  const [license, setLicense] = useState(null);
  const [contract, setContract] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState(null);
  const [carProviders, setCarProviders] = useState(null);

  const getCarData = async () => {
    setLoading(true);
    const Car = Parse.Object.extend("Car");
    const query = new Parse.Query(Car);
    query.equalTo("objectId", carId);
    query.include("owner");

    try {
      const car = await query.first();
      const docs = await getDocuments(car);
      const providers = await getCarProviders();
      setCar(car);
      setLicense(docs.license);
      setCarProviders(providers);
      setContract(docs.contract);
      setInsurance(docs.insurance);
      setKteo(docs.kteo);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
    }
  };

  const getDocuments = async (car) => {
    const queryLicense = new Parse.Query("CarLicense");
    queryLicense.equalTo("car", car);
    queryLicense.equalTo("isOutdated", false);
    const queryContract = new Parse.Query("CarContract");
    queryContract.equalTo("car", car);
    queryContract.equalTo("isOutdated", false);
    queryContract.include("provider");
    const queryInsurance = new Parse.Query("CarInsurance");
    queryInsurance.equalTo("car", car);
    queryInsurance.equalTo("isOutdated", false);
    const queryKteo = new Parse.Query("CarKteo");
    queryKteo.equalTo("car", car);
    queryKteo.equalTo("isOutdated", false);

    try {
      const license = await queryLicense.first();
      const contract = await queryContract.first();
      const insurance = await queryInsurance.first();
      const kteo = await queryKteo.first();
      return {
        license: license,
        contract: contract,
        insurance: insurance,
        kteo: kteo,
      };
    } catch (e) {
      console.error(e.message);
    }
  };

  const getCarProviders = async () => {
    const query = new Parse.Query("CarProviders");
    return await query.find();
  };

  const markOutdated = async (type, id) => {
    setLoading(true);
    try {
      if (type === "license") {
        await new Parse.Object("CarLicense").set("objectId", id).set("isOutdated", true).save();
        setLicense(null);
      } else if (type === "contract") {
        await new Parse.Object("CarContract").set("objectId", id).set("isOutdated", true).save();
        setContract(null);
      } else if (type === "insurance") {
        await new Parse.Object("CarInsurance").set("objectId", id).set("isOutdated", true).save();
        setInsurance(null);
      } else if (type === "kteo") {
        await new Parse.Object("CarKteo").set("objectId", id).set("isOutdated", true).save();
        setKteo(null);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
    }
  };

  const handleSubmit = async (type, form) => {
    setLoading(true);
    try {
      const Class =
        type === "license"
          ? "CarLicense"
          : type === "contract"
          ? "CarContract"
          : type === "insurance"
          ? "CarInsurance"
          : "CarKteo";
      let fileBase64 = null;
      let fileReader = new FileReader();
      fileReader.onload = async function (fileLoadedEvent) {
        fileBase64 = fileLoadedEvent.target.result;
        const data = fileBase64.split(",")[1];

        const parseFile = new Parse.File(form.file.name, { base64: data });

        const newDocument = new Parse.Object(Class);
        newDocument.set("car", car);
        newDocument.set("isOutdated", false);
        newDocument.set("document", parseFile);
        !!form.comments && newDocument.set("comments", form.comments);

        if (type === "license") {
          await newDocument.save().then(() => setLoading(false));
          setLicense(newDocument);
        } else if (type === "contract") {
          newDocument.set("provider", car.attributes.owner);
          newDocument.set("startDate", form.startDate);
          newDocument.set("endDate", form.endDate);
          await newDocument.save().then(() => setLoading(false));
          setContract(newDocument);
        } else if (type === "insurance") {
          newDocument.set("insuranceCompany", form.insuranceCompany);
          newDocument.set("issueDate", form.issueDate);
          newDocument.set("expiryDate", form.expiryDate);
          await newDocument.save().then(() => setLoading(false));
          setInsurance(newDocument);
        } else if (type === "kteo") {
          newDocument.set("issueDate", form.issueDate);
          newDocument.set("expiryDate", form.expiryDate);
          await newDocument.save().then(() => setLoading(false));
          setKteo(newDocument);
        }
      };
      fileReader.readAsDataURL(form.file);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
    }
  };

  useEffect(() => {
    getCarData();
  }, []);

  return (
    <CarDocsPageView
      car={car}
      kteo={kteo}
      license={license}
      contract={contract}
      insurance={insurance}
      isLoading={isLoading}
      carProviders={carProviders}
      markOutdated={markOutdated}
      handleSubmit={handleSubmit}
    />
  );
};

export default CarDocsPageState;
