const CarDetails = ({ car, carStatus, isLoadingInvers }) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Car Details</span>
      </div>
      <div className='car-details'>
        <span>Plate</span>
        <span>{car.carPlate}</span>
        <span>Brand</span>
        <span>{car.brand}</span>
        <span>Model</span>
        <span>{car.model}</span>
        <span>Price</span>
        <span>€{car.price}/m</span>
        <span>Location</span>
        {!carStatus || isLoadingInvers ? (
          <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : (
          <span>{carStatus?.address}</span>
        )}
        <span>Coordinates</span>
        {!carStatus || isLoadingInvers ? (
          <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : (
          <span>
            {carStatus?.invers?.position.lat}, {carStatus?.invers?.position.lon}
          </span>
        )}
        <span>Updated:</span>
        <span>{car.updatedAt}</span>
      </div>
    </>
  );
};

export default CarDetails;
