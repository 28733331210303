export default class Admin {
  static ACTIONS = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    EDIT_USER: "EDIT USER",
    DELETE_USER: "DELETE USER",
    AUTHORIZE_USER: "AUTHORIZE USER",
    SEND_USER_MESSAGE: "SEND USER MESSAGE",
    ADD_USER_COMM: "ADD USER COMMUNICATION",
    EDIT_USER_COMM: "EDIT USER COMMUNICATION",
    DELETE_USER_COMM: "DELETE USER COMMUNICATION",
    DELETE_USER_PAYMENT_METHOD: "DELETE USER PAYMENT METHOD",
    ADD_USER_NOTES: "ADD USER NOTES",
    ADD_DISCOUNT: "ADD DISCOUNT",
    EDIT_RENTING: "EDIT RENTING",
    ADD_RENTING_NOTES: "ADD RENTING NOTES",
    ADD_CAR: "ADD CAR",
    EDIT_CAR: "EDIT CAR",
    ADD_CAR_NOTES: "ADD CAR NOTES",
    DOWNLOAD_RENTAL_REPORT: "DOWNLOAD RENTAL REPORT",
  };
}
