const UserMetricsBlock = ({ userTotals }) => {
  return (
    <div className='dashboard-block'>
      <div className='title'>
        <span>Users</span>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Total Users</span>
          </div>
          {userTotals?.totalUsers ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalUsers}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Pending</span>
          </div>
          {userTotals?.totalPending ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalPending}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Authorized</span>
          </div>
          {userTotals?.totalAuthorized ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalAuthorized}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Unauthoried</span>
          </div>
          {userTotals?.totalUnauthorized ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalUnauthorized}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Rejected</span>
          </div>
          {userTotals?.totalRejected ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalRejected}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Locked</span>
          </div>
          {userTotals?.totalLocked ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalLocked}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
        <div className='flex justify-between'>
          <div>
            <span>Referred</span>
          </div>
          {userTotals?.totalReferred ? (
            <div>
              <span className='text-semibold'>{userTotals?.totalReferred}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMetricsBlock;
