import dayjs from "dayjs";
import Utils from "../../../utils/Utils";
import Discount from "../../../models/Discount";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckbox from "../../../components/form/FormCheckbox";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark, faTag } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const typeOptions = Object.keys(Discount.TYPE).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const typeDefault = !Utils.isNull(filters.type)
    ? findOption(typeOptions, String(filters.type)?.toUpperCase())
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='relative'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
              <div>
                <span className='text-lg font-semibold'>
                  <FormInput
                    type='text'
                    label='Username'
                    classes='w-full'
                    placeholder='Username'
                    value={Utils.isNull(filters?.username) ? "" : filters?.username}
                    handleChange={(e) => handleFilterChange("username", e.target.value)}
                  />
                </span>
              </div>
              <div>
                <span className='text-lg font-semibold'>
                  <FormInput
                    type='text'
                    label='Last Name'
                    classes='w-full'
                    placeholder='Last Name'
                    value={Utils.isNull(filters?.lastName) ? "" : filters?.lastName}
                    handleChange={(e) => handleFilterChange("lastName", e.target.value)}
                  />
                </span>
              </div>
              <div>
                <FormSelect
                  label='Type'
                  classes='w-full'
                  isClearable={true}
                  options={typeOptions}
                  value={typeDefault}
                  placeholder='Type'
                  handleChange={(option) => handleFilterChange("type", option)}
                />
              </div>
              <div>
                <FormDatepicker
                  label='From'
                  value={from}
                  isClearable={true}
                  handleChange={(value) => handleDateChange("from", value)}
                />
              </div>
              <div>
                <FormDatepicker
                  label='To'
                  value={to}
                  isClearable={true}
                  handleChange={(value) => handleDateChange("to", value)}
                />
              </div>
              <div className='flex items-end mb-2 mt-2'>
                <FormCheckbox
                  label='Exclude used'
                  value={filters.isUsed}
                  handleChange={(checked) => handleFilterChange("isUsed", checked)}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
