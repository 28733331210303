const FormTextarea = ({ rows, label, value, disabled, placeholder, handleChange }) => {
  return (
    <>
      {label && <label className='text-text-main pl-1 mb-1'>{label}</label>}
      <textarea
        rows={rows}
        value={value}
        className='w-full'
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};

export default FormTextarea;
