import Parse from "parse";
import dayjs from "dayjs";
import { json2excel } from "js2excel";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import Renting from "../../../models/Renting";
import Loader from "../../../components/Loader";
import { metersToKM } from "../../../utils/Units";
import RentingsPageView from "../view/RentingsPageView";
import { getViewportWidth } from "../../../utils/Viewport";
import { secondsToHoursMinutes } from "../../../utils/Dates";
import { rentingSerializer } from "../../../models/serializers";

const RentingsPageState = ({ userId = null, carId = null }) => {
  let liveQ = null;
  const windowSize = getViewportWidth();
  const [totals, setTotals] = useState({});
  const [filters, setFilters] = useState({});
  const [rentings, setRentings] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isLoadingTotals, setLoadingTotals] = useState(true);
  const isExternalPage = !Utils.isNull(userId) || !Utils.isNull(carId);
  const [sorting, setSorting] = useState([{ columnName: "startTime", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: windowSize === "MOBILE" ? 10 : 25,
  });

  const startLive = async () => {
    const q = new Parse.Query("Rentings");
    liveQ = await q.subscribe();

    liveQ.on("update", (object) => {
      setRentings((prev) => {
        const newState = prev.map((r) => {
          if (r.id === object.id) return object;
          else return r;
        });
        return newState;
      });
    });
    liveQ.on("create", async () => {
      await getRentings();
    });
  };

  const getRentings = async (isTotals = false, isExport = false) => {
    setLoading(true);
    const query = new Parse.Query("Rentings");
    query.includeAll();

    if (!!userId) {
      query.equalTo("driver", { __type: "Pointer", className: "_User", objectId: userId });
    }
    if (!!carId) {
      query.equalTo("car", { __type: "Pointer", className: "Car", objectId: carId });
    }

    if (Object.keys(filters).length > 0) {
      if (filters?.username && Utils.isNull(userId)) {
        let driver = Parse.Object.extend("_User");
        const q = new Parse.Query(driver);
        q.matches("username", filters.username.trim(), "i");
        driver = await q.first();
        query.equalTo("driver", driver);
      }
      if (filters?.carPlate && Utils.isNull(carId)) {
        let car = Parse.Object.extend("Car");
        const q = new Parse.Query(car);
        q.contains("carPlate", filters.carPlate);
        car = await q.first();
        query.equalTo("car", car);
      }
      filters.from && query.greaterThanOrEqualTo("endTime", filters.from);
      filters.to && query.lessThanOrEqualTo("endTime", filters.to);
      filters.hasNotes && query.exists("notes");
      if (!!filters.hasDiscount) {
        const joinPayment = new Parse.Query("Payment").exists("usedDiscount");

        query.matchesQuery("payment", joinPayment);
      }
      if (!Utils.isNull(filters.status)) {
        const status = filters.status;
        if (status === Renting.STATUS.UNPAID) {
          query.equalTo("isFinished", true);
          query.equalTo("isPaid", false);
        } else if (status === Renting.STATUS.ACTIVE) {
          query.equalTo("isFinished", false);
          query.equalTo("isPaid", false);
        } else if (status === Renting.STATUS.COMPLETED) {
          query.equalTo("isFinished", true);
          query.equalTo("isPaid", true);
        }
      }
      if (filters.hasIncident) {
        const incidentQuery = new Parse.Query("Incident");
        query.matchesQuery("incident", incidentQuery);
      }
    }

    if (!isTotals && !isExport) {
      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }

      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      const data = await query.find({ useMasterKey: true });

      const discountsCalculated = await Renting.provideTotalBill(data.results);

      setRentings(discountsCalculated);
      setTotalElements(data.count);
      setLoading(false);
      return discountsCalculated;
    } else if (isTotals) {
      const data = await query.findAll({ useMasterKey: true });

      const discountsCalculated = await Renting.provideTotalBill(data);
      setLoading(false);
      return discountsCalculated;
    } else if (isExport) {
      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      const size = await query.count({ useMasterKey: true });
      query.limit(size);
      const data = await query.find({ useMasterKey: true });

      const discountsCalculated = await Renting.provideTotalBill(data);
      setLoading(false);
      return discountsCalculated;
    }
  };

  const getTotals = async () => {
    setLoadingTotals(true);
    if (Object.keys(filters).length < 1) {
      const dailyRentings = await new Parse.Query("Rentings")
        .greaterThanOrEqualTo("endTime", new Date(dayjs().startOf("day").toISOString()))
        .includeAll()
        .findAll({ useMasterKey: true });

      const discountsCalculated = await Renting.provideTotalBill(dailyRentings);

      setTotals({
        dailyRentings: discountsCalculated.length,
        dailyTimeSpent: discountsCalculated.reduce((a, b) => a + (b.attributes.totalTime || 0), 0),
        dailyRevenue: discountsCalculated
          .filter((r) => r.attributes.isPaid)
          .reduce((a, b) => a + (b.attributes.totalBill || 0), 0),
      });
      setLoadingTotals(false);
    } else {
      const data = await getRentings(true);

      setTotals({
        rentings: data.length,
        timeSpent: data.reduce((a, b) => a + (b.attributes.totalTime || 0), 0),
        revenue: data
          .filter((r) => r.attributes.isPaid)
          .reduce((a, b) => a + (b.attributes.totalBill || 0), 0),
      });
      setLoadingTotals(false);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
  };

  const handleClearFilters = (e) => {
    e.stopPropagation();
    setFilters({});
    setShouldUpdate(true);
  };

  const handleApplyFilters = () => {
    setShouldUpdate(true);
  };

  const handleSorting = (sorting) => {
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setPagination({ ...pagination, size: size });
  };

  const handleAddRenting = async (e) => {
    e.stopPropagation();
    console.log("add renting");
  };

  const handleExportData = async (e) => {
    e.stopPropagation();
    let excelRentings = [];

    const convertAndExport = async (rentings) => {
      let totalAmount = 0;
      let totalDiscount = 0;
      let totalTotal = 0;
      let totalTime = 0;
      let totalDistance = 0;

      for (let i = 0; i < rentings.length; i++) {
        const rent = rentingSerializer(rentings[i]);
        totalAmount += Number(rent.bill ?? 0);
        totalDiscount += Number(rent.discount?.applies ?? 0);
        totalTotal += Number(rent.totalBill ?? 0);
        totalTime += Number(rent.time.totalTime ?? 0);
        totalDistance += Number(rent.distance ?? 0);

        excelRentings.push(await rentingsJsonToExcelFormat(rent));
      }

      excelRentings.push({
        "Renting ID": "Totals",
        Car: "",
        Driver: "",
        "Start Date": "",
        "Start Date Raw": "",
        "End Date": "",
        "End Date Raw": "",
        Time: secondsToHoursMinutes(totalTime) + "'",
        "Time in Seconds": "",
        Distance: metersToKM(totalDistance) + " km",
        "Distance in Meters": "",
        Amount: totalAmount,
        Discount: totalDiscount,
        Total: totalTotal,
        Status: "",
        "Start Address": "",
        "End Address": "",
        "Start Geolocation": "",
        "End Geolocation": "",
        "Reason of Use": "",
        Rating: "",
        "People Count": "",
      });

      const fileName = `${carId ? "Car: " + carId : ""}${
        userId ? "User: " + userId : ""
      } - Rentings`;

      json2excel({
        data: excelRentings,
        name: fileName,
        formatdate: "dd/mm/yyyy",
      });
    };

    const data = await getRentings(false, true);

    await convertAndExport(data);
  };

  const rentingsJsonToExcelFormat = async (rent) => {
    return {
      "Renting ID": rent.id,
      Car: rent.car?.carPlate ?? "Deleted Car",
      Driver: rent.driver?.username ?? "Deleted User",
      "Start Date": rent.time.startTime,
      "Start Date Raw": dayjs(rent.time.startRaw).unix(),
      "End Date": rent.time.endTime ?? "",
      "End Date Raw": dayjs(rent.time.endRaw).unix(),
      Time: secondsToHoursMinutes(rent.time.totalTime) + "'",
      "Time in Seconds": rent.time.totalTime,
      Distance: metersToKM(rent.distance) + " km",
      "Distance in Meters": rent.distance,
      Amount: rent.bill ?? "",
      Discount: rent.discount?.applies ?? "",
      Total: rent.totalBill ?? "",
      Status: Utils.textFirstOnlyUpper(Renting.getStatus(rent.isFinished, rent.isPaid)),
      "Start Address": rent.address.startAddress ?? "",
      "End Address": rent.address.endAddress ?? "",
      "Start Geolocation":
        rent.location.start.latitude + ", " + rent.location.start.longitude ?? "",
      "End Geolocation": rent.location.end.latitude + ", " + rent.location.end.longitude ?? "",
      "Reason of Use": rent.feedback.reasonOfUse ?? "",
      Rating: rent.feedback.starRating ?? "",
      "People Count": rent.feedback.peopleCount ?? "",
    };
  };

  useEffect(() => {
    // startLive();
    getTotals();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      getTotals();
      getRentings();
      setPagination({ ...pagination, page: 0 });
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    getRentings();
  }, [sorting, pagination]);

  return (
    <>
      {rentings ? (
        <RentingsPageView
          totals={totals}
          sorting={sorting}
          filters={filters}
          fromCar={!!carId}
          fromUser={!!userId}
          rentings={rentings}
          isLoading={isLoading}
          pagination={pagination}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          isLoadingTotals={isLoadingTotals}
          handleExportData={handleExportData}
          handleAddRenting={handleAddRenting}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default RentingsPageState;
