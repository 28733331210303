import dayjs from "dayjs";
import Utils from "../../../utils/Utils";
import Admin from "../../../models/Admin";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import { userSerializer } from "../../../models/serializers";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, distinctUsers, handleFilterChange, handleClearFilters }) => {
  const distUsers = distinctUsers
    .filter((u) => !Utils.isNull(u))
    .map((u) => {
      const user = userSerializer(u);
      return { value: user.username, label: user.username };
    });
  const selectedUser = !Utils.isNull(filters?.user) ? findOption(distUsers, filters.user) : null;
  const actionOptions = Object.keys(Admin.ACTIONS).map((s) => ({
    value: Admin.ACTIONS[s],
    label: Utils.textFirstOnlyUpper(Admin.ACTIONS[s]),
  }));
  const actionDefault = !Utils.isNull(filters?.action)
    ? findOption(actionOptions, String(filters?.action)?.toUpperCase())
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormSelect
                label='User'
                classes='w-full'
                isClearable={true}
                options={distUsers}
                value={selectedUser}
                placeholder='User'
                handleChange={(option) => handleFilterChange("user", option)}
              />
            </div>
            <div>
              <span className='text-lg font-semibold'>
                <FormInput
                  type='text'
                  label='Extra contains keyword'
                  classes='w-full'
                  placeholder='Type a keyword'
                  value={Utils.isNull(filters?.extraKeyword) ? "" : filters?.extraKeyword}
                  handleChange={(e) => handleFilterChange("extraKeyword", e.target.value)}
                />
              </span>
            </div>
            <div>
              <FormSelect
                label='Action'
                classes='w-full'
                isClearable={true}
                options={actionOptions}
                value={actionDefault}
                placeholder='Action'
                handleChange={(option) => handleFilterChange("action", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
