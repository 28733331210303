import Utils from "./Utils";
import dayjs from "dayjs";

export function dateFormatter(date, includeTime = false, format = "DD/MM/YYYY") {
  if (Utils.isNull(date)) return null;
  if (includeTime) format = format + " HH:mm";
  const d = dayjs(new Date(date).toISOString()).format(format);

  return d;
}

export function addZeroBefore(n) {
  return (n < 10 ? "0" : "") + n;
}

export function secondsToHoursMinutes(time) {
  let hours = Math.floor(Math.floor(time / 60) / 60);
  let minutes = (Math.floor(time / 60) % 60) + 1;

  return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

export function secondsToDaysHoursMinutes(time) {
  var seconds = parseInt(time, 10);

  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  var minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return days + "d " + hours + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m";
}

export function differenceInYears(date) {
  const day = date.split("/")[0];
  const month = date.split("/")[1] - 1; // -1 because dayjs counts months from 0 and not 1 so Feb would be 1 and not 2
  const year = date.split("/")[2];
  let age = dayjs().set("date", day).set("month", month).set("year", year);
  let today = dayjs(new Date());
  let difference = today.diff(age, "year");

  return difference;
}
