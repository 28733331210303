import Parse from "parse";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import Card from "../../../components/stripe/Card";

const PaymentMethodsModal = ({ user, isOpen, toggleModal }) => {
  const userHook = useUser();
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const listPaymentMethods = async () => {
    if (!!user.customerId) {
      const PM = await Parse.Cloud.run("listPaymentMethods", {
        customerId: user.customerId,
      });

      if (PM) setPaymentMethods(PM.data);
    }
  };

  const handleDelete = async (paymentMethod) => {
    setLoading(true);
    if (
      window.confirm(
        `Are you sure you want to delete ${user.username} - ${user.firstName} ${user.lastName} payment method?`
      )
    ) {
      const PM = await new Parse.Query("PaymentMethod")
        .equalTo("stripeId", paymentMethod.id)
        .first({ useMasterKey: true });

      if (!!PM) {
        await PM.destroy({ useMasterKey: true });
      }

      Parse.Cloud.run("detachPaymentMethod", {
        paymentMethodId: paymentMethod.id,
      }).then(async () => {
        await Logger.deleteUserPaymentMethod(session, user, paymentMethod);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    listPaymentMethods();
  }, [isLoading]);

  return (
    <Modal
      open={isOpen}
      title='User Payment Methods'
      classes={"w-4/5 md:w-1/2 xl:w-1/3 text-text-main"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <Loader isLoading={isLoading} />
      <div className='flex flex-col gap-4'>
        {paymentMethods?.length > 0 ? (
          paymentMethods.map((pm) => {
            return <Card key={pm.id} card={pm} handleDelete={handleDelete} />;
          })
        ) : (
          <div>User has no payment methods set.</div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentMethodsModal;
