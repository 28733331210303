import Parse from "parse";
import Discount from "./Discount";

export default class Renting {
  static STATUS = {
    ACTIVE: "ACTIVE",
    UNPAID: "UNPAID",
    COMPLETED: "COMPLETED",
  };

  static getStatus = (isFinished, isPaid) => {
    switch (true) {
      case isFinished && isPaid:
        return this.STATUS.COMPLETED;
      case isFinished && !isPaid:
        return this.STATUS.UNPAID;
      case !isFinished && !isPaid:
        return this.STATUS.ACTIVE;
      default:
        return "UNDEFINED";
    }
  };

  static getColor = (isFinished, isPaid) => {
    const status = this.getStatus(isFinished, isPaid);
    
    switch (status) {
      case this.STATUS.COMPLETED:
        return "bg-green-500";
      case this.STATUS.UNPAID:
        return "bg-red-500";
      case this.STATUS.ACTIVE:
        return "bg-blue-500";
      default:
        break;
    }
  };

  static discountCalculator = (bill, discount) => {
    let calcDiscount = null;

    if (!!discount) {
      switch (discount.type) {
        case Discount.TYPE.FIX:
          calcDiscount = discount.amount;
          break;
        case Discount.TYPE.REFERRAL:
          calcDiscount = 3;
          break;
        case Discount.TYPE.RIDE:
          calcDiscount = bill;
          break;
        case Discount.TYPE.PERCENTAGE:
          calcDiscount = (discount.amount / 100) * bill;
          break;
        default:
          break;
      }
    }

    return calcDiscount;
  };

  static totalBillCalculator = (bill, discount) => {
    return parseFloat(bill - discount < 0 ? 0 : bill - discount).toFixed(2);
  };

  // This method accepts a Rentings set from a Query("Rentings") type, calculates the total bill of each renting by taking into account applied discounts
  // and returns a new Rentings set that includes the `totalBill` property in the attributes
  static provideTotalBill = async (rentingsResultSet) => {
    return await Promise.all(
      rentingsResultSet.map(async (r) => {
        let discount = null;
        const bill = r.get("bill");
        const hasDiscount = r.get("payment")?.get("usedDiscount");

        if (!!hasDiscount) {
          const d =
            (await new Parse.Object("Discount")
              .set("objectId", hasDiscount.id)
              .fetchWithInclude()) ?? null;

          if (!!d) {
            discount = {
              type: d.get("type"),
              amount: d.get("amount"),
            };
          } else {
            discount = {
              type: null,
              amount: 0,
            };
          }

          const calcDiscount = this.discountCalculator(bill, discount);
          const totalBill = this.totalBillCalculator(bill, calcDiscount);

          return {
            ...r,
            attributes: {
              ...r.attributes,
              discount: { ...discount, applies: calcDiscount },
              totalBill: Number(totalBill),
            },
          };
        } else {
          return {
            ...r,
            attributes: { ...r.attributes, discount: discount, totalBill: Number(bill) },
          };
        }
      })
    );
  };
}
