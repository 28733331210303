import User from "../../models/User";
import Authorizer from "../../components/Authorizer";
import IncidentsPageState from "./state/IncidentsPageState";

const IncidentsPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <IncidentsPageState {...props} />
    </Authorizer>
  );
};

export default IncidentsPage;
