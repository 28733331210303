import "../../../styles/global.css";
import Utils from "../../../utils/Utils";
import Modal from "../../../components/Modal";
import Comms from "../../../models/Communication";
import { findOption } from "../../../utils/Forms";
import { useEffect, useMemo, useState } from "react";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTextarea from "../../../components/form/FormTextarea";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FormDatepicker from "../../../components/form/FormDatepicker";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";
import dayjs from "dayjs";

const CommsModal = ({
  isOpen,
  isLoading,
  editingComm,
  toggleModal,
  setEditingCom,
  handleSubmitCommunication,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [commSuccess, setCommSuccess] = useState(false);
  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });

  const [form, setForm] = useState({
    type: null,
    subject: "",
    content: "",
    status: null,
    followup: null,
    priority: null,
    attachment: null,
    tags: selectedTags,
  });

  const initializeForm = useMemo(() => {
    editingComm && setSelectedTags(editingComm.attributes.tags ?? []);
    return {
      type: editingComm ? editingComm.attributes.type : null,
      subject: editingComm ? editingComm.attributes.subject : "",
      content: editingComm ? editingComm.attributes.content : "",
      status: editingComm ? editingComm.attributes.status : null,
      followup: editingComm?.attributes?.followupDate
        ? dayjs(editingComm.attributes.followupDate)
        : null,
      priority: editingComm ? editingComm.attributes.priority : null,
      tags: editingComm ? editingComm.attributes.tags : selectedTags,
      attachment: null,
    };
  }, [editingComm]);

  const typeOptions = Object.keys(Comms.TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(form.type)
    ? findOption(typeOptions, String(form.type)?.toUpperCase())
    : null;

  const priorityOptions = Object.keys(Comms.PRIORITY).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const priorityDefaultOption = !Utils.isNull(form.priority)
    ? findOption(priorityOptions, String(form.priority)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Comms.STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(form.status)
    ? findOption(statusOptions, String(form.status)?.toUpperCase())
    : null;

  const tags = Object.keys(Comms.TAGS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));

  const handleFormChange = (field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleSetTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags((prev) => {
        const newTags = prev.filter((t) => t !== tag);
        setForm((prev) => ({ ...prev, tags: newTags }));
        return newTags;
      });
    } else {
      setSelectedTags((prev) => {
        const newTags = [...prev, tag];
        setForm((prev) => ({ ...prev, tags: newTags }));
        return newTags;
      });
    }
  };

  const handleSubmit = () => {
    if (!form.type || !form.status || !form.priority || !form.subject) {
      setCommSuccess(false);
      setResponseState({
        type: false,
        message: "Fill in all the required fields.",
      });
    } else {
      handleSubmitCommunication(form, editingComm?.id ?? null).then((r) => {
        if (r) {
          setCommSuccess(true);
          setTimeout(() => {
            setForm({
              type: null,
              subject: "",
              content: "",
              status: null,
              followup: null,
              priority: null,
              attachment: null,
              tags: setSelectedTags([]),
            });
            toggleModal(false);
            setCommSuccess(false);
            setEditingCom(null);
          }, 1500);
        } else {
          setCommSuccess(false);
          setResponseState({
            type: false,
            message: "Something went wrong. Try again.",
          });
        }
      });
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    setForm({
      type: null,
      subject: "",
      content: "",
      status: null,
      followup: null,
      priority: null,
      attachment: null,
      tags: setSelectedTags([]),
    });
    setResponseState({ type: null, message: null });
    setEditingCom(null);
  };

  useEffect(() => {
    setForm(initializeForm);
  }, [editingComm]);

  return (
    <Modal
      open={isOpen}
      title='Communication'
      classes={"w-4/5 md:w-1/2 lg:w-3/5 xl:w-1/2"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='grid grid-cols-1 gap-1'>
        <FormSelect
          label='Type'
          options={typeOptions}
          disabled={!!editingComm}
          value={typeDefaultOption}
          handleChange={(value) => handleFormChange("type", value.value)}
        />
        <FormSelect
          label='Priority'
          disabled={!!editingComm}
          options={priorityOptions}
          value={priorityDefaultOption}
          handleChange={(value) => handleFormChange("priority", value.value)}
        />
        <FormSelect
          label='Status'
          options={statusOptions}
          value={statusDefaultOption}
          handleChange={(value) => handleFormChange("status", value.value)}
        />
        <div>
          <span className='inline-block text-text-main mb-1 font-medium pl-1'>Tags</span>
          <div className='flex flex-wrap items-center gap-1 p-2 bg-bg-input border border-solid border-border-input rounded'>
            {tags.map((t) => (
              <div
                key={t.value}
                className={`rounded-lg px-1 text-sm cursor-pointer ${
                  selectedTags.includes(t.value)
                    ? "bg-gray-300 text-gray-900"
                    : "bg-bg-modal text-text-main"
                }`}
                onClick={() => handleSetTag(t.value)}
              >
                {t.label}
              </div>
            ))}
          </div>
        </div>
        <FormDatepicker
          withTime={true}
          label='Follow Up'
          isClearable={true}
          value={form.followup}
          handleChange={(value) => handleFormChange("followup", value ?? null)}
        />
        <FormInput
          type='text'
          label='Subject'
          value={form.subject}
          placeholder='Subject'
          disabled={!!editingComm}
          handleChange={(e) => handleFormChange("subject", e.target.value)}
        />
        <FormTextarea
          rows={5}
          label='Content'
          value={form.content}
          placeholder='Type the communication conten'
          handleChange={(value) => handleFormChange("content", value)}
        />
        <FileUploadDropzone
          isMultifile={false}
          acceptedTypes={["pdf", "jpg", "jpeg", "png"]}
          title='Drag and drop attachment here.'
          emitFiles={(file) => handleFormChange("attachment", file)}
        />

        {!Utils.isNull(responseState.type) && (
          <div className='mt-1'>
            <span
              className={`text-sm font-bold ${
                responseState.type ? "text-green-400" : "text-red-400"
              }`}
            >
              {responseState.message}
            </span>
          </div>
        )}
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            disabled={isLoading || commSuccess}
            classes={`bg-blue-500 py-2 ${isLoading ? "pr-7" : "pr-2"}`}
          >
            <span className='text-white'>Add</span>
            {commSuccess && <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CommsModal;
