import Utils from "../../../utils/Utils";
import { useEffect, useRef, useState } from "react";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const LoginPageView = ({
  errors,
  username,
  password,
  isLoading,
  OTPstatus,
  OTPverified,
  handleLogin,
  setUsername,
  setPassword,
  handleVerifyOTP,
}) => {
  const firstRef = useRef(null);
  const [first, setFirst] = useState("");
  const secondRef = useRef(null);
  const [second, setSecond] = useState("");
  const thirdRef = useRef(null);
  const [third, setThird] = useState("");
  const fourthRef = useRef(null);
  const [fourth, setFourth] = useState("");
  const fifthRef = useRef(null);
  const [fifth, setFifth] = useState("");
  const sixthRef = useRef(null);
  const [sixth, setSixth] = useState("");
  const [isDisabled, setDisabled] = useState(null);

  const handleInputChange = (e) => {
    if (!!!e.target.value) {
      if (e.target.id === "first") {
        setFirst("");
      } else if (e.target.id === "second") {
        setSecond("");
        firstRef.current.focus();
      } else if (e.target.id === "third") {
        setThird("");
        secondRef.current.focus();
      } else if (e.target.id === "fourth") {
        setFourth("");
        thirdRef.current.focus();
      } else if (e.target.id === "fifth") {
        setFifth("");
        fourthRef.current.focus();
      } else {
        setSixth("");
        fifthRef.current.focus();
      }
    } else {
      if (e.target.value.length === 6) {
        const val = e.target.value;
        setFirst(val[0]);
        setSecond(val[1]);
        setThird(val[2]);
        setFourth(val[3]);
        setFifth(val[4]);
        setSixth(val[5]);
      } else {
        if (e.target.id === "first") {
          setFirst(e.target.value);
          secondRef.current.focus();
        } else if (e.target.id === "second") {
          setSecond(e.target.value);
          thirdRef.current.focus();
        } else if (e.target.id === "third") {
          setThird(e.target.value);
          fourthRef.current.focus();
        } else if (e.target.id === "fourth") {
          setFourth(e.target.value);
          fifthRef.current.focus();
        } else if (e.target.id === "fifth") {
          setFifth(e.target.value);
          sixthRef.current.focus();
        } else {
          setSixth(e.target.value);
        }
      }
    }
  };

  useEffect(() => {
    if (!!first && !!second && !!third && !!fourth && !!fifth && !!sixth) {
      setDisabled(true);
      handleVerifyOTP(first + second + third + fourth + fifth + sixth);
    }
  }, [first, second, third, fourth, fifth, sixth]);

  useEffect(() => {
    if (!OTPverified) setDisabled(false);
    else setDisabled(true);
  }, [OTPverified]);

  return (
    <div className='h-full login-panel'>
      <div className='login-container'>
        <div className='text-center mb-10'>
          <span className='title'>Sign In</span>
        </div>
        <div className='mb-4'>
          <FormInput
            type='text'
            classes='w-full'
            placeholder='Username'
            value={username}
            handleChange={setUsername}
          />
        </div>
        <div className='relative mb-4'>
          <FormInput
            type='password'
            classes='w-full'
            placeholder='Password'
            value={password}
            handleChange={setPassword}
          />
        </div>
        {OTPstatus === "pending" && (
          <div className='my-6 text-text-main'>
            {Utils.isNull(OTPverified) ? (
              <div className='flex space-x-2 justify-center items-center mt-2 mb-2'>
                <div className='h-4 w-4 bg-text-main rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                <div className='h-4 w-4 bg-text-main rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div className='h-4 w-4 bg-text-main rounded-full animate-bounce'></div>
              </div>
            ) : OTPverified ? (
              <div className='flex justify-center'>
                <FontAwesomeIcon icon={faCircleCheck} size='2x' className='text-green-500' />
              </div>
            ) : (
              <div className='flex justify-center'>
                <FontAwesomeIcon icon={faCircleXmark} size='2x' className='text-red-500' />
              </div>
            )}

            <div className='mb-4'>
              <span>
                There was an OTP sent to your email. Copy and paste it here to continue logging in.
              </span>
            </div>
            <div className='flex gap-2 items-center justify-center'>
              <FormInput
                id='first'
                type='text'
                value={first}
                ref={firstRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
              <FormInput
                id='second'
                type='text'
                maxLength='1'
                value={second}
                ref={secondRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
              <FormInput
                id='third'
                type='text'
                maxLength='1'
                value={third}
                ref={thirdRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
              <FormInput
                id='fourth'
                type='text'
                maxLength='1'
                value={fourth}
                ref={fourthRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
              <FormInput
                id='fifth'
                type='text'
                maxLength='1'
                value={fifth}
                ref={fifthRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
              <FormInput
                id='sixth'
                type='text'
                maxLength='1'
                value={sixth}
                ref={sixthRef}
                classes='w-[51px]'
                disabled={isDisabled}
                handleChange={handleInputChange}
              />
            </div>
          </div>
        )}
        {OTPstatus === "failed" && (
          <div>
            <span className='text-red-500'>
              Something went unexpectedly wrong. <br /> Please refresh the page and try again.
            </span>
          </div>
        )}
        <Button
          classes='login-button mt-4'
          isLoading={isLoading || (!OTPverified && !Utils.isNull(OTPverified))}
          handleClick={handleLogin}
        >
          <span>{OTPverified ? "Continue" : "Login"}</span>
        </Button>
        {!!errors && (
          <div className='mt-5'>
            <span className='text-lg text-red-500'>{errors}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPageView;
