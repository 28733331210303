import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus } from "@fortawesome/free-solid-svg-icons";

const FormCheckbox = ({ label, value, classes, disabled, handleChange, indeterminate = false }) => {
  const [checked, setChecked] = useState(value);

  const handleCheckToggle = (value) => {
    setChecked(value);
    handleChange(value);
  };

  return (
    <div
      className={`inline-flex items-center ${label && "gap-2"} ${classes} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={() => (disabled ? null : handleCheckToggle(!checked))}
    >
      <div
        className={`form-checkbox relative ${(checked || indeterminate) && "is-checked"} ${
          disabled && "grayscale"
        }`}
      >
        {(checked || indeterminate) && (
          <FontAwesomeIcon
            icon={indeterminate ? faMinus : faCheck}
            className='absolute icon top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-4 h-4 max-h-4 min-h-4 max-w-4 min-w-4'
          />
        )}
      </div>
      <span className='text-sm text-text-main'>{label}</span>
    </div>
  );
};

export default FormCheckbox;
