const ActiveStatusBlinker = ({ color = "blue-500" }) => {
  return (
    <span className="relative flex h-3 w-3">
      <span
        className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-${color} opacity-75`}
      ></span>
      <span
        className={`animate-pulse relative inline-flex rounded-full h-3 w-3 bg-${color}`}
      ></span>
    </span>
  );
};

export default ActiveStatusBlinker;
