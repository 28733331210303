import { Rating } from "@mui/material";
import FormTextarea from "../../../components/form/FormTextarea";

const ReviewDetails = ({ renting }) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Renting Review</span>
      </div>
      <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-2'>
          <span>Star Rating:</span>
          <Rating name='read-only' value={renting.feedback.starRating} precision={0.1} readOnly />
        </div>
        <div className='flex items-center gap-2'>
          <span>Clean Rating:</span>
          <Rating name='read-only' value={renting.feedback.cleanRating} precision={0.1} readOnly />
        </div>
        <div>
          <span className='block mb-1'>Reason of Use:</span>
          <FormTextarea value={renting.feedback.reasonOfUse} rows={4} disabled={true} />
        </div>
      </div>
    </>
  );
};

export default ReviewDetails;
