import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import CommsPageView from "../view/CommsPageView";
import { getViewportWidth } from "../../../utils/Viewport";
import Logger from "../../../models/Logger";

const CommsPageState = ({ userId = null }) => {
  const userHook = useUser();
  const windowSize = getViewportWidth();
  const [comms, setComms] = useState([]);
  const [filters, setFilters] = useState({});
  const session = userHook.getCurrentSession();
  const isExternalPage = !Utils.isNull(userId);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoadingComm, setLoadingComm] = useState(false);
  const [sorting, setSorting] = useState([{ columnName: "communicationDate", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: isExternalPage ? 3 : windowSize === "MOBILE" ? 10 : 25,
  });

  const getComms = async () => {
    const query = new Parse.Query("CommunicationHistory");
    query.includeAll();

    if (!!userId) {
      const userPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: userId,
      };
      query.equalTo("user", userPointer);
    }

    try {
      if (Object.keys(filters).length > 0) {
        filters.type && query.equalTo("type", filters.type);
        filters.priority && query.equalTo("priority", filters.priority);
        filters.status && query.equalTo("status", filters.status);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then((r) => {
          setComms(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleSubmitCommunication = async (form, commId = null) => {
    setLoadingComm(true);
    return await userHook
      .handleAddComm(userId, form, commId)
      .then(() => {
        setLoadingComm(false);
        getComms();
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        return false;
      });
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const comm = new Parse.Object("CommunicationHistory").set("objectId", id);
      new Parse.Object("CommunicationHistory")
        .set("objectId", id)
        .destroy()
        .then(async () => {
          getComms();
          await Logger.deleteUserComm(session, comm);
        });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getComms();
  }, [filters, sorting, pagination]);

  return (
    <>
      {comms ? (
        <CommsPageView
          comms={comms}
          filters={filters}
          sorting={sorting}
          isLoading={isLoading}
          pagination={pagination}
          handleDelete={handleDelete}
          isLoadingComm={isLoadingComm}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
          handleSubmitCommunication={handleSubmitCommunication}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default CommsPageState;
