import { useState } from "react";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils/Dates";
import { decryptMessage } from "../../../utils/Crypto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userMessageSerializer } from "../../../models/serializers";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { PagingState, SortingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const UsersTable = ({
  sorting,
  isLoading,
  pagination,
  userMessages,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const sortableColumns = ["createdAt"];
  const [expandedRows, setExpandedRows] = useState([]);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveSeen = (seen) => {
    return (
      <FontAwesomeIcon
        icon={seen ? faEye : faEyeSlash}
        className={`text-lg ${seen ? "text-green-600" : "text-gray-400"}`}
      />
    );
  };

  const resolveCreatedAt = (time) => {
    return dateFormatter(time, true);
  };

  const columns = [
    {
      name: "createdAt",
      title: "Created At",
      getCellValue: (data) => data.attributes.createdAt,
    },
  ];

  const cellComponent = (props) => {
    const message = userMessageSerializer(props.row);
    const type = message.type;

    const getTypeLabelColor = () => {
      if (type === "INFO") {
        return "bg-blue-500";
      } else if (type === "REMINDER") {
        return "bg-yellow-500";
      } else {
        return "bg-red-500";
      }
    };

    return (
      <Table.Cell {...props}>
        <div className='user-messages-cell'>
          <div className='cell-wrapper'>
            <div className='cell-inner col-span-12 xl:col-span-3'>
              <span className='font-bold mr-1'>Recipient:</span>
              <span>
                {message.reciever?.username ? message.reciever?.username : "User Deleted"}
              </span>
            </div>
            <div className='cell-inner col-span-12 xl:col-span-3'>
              <span className='font-bold mr-1'>Sent By:</span>
              <span>{message.sender.username}</span>
            </div>
            <div className='cell-inner col-span-12 xl:col-span-4 relative'>
              <div className={`absolute right-0 w-min px-1 rounded text-white ${getTypeLabelColor()}`}>
                {message.type}
              </div>
              <span>{message.title}</span>
            </div>
            <div className='cell-inner col-span-12 xl:col-span-2 justify-end gap-4'>
              <span>{resolveSeen(message.seen)}</span>
              <span>{resolveCreatedAt(message.createdAt)}</span>
            </div>
          </div>
        </div>
      </Table.Cell>
    );
  };

  const RowDetail = ({ row }) => {
    const message = row.attributes.message;
    return (
      <div className='p-2'>
        <span>{decryptMessage(message)}</span>
      </div>
    );
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='user-messages-table'>
          <Grid rows={userMessages} columns={columns} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRows}
              onExpandedRowIdsChange={setExpandedRows}
            />
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "createdAt",
                },
              ]}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <PagingPanel pageSizes={[...(isExternalPage ? [3, 5] : []), 10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
