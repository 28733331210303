import { useState } from "react";
import Filters from "../components/Filters";
import CommsModal from "../components/CommsModal";
import CommsTable from "../components/CommsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";

const CommsPageView = ({
  comms,
  filters,
  sorting,
  isLoading,
  pagination,
  handleDelete,
  isLoadingComm,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleClearFilters,
  handleFilterChange,
  handlePageSizeChange,
  handleSubmitCommunication,
}) => {
  const [editingComm, setEditingCom] = useState(null);
  const [isCommsModalOpen, toggleCommsModal] = useState(false);

  const handleEdit = (row) => {
    setEditingCom(row);
    toggleCommsModal(true);
  };

  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <>
            <div className='page-title'>
              <span className='title'>COMMUNICATION HISTORY</span>
            </div>
            <Filters
              filters={filters}
              isLoading={isLoading}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          </>
        )}
        <div className='table-wrapper'>
          <div className='external-header'>
            <div className='title-inner'>
              <span>Communication History</span>
            </div>
            <div
              className='flex items-center gap-1 text-text-main cursor-pointer'
              onClick={() => toggleCommsModal(true)}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
              <span className='text-sm sm:text-base'>Log Communication</span>
            </div>
          </div>
          <CommsTable
            comms={comms}
            filters={filters}
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSorting={handleSorting}
            totalElements={totalElements}
            isExternalPage={isExternalPage}
            handlePageChange={handlePageChange}
            handleFilterChange={handleFilterChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <CommsModal
        editingComm={editingComm}
        isOpen={isCommsModalOpen}
        isLoading={isLoadingComm}
        setEditingCom={setEditingCom}
        toggleModal={toggleCommsModal}
        handleSubmitCommunication={handleSubmitCommunication}
      />
    </div>
  );
};

export default CommsPageView;
