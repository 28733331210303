import "../../../styles/global.css";
import Modal from "../../../components/Modal";
import FormCheckbox from "../../../components/form/FormCheckbox";
import Button from "../../../components/form/Button";

const ExportModal = ({
  isOpen,
  isLoading,
  toggleModal,
  exportOptions,
  setExportOptions,
  handleExportData,
}) => {
  return (
    <Modal
      open={isOpen}
      title='Select Export Options'
      classes={"w-4/5 md:w-1/3 xl:w-1/4"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div>
        <div className='flex flex-col gap-2'>
          <FormCheckbox
            label='Include Filters'
            value={exportOptions.filters}
            handleChange={(checked) => setExportOptions((prev) => ({ ...prev, filters: checked }))}
          />
          <FormCheckbox
            label='Include Tab'
            value={exportOptions.tabs}
            handleChange={(checked) => setExportOptions((prev) => ({ ...prev, tabs: checked }))}
          />
        </div>
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button
            isLoading={isLoading}
            classes='bg-gray-200 py-2'
            handleClick={() => toggleModal(!isOpen)}
          >
            <span>Cancel</span>
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            handleClick={handleExportData}
            classes={`bg-blue-500 py-2`}
          >
            <span className='text-white'>Export</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
