import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="absolute z-50 left-0 top-0 w-full h-full bg-gray-700 bg-opacity-30 rounded shadow">
          <div className="absolute z-50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <FontAwesomeIcon
              className="relative z-50 animate-spin text-gray-50"
              icon={faCircleNotch}
              size="2xl"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
