import { useState } from "react";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils/Dates";
import MaintenanceModel from "../../../models/Maintenance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faUser } from "@fortawesome/free-solid-svg-icons";
import { getViewportWidth, isBelow } from "../../../utils/Viewport";
import { maintenanceSerializer } from "../../../models/serializers";
import MaintenancesRowDetails from "../components/MaintenancesRowDetails";
import ActiveStatusBlinker from "../../../components/ActiveStatusBlinker";
import { SortingState, RowDetailState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const UserRentingsTable = ({
  sorting,
  isLoading,
  pagination,
  maintenances,
  totalElements,
  handleSorting,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = ["startTime", "endTime"];
  const [expandedRows, setExpandedRows] = useState([]);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveCarPlate = (data) => {
    const maintenance = data.maintenance;
    return (
      <Link className='link' to={`/cars/${maintenance.attributes.car.id}`}>
        {maintenance.attributes.car.attributes.carPlate}
      </Link>
    );
  };

  const resolveUser = (data) => {
    return (
      <Link className='link' to={`/users/${data?.maintenance?.attributes?.user?.id}`}>
        {data?.maintenance?.attributes?.user?.attributes?.name}{" "}
        {data?.maintenance?.attributes?.user?.attributes?.lastName}
      </Link>
    );
  };

  const resolveEndTime = (data) => {
    const status = data.maintenance.attributes.status;
    const endTime = dateFormatter(data.maintenance.attributes.endTime, false);

    if (Utils.isNull(endTime)) {
      return (
        <div className='flex items-center justify-center'>
          <ActiveStatusBlinker
            color={status === MaintenanceModel.STATUS.PAUSED ? "orange-500" : "green-500"}
          />
          <span className='ml-2'>
            {status === MaintenanceModel.STATUS.STARTED ||
            status === MaintenanceModel.STATUS.CONTINUED
              ? "Ongoing"
              : "Paused"}
          </span>
        </div>
      );
    } else {
      return endTime;
    }
  };

  const resolveStatus = (data) => {
    const status = data.maintenance.attributes.status;

    return <div>{Utils.textFirstOnlyUpper(status)}</div>;
  };

  const RowDetail = ({ row }) => {
    const maintenance = maintenanceSerializer(row.maintenance);

    return <MaintenancesRowDetails maintenance={maintenance} jobs={row.jobs} />;
  };

  const resolveColumns = () => {
    let columns = [
      {
        name: "startTime",
        title: isBelow("LG") ? "Maintenances" : "Start Time",
        getCellValue: (data) => dateFormatter(data.maintenance.attributes.startTime, true),
      },
    ];

    switch (true) {
      case isBelow("LG"):
        return columns;
      default:
        return [
          {
            name: "user",
            title: "User",
            getCellValue: resolveUser,
          },
          {
            name: "carPlate",
            title: "Car",
            getCellValue: resolveCarPlate,
          },
          {
            name: "status",
            title: "Status",
            getCellValue: resolveStatus,
          },
          {
            name: "startTime",
            title: "Start",
            getCellValue: (data) => dateFormatter(data.maintenance.attributes.startTime, false),
          },
          {
            name: "endTime",
            title: "End",
            getCellValue: resolveEndTime,
          },
        ];
    }
  };

  const columns = resolveColumns();

  const rowComponent = (props) => {
    const maintenance = props.row.maintenance;
    const status = maintenance.attributes.status;

    return (
      <>
        {status === MaintenanceModel.STATUS.CANCELLED ? (
          <Table.Row className='bg-red-500 bg-opacity-15' {...props} />
        ) : status === MaintenanceModel.STATUS.STARTED ? (
          <Table.Row className='bg-green-500 bg-opacity-15' {...props} />
        ) : (
          <Table.Row {...props} />
        )}
      </>
    );
  };

  const cellComponent = (props) => {
    if (isBelow("LG")) {
      const maintenance = props.row;

      return (
        <Table.Cell {...props} padding='none'>
          <div className='py-2 flex flex-col md:flex-row gap-1 sm:gap-2 justify-between'>
            <div className='flex flex-col sm:flex-row gap-1 sm:gap-2'>
              <span>
                <FontAwesomeIcon icon={faUser} /> {resolveUser(maintenance)}
              </span>
              <span>
                <FontAwesomeIcon icon={faCar} /> {resolveCarPlate(maintenance)}
              </span>
            </div>
            <div className='flex justify-between sm:flex-row gap-2'>
              <span>{dateFormatter(maintenance.maintenance.get("startTime"), true)}</span>
              <span>{resolveStatus(maintenance)}</span>
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='fixed-table'>
          <Grid rows={maintenances} columns={columns} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRows}
              onExpandedRowIdsChange={setExpandedRows}
            />
            <SortingState
              columnExtensions={[
                { columnName: "user", sortingEnabled: false },
                { columnName: "carPlate", sortingEnabled: false },
                { columnName: "status", sortingEnabled: true },
                { columnName: "startTime", sortingEnabled: true },
                { columnName: "endTime", sortingEnabled: true },
              ]}
              sorting={sorting}
              onSortingChange={handleSorting}
            />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  columnName: "user",
                  wordWrapEnabled: false,
                  width: "auto",
                },
                {
                  columnName: "carPlate",
                  wordWrapEnabled: false,
                  width: windowSize === "MOBILE" ? "auto" : "90",
                  align: windowSize === "MOBILE" ? "left" : "center",
                },
                {
                  columnName: "status",
                  wordWrapEnabled: true,
                  width: "110",
                  align: "center",
                },
                {
                  columnName: "startTime",
                  wordWrapEnabled: true,
                  width: isBelow("LG") ? "auto" : "120",
                  align: isBelow("LG") ? "left" : "center",
                },
                {
                  columnName: "endTime",
                  wordWrapEnabled: true,
                  width: "120",
                  align: "center",
                },
              ]}
              cellComponent={cellComponent}
              rowComponent={rowComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UserRentingsTable;
