import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { isBelow } from "../../../utils/Viewport";
import IncidentRowDetail from "./IncidentRowDetail";
import { dateFormatter } from "../../../utils/Dates";
import { incidentSerializer } from "../../../models/serializers";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { SortingState, PagingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const IncidentsTable = ({
  sorting,
  incidents,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const sortableColumns = ["date", "createdAt"];
  const [expandedRows, setExpandedRows] = useState([]);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveRenting = (row) => {
    const incident = incidentSerializer(row);
    return (
      <Link className='link' to={`/rentings/${incident?.renting?.id}`}>
        <span>{incident?.renting?.id}</span>
      </Link>
    );
  };

  const resolveSubmittedBy = (row) => {
    const incident = incidentSerializer(row);
    return (
      <Link className='link' to={`/users/${incident.submittedBy.id}`}>
        <span>{incident.submittedBy.username}</span>
      </Link>
    );
  };

  const resolveDamages = (row) => {
    const incident = incidentSerializer(row);
    return (
      <div className='flex flex-col gap-1'>
        <FormCheckbox label='Car Damage?' value={incident.carDamage} disabled={true} />
        <FormCheckbox label='Injury?' value={incident.injury} disabled={true} />
      </div>
    );
  };

  const resolveDate = (row) => {
    return dateFormatter(row.attributes.date, true);
  };

  const resolveCreatedAt = (row) => {
    return dateFormatter(row.attributes.createdAt, true);
  };

  const resolveMobile = (row) => {
    return (
      <div className='grid grid-cols-2 gap-2 text-base'>
        <div className='flex flex-col gap-1'>
          {resolveRenting(row)}
          {resolveSubmittedBy(row)}
        </div>
        {resolveDamages(row)}
        <div className='col-span-2'>
          <div className='flex flex-col'>
            <div>Incident Date: {resolveDate(row)}</div>
            <div>Created At: {resolveCreatedAt(row)}</div>
          </div>
        </div>
      </div>
    );
  };

  const resolveColumns = () => {
    switch (true) {
      case isBelow("SM"):
        return [
          {
            name: "date",
            title: "Date",
            getCellValue: resolveMobile,
          },
        ];
      default:
        return [
          {
            name: "renting",
            title: "Renting",
            getCellValue: resolveRenting,
          },
          {
            name: "submittedBy",
            title: "Submitted By",
            getCellValue: resolveSubmittedBy,
          },
          {
            name: "damages",
            title: "Damages",
            getCellValue: resolveDamages,
          },
          {
            name: "date",
            title: "Date",
            getCellValue: resolveDate,
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: resolveCreatedAt,
          },
        ];
    }
  };

  const columns = resolveColumns();

  const RowDetail = ({ row }) => {
    return <IncidentRowDetail row={row} />;
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='incidents-table'>
          <Grid rows={incidents} columns={columns} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRows}
              onExpandedRowIdsChange={setExpandedRows}
            />
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "renting",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "submittedBy",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "damages",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "date",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "createdAt",
                },
              ]}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default IncidentsTable;
