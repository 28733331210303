const UserAdditionalInfomration = ({ user }) => {
  return (
    <div>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Additional Information</span>
      </div>
      <div className='user-details'>
        <span>ID:</span>
        <span>{user.id}</span>
        <span>Customer ID:</span>
        <span>{user.customerId}</span>
        <span>Created At:</span>
        <span>{user.createdAt}</span>
        <span>Updated At:</span>
        <span>{user.updatedAt}</span>
        {user.address && (
          <>
            <span>Address:</span>
            <span>{user.address}</span>
          </>
        )}
        {user.city && (
          <>
            <span>City:</span>
            <span>{user.city}</span>
          </>
        )}
        {user.country && (
          <>
            <span>Country:</span>
            <span>
              {user.country.icon} {user.country.name}
            </span>
          </>
        )}
        {user.postalCode && (
          <>
            <span>Postal Code:</span>
            <span>{user.postalCode}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default UserAdditionalInfomration;
