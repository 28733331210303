import MapMarker from "./MapMarker.js";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Utils from "../../../utils/Utils.js";
import { carSerializer } from "../../../models/serializers";
import { Polygon } from "../../../components/map/Polygon.ts";
import { GEOCORDS_OF_SERVICE } from "../../../models/Baseline.js";
import { InfoWindow, Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

const MapTracker = ({
  cars,
  singleCar,
  carHovered,
  isInfoOpen,
  setCarHovered,
  selectedMarker,
  handleMarkerClick,
  handleCloseMarker,
}) => {
  const map = useMap("cars-map");
  const [zoom, setZoom] = useState(11);
  const coreLib = useMapsLibrary("core");
  const [markers, setMarkers] = useState({});
  const [hoveringLabel, setHoveringLabel] = useState(null);
  const [center, setCenter] = useState({ lat: 37.997876, lng: 23.726409 });

  const carPositions = cars.map((car) => {
    const c = Utils.isDefined(car.carPlate) ? car : carSerializer(car);

    return { lat: c.location.latitude, lng: c.location.longitude };
  });

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const infoWindow = () => {
    const car = carSerializer(cars.find((c) => c.id === selectedMarker));

    return (
      <InfoWindow
        headerContent={
          <div className='flex items-center gap-2'>
            <div>
              <Link className='link' to={`/cars/${car.id}`}>
                <span className='font-semibold text-lg'>{car.carPlate}</span>
              </Link>
              <div className='flex items-center gap-x-1 text-base font-medium'>
                <span>{car.brand}</span>
                <span>{car.model}</span>
              </div>
            </div>
            <img src={car.photo} className='w-24 sm:w-32' />
          </div>
        }
        shouldFocus={true}
        onCloseClick={handleCloseMarker}
        anchor={markers[Object.keys(markers).find((m) => m === selectedMarker)]}
        pl
      >
        <div>
          <div className='flex items-center gap-x-1'>
            <span className='text-base font-medium'>Address:</span>
            <span className='text-base'>{car.address}</span>
          </div>
          <div className='flex items-center gap-x-1'>
            <span className='text-base font-medium'>Last rented:</span>
            <span className='text-base'>{car.latestRide}</span>
          </div>
          {!!car.maintenance && (
            <div className='flex items-start gap-x-1'>
              <span className='text-base font-medium'>Maintenance:</span>
              <span className='text-base'>
                Planned maintenance on{" "}
                <span className='font-bold'>{car.maintenance.startTime}</span>
                <br /> by maintainer{" "}
                <span className='font-bold'>{car.maintenance.user.username}</span>.
              </span>
            </div>
          )}
        </div>
      </InfoWindow>
    );
  };

  const handleZoomChange = (props) => {
    setZoom(props.detail.zoom);
  };

  useEffect(() => {
    if (!!map) {
      const bounds = new coreLib.LatLngBounds();
      carPositions.forEach((coord) => {
        bounds.extend(coord);
      });
      map.fitBounds(bounds);
      setCenter(map.getCenter());
      if (singleCar) map.setZoom(map.getZoom() - 10);
    }
  }, [cars, map]);

  return (
    <>
      {!!cars && (
        <Map
          id='cars-map'
          zoom={zoom}
          defaultCenter={center}
          disableDefaultUI={false}
          gestureHandling={"greedy"}
          onZoomChanged={handleZoomChange}
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
        >
          <Polygon
            strokeWeight='.5'
            fillOpacity='0.25'
            fillColor={"#4361EE"}
            strokeColor={"#4361EE"}
            paths={GEOCORDS_OF_SERVICE.ATHENS}
          />
          {cars.map((car) => {
            const c = Utils.isDefined(car.carPlate) ? car : carSerializer(car);
            return (
              <MapMarker
                car={c}
                key={c.id}
                zoom={zoom}
                carHovered={carHovered}
                setMarkerRef={setMarkerRef}
                hoveringLabel={hoveringLabel}
                setCarHovered={setCarHovered}
                selectedMarker={selectedMarker}
                setHoveringLabel={setHoveringLabel}
                handleMarkerClick={handleMarkerClick}
              />
            );
          })}
          {isInfoOpen && selectedMarker && infoWindow()}
        </Map>
      )}
    </>
  );
};

export default MapTracker;
