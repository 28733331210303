import { Tooltip } from "@mui/material";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";

const Status = ({ isRejected, isAuthorized }) => {
  const color = User.getColor(isRejected, isAuthorized);
  const userStatus = User.getStatus(isRejected, isAuthorized);

  return (
    <div className={`w-fit font-bold text-white rounded px-2 py-1 ${color}`}>
      <Tooltip title={User.getStatusTooltip(userStatus)} placement='top'>
        <div>{Utils.textFirstOnlyUpper(userStatus)}</div>
      </Tooltip>
    </div>
  );
};

export default Status;
