import UserMetricsBlock from "../components/UserMetricsBlock";
import RideMetricsBlock from "../components/RideMetricsBlock";
import RentingMetricsBlock from "../components/RentingMetricsBlock";
import RevenueMetricsBlock from "../components/RevenueMetricsBlock";

const DashboardPageView = ({ userTotals, rentingTotals, rideTotals, revenueTotals }) => {
  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
          <UserMetricsBlock userTotals={userTotals} />
          <RentingMetricsBlock rentingTotals={rentingTotals} />
          <RideMetricsBlock rideTotals={rideTotals} />
          <RevenueMetricsBlock revenueTotals={revenueTotals} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPageView;
