import dayjs from "dayjs";
import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import { carSerializer } from "../../../models/serializers";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, isExternalPage, handleFilterChange, handleClearFilters }) => {
  const [cars, setCars] = useState([]);
  const carOptions = cars.map((c) => ({
    value: c.carPlate,
    label: c.carPlate + " - " + c.brand + " " + c.model,
  }));
  const carDefault = !Utils.isNull(filters?.carPlate)
    ? findOption(carOptions, String(filters?.carPlate)?.toUpperCase())
    : null;
  const fixedOptions = [
    { value: true, label: "Fixed" },
    { value: false, label: "Not Fixed" },
  ];
  const fixedDefault = !Utils.isNull(filters?.isFixed)
    ? findOption(fixedOptions, String(filters?.isFixed))
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const getCars = async () => {
    try {
      const response = (
        await new Parse.Query("Car")
          .addAscending("brand")
          .addAscending("model")
          .notEqualTo("inversQNR", "ARISTEST")
          .find({ useMasterKey: true })
      ).map((c) => carSerializer(c));
      setCars(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  useEffect(() => {
    getCars();
  }, []);

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='relative'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
              <div>
                <span className='text-lg font-semibold'>
                  <FormInput
                    type='text'
                    label='Username'
                    classes='w-full'
                    placeholder='Username'
                    value={Utils.isNull(filters?.username) ? "" : filters?.username}
                    handleChange={(e) => handleFilterChange("username", e.target.value)}
                  />
                </span>
              </div>
              {!isExternalPage && (
                <div>
                  <FormSelect
                    classes='w-full'
                    label='Car Plate'
                    isClearable={true}
                    value={carDefault}
                    options={carOptions}
                    placeholder='Car Plate'
                    handleChange={(option) => handleFilterChange("carPlate", option)}
                  />
                </div>
              )}
              <div>
                <FormSelect
                  classes='w-full'
                  isClearable={true}
                  label='Fixed State'
                  value={fixedDefault}
                  options={fixedOptions}
                  placeholder='Select state'
                  handleChange={(option) => handleFilterChange("isFixed", option)}
                />
              </div>
              <div>
                <FormDatepicker
                  label='From'
                  value={from}
                  isClearable={true}
                  handleChange={(value) => handleDateChange("from", value)}
                />
              </div>
              <div>
                <FormDatepicker
                  label='To'
                  value={to}
                  isClearable={true}
                  handleChange={(value) => handleDateChange("to", value)}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
