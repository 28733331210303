import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLightbox from "./ImageLightbox";
import { useCallback, useEffect, useState } from "react";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const DocumentViewer = ({ document, asIcon }) => {
  const [index, setIndex] = useState(-1);
  const imageTypes = ["jpg", "png", "jpeg"];
  const [slides, setSlides] = useState(null);
  const [isImage, setIsImage] = useState(false);

  const initializeSlides = useCallback(() => {
    const checkFileType = (file) => {
      const fileNameParts = file.split(".");
      const fileType = fileNameParts[fileNameParts.length - 1];
      return fileType;
    };

    if (imageTypes.includes(checkFileType(document))) {
      setIsImage(true);
      setSlides([{ src: document }]);
    }
  });

  useEffect(() => {
    initializeSlides();
  }, []);

  return (
    <>
      {isImage ? (
        <div className={`document-wrapper ${asIcon && "inline"}`}>
          {asIcon ? (
            <FontAwesomeIcon
              size='xl'
              icon={faImage}
              onClick={() => setIndex(0)}
              className='ml-2 cursor-pointer'
            />
          ) : (
            <div className='image-holder h-[60vh]'>
              <img src={document} alt='car-kteo-document' onClick={() => setIndex(0)} />
            </div>
          )}
        </div>
      ) : (
        <iframe className='w-full h-[50vh] md:h-[65vh]' src={document} title='title' />
      )}
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </>
  );
};

export default DocumentViewer;
