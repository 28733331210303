import User from "../../models/User";
import UserPageState from "./state/UserPageState";
import Authorizer from "../../components/Authorizer";

const UserPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <UserPageState {...props} />
    </Authorizer>
  );
};

export default UserPage;
