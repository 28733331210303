import "../styles/styles.scss";
import Filters from "../components/Filters";
import IncidentsTable from "../components/IncidentsTable";

const IncidentsPageView = ({
  filters,
  sorting,
  incidents,
  isLoading,
  hasFilters,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        <div>
          {hasFilters && (
            <Filters
              filters={filters}
              isLoading={isLoading}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          )}
          <div className='table-wrapper'>
            <IncidentsTable
              incidents={incidents}
              isLoading={isLoading}
              filters={filters}
              handleFilterChange={handleFilterChange}
              pagination={pagination}
              totalElements={totalElements}
              sorting={sorting}
              handleSorting={handleSorting}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentsPageView;
