const FormRadio = ({ name, value, label, handleChange, checked = false }) => {
  const handleClick = () => {
    handleChange(value);
  };

  return (
    <div className='inline-block cursor-pointer' onClick={handleClick}>
      <div className='flex gap-1 items-center'>
        <div className='relative flex items-center justify-center'>
          <input name={name} type='radio' value={value} checked={checked} />
          {checked && <div className='absolute w-2.5 h-2.5 bg-bg-main rounded-full'></div>}
        </div>
        {!!label && <span className='text-text-main'>{label}</span>}
      </div>
    </div>
  );
};

export default FormRadio;
