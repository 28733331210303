import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import Loader from "../../../components/Loader";
import { getViewportWidth } from "../../../utils/Viewport";
import MaintenancesPageView from "../view/MaintenancesPageView";

const MaintenancesPageState = ({ carId = null, shouldRefresh, isFiltersOpened }) => {
  const windowSize = getViewportWidth();
  const [cars, setCars] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [maintenances, setMaintenances] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [sorting, setSorting] = useState([{ columnName: "startTime", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: windowSize === "MOBILE" ? 10 : 25,
  });

  const getMaintenances = async () => {
    const Maintenances = Parse.Object.extend("Maintenance");
    const query = new Parse.Query(Maintenances);
    if (!!carId) {
      query.equalTo("car", {
        __type: "Pointer",
        className: "Car",
        objectId: carId,
      });
    }
    query.includeAll();

    try {
      if (Object.keys(filters).length > 0) {
        if (filters?.user) {
          let user = new Parse.Object("_User").set("objectId", filters.user);
          query.equalTo("user", user);
        }
        if (filters?.car) {
          let car = new Parse.Object("Car").set("objectId", filters.car);
          query.equalTo("car", car);
        }
        filters.status && query.equalTo("status", filters.status);
        filters.from && query.greaterThanOrEqualTo("startTime", filters.from);
        filters.to && query.lessThanOrEqualTo("startTime", filters.to);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      await query
        .find({ useMasterKey: true })
        .then(async (r) => {
          await Promise.all(
            r.results.map(async (m) => {
              return {
                id: m.id,
                maintenance: m,
                jobs: await new Parse.Query("MaintenanceJob")
                  .equalTo("maintenance", {
                    __type: "Pointer",
                    className: "Maintenance",
                    objectId: m.id,
                  })
                  .includeAll()
                  .find({ useMasterKey: true }),
              };
            })
          ).then((r) => {
            setMaintenances(r);
          });
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getUsers = async () => {
    try {
      const Users = await new Parse.Query("Maintenance").include("user").distinct("user");
      setUsers(
        Users.map((u) => {
          const user = new Parse.Object("_User").set("objectId", u.objectId);
          return user;
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getCars = async () => {
    try {
      const Cars = await new Parse.Query("Car").notEqualTo("inversQNR", "ARISTEST").findAll();
      setCars(Cars);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    getCars();
    getUsers();
  }, []);

  useEffect(() => {
    getMaintenances();
  }, [shouldRefresh]);

  useEffect(() => {
    if (filters?.username?.length > 0 || filters?.carPlate?.length > 0) {
      const timer = setTimeout(() => {
        getMaintenances();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getMaintenances();
    }
  }, [filters, sorting, pagination]);

  return (
    <>
      {maintenances ? (
        <MaintenancesPageView
          cars={cars}
          users={users}
          sorting={sorting}
          filters={filters}
          isLoading={isLoading}
          pagination={pagination}
          isOpened={isFiltersOpened}
          maintenances={maintenances}
          totalElements={totalElements}
          handleSorting={handleSorting}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default MaintenancesPageState;
