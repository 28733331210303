import Parse from "parse";
import "./../styles/styles.scss";
import Utils from "../../../utils/Utils";
import useCar from "../../../hooks/useCar";
import Logger from "../../../models/Logger";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import CarPageView from "../view/CarPageView";
import Renting from "../../../models/Renting";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import MaintenanceModel from "../../../models/Maintenance";
import { carSerializer } from "../../../models/serializers";

const CarPageState = () => {
  const carHook = useCar();
  const userHook = useUser();
  const toaster = useToaster();
  const { carId } = useParams();
  const [jobs, setJobs] = useState([]);
  const [totals, setTotals] = useState({});
  const session = userHook.getCurrentSession();
  const [carStatus, setCarStatus] = useState(null);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [modemStatus, setModemStatus] = useState(null);
  const [isLoadingCar, setLoadingCar] = useState(true);
  const [carSerialized, setCarSerialized] = useState();
  const [isLoadingModem, setLoadingModem] = useState(false);
  const [isLoadingTotals, setLoadingTotals] = useState(true);
  const [isLoadingInvers, setLoadingInvers] = useState(true);
  const [isLoadingUpdate, setLoadingUpdate] = useState(false);
  const [activeMaintenance, setActiveMaintenance] = useState(null);
  const [isLoadingCarReroute, setLoadingCarReroute] = useState(false);
  const [refreshMaintenances, setRefreshMaintenances] = useState(false);
  const [isLoadingMaintenance, setLoadingMaintenance] = useState(false);
  const Admin = new Parse.Object("_User").set("objectId", session.user.objectId);

  const getCar = async () => {
    setLoadingCar(true);
    const Car = Parse.Object.extend("Car");
    const query = new Parse.Query(Car);
    query.equalTo("objectId", carId);
    query.include("owner");

    try {
      return query.first().then(async (r) => {
        setCarSerialized(carSerializer(r));
        setLoadingCar(false);
        return r;
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const getTotals = async () => {
    try {
      const totalRentings = await new Parse.Query("Rentings")
        .equalTo("car", {
          __type: "Pointer",
          className: "Car",
          objectId: carId,
        })
        .includeAll()
        .findAll({
          useMasterKey: true,
        });

      const discountsCalculated = await Renting.provideTotalBill(totalRentings);

      setTotals({
        rentings: totalRentings.length,
        revenue: discountsCalculated
          .filter((r) => r.attributes.isPaid)
          .reduce((a, b) => a + (b.attributes.totalBill || 0), 0),
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const getCarStatus = async (car = null) => {
    let carS = !!car ? carSerializer(car) : carSerialized;
    setLoadingInvers(true);
    try {
      if (!Utils.isNull(carS?.inversQNR)) {
        const status = await Parse.Cloud.run("getInverseDeviceStatus", {
          inversQNR: carS?.inversQNR,
        });
        const address = await Parse.Cloud.run("getAddress", {
          latitude: status.position.lat,
          longitude: status.position.lon,
        });

        setCarStatus({ invers: status, address: address });
        return status;
      }
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    } finally {
      setLoadingInvers(false);
    }
  };

  // ------ Maintenance logic ------ //
  const getActiveMaintenance = async () => {
    new Parse.Query("Maintenance")
      .equalTo("car", {
        __type: "Pointer",
        className: "Car",
        objectId: carId,
      })
      .doesNotExist("endTime")
      .includeAll()
      .first()
      .then((r) => {
        if (!!r) {
          setActiveMaintenance(r);
        }
      });
  };

  const getJobs = async () => {
    try {
      const Jobs = await new Parse.Query("Job")
        .equalTo("deprecated", false)
        .find({ useMasterKey: true });
      setJobs(Jobs);
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleStartMaintenance = async (maintainer, maintenanceDate) => {
    setLoadingMaintenance(true);
    try {
      const Maintenance = new Parse.Object("Maintenance");
      Maintenance.set("admin", Admin);
      Maintenance.set("user", {
        __type: "Pointer",
        className: "_User",
        objectId: maintainer,
      });
      Maintenance.set("car", {
        __type: "Pointer",
        className: "Car",
        objectId: carId,
      });
      Maintenance.set("status", MaintenanceModel.STATUS.STARTED);
      Maintenance.set("startTime", maintenanceDate);
      Maintenance.save().then(async (r) => {
        let postJobs = [];
        selectedJobs.forEach((j) => {
          const MaintenanceJob = new Parse.Object("MaintenanceJob")
            .set("user", {
              __type: "Pointer",
              className: "_User",
              objectId: maintainer,
            })
            .set("job", {
              __type: "Pointer",
              className: "Job",
              objectId: j,
            })
            .set("maintenance", { __type: "Pointer", className: "Maintenance", objectId: r.id })
            .set("isDone", false);
          postJobs.push(MaintenanceJob);
        });
        await Parse.Object.saveAll(postJobs).then(() => {
          getActiveMaintenance();
          getCar();
          setRefreshMaintenances(true);
          setLoadingMaintenance(false);
          setSelectedJobs([]);
        });
      });
    } catch (e) {
      setLoadingMaintenance(false);
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleCloseMaintenance = async () => {
    setLoadingMaintenance(true);
    try {
      const Maintenance = new Parse.Object("Maintenance").set("objectId", activeMaintenance.id);
      Maintenance.set("endTime", new Date());
      Maintenance.set("status", MaintenanceModel.STATUS.FINISHED);

      await Maintenance.save(null, { useMasterKey: true }).then(async () => {
        setLoadingMaintenance(false);
        setActiveMaintenance(null);
        getCar();
        setRefreshMaintenances(true);
      });
    } catch (e) {
      setLoadingMaintenance(false);
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleChangeMaintainer = async (maintainer) => {
    setLoadingMaintenance(true);
    try {
      const Maintenance = new Parse.Object("Maintenance").set("objectId", activeMaintenance.id);
      Maintenance.set("user", {
        __type: "Pointer",
        className: "_User",
        objectId: maintainer,
      });
      await Maintenance.save(null, { useMasterKey: true }).then(async () => {
        getActiveMaintenance().then(() => {
          setLoadingMaintenance(false);
          setRefreshMaintenances(true);
        });
      });
    } catch (e) {
      setLoadingMaintenance(false);
      toaster.error(e.message);
      console.error(e.message);
    }
  };
  // ------ Maintenance logic ------ //

  const handleToggleAvailable = async (toggle) => {
    setLoadingCar(true);
    carHook.handleToggleAvailable(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleToggleRiding = async (toggle) => {
    setLoadingCar(true);
    carHook.handleToggleRiding(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleToggleUnderMaintenance = async (toggle) => {
    setLoadingCar(true);
    carHook.handleToggleUnderMaintenance(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleToggleBluetooth = async (toggle) => {
    setLoadingCar(true);
    carHook.handleToggleBluetooth(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleToggleChildSeat = async (toggle) => {
    setLoadingCar(true);
    carHook.handleToggleChildSeat(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleTogglePetFriendly = async (toggle) => {
    setLoadingCar(true);
    carHook.handleTogglePetFriendly(toggle, carId).then(() => {
      getCar();
    });
  };

  const handleRefreshStatus = async (st = null) => {
    setLoadingUpdate(true);
    const status = Utils.isNull(st) ? await getCarStatus() : st;
    const address = await Parse.Cloud.run("getAddress", {
      latitude: status.position.lat,
      longitude: status.position.lon,
    });
    setCarStatus({ invers: status, address: address });

    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("address", address);
    Car.set("latitude", status.position.lat);
    Car.set("longitude", status.position.lon);
    Car.set(
      "location",
      new Parse.GeoPoint(Number(status.position.lat), Number(status.position.lon))
    );
    try {
      await Car.save(null, { useMasterKey: true }).finally(() => {
        setLoadingUpdate(false);
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleLock = async () => {
    setLoadingInvers(true);
    setLoadingUpdate(true);
    try {
      const action = carStatus.invers.central_lock === "locked" ? "unlocked" : "locked";
      const newStatus = await Parse.Cloud.run("setInverseDeviceLockStatus2", {
        inversQNR: carSerialized.inversQNR,
        lockStatus: action,
      }).then(async () => {
        const status = action === "locked" ? "LOCK" : "UNLOCK";
        const Car = new Parse.Object("Car");
        Car.set("objectId", carId);
        await Logger.editCar(session, Car, status);
        setLoadingUpdate(false);
        setLoadingInvers(false);
      });
      await handleRefreshStatus(newStatus);
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleNotesSubmit = async (value) => {
    await carHook.handleNotesSubmit(value, carId);
  };

  const getInversModemStatus = async () => {
    setLoadingModem(true);
    await Parse.Cloud.run("getModemStatus", { inversQNR: carSerialized.inversQNR })
      .then((r) => {
        setModemStatus(r);
        setLoadingModem(false);
      })
      .catch((e) => {
        toaster.error(e.message);
        console.error(e.message);
      });
  };

  const switchInversModemNetwork = async () => {
    setLoadingModem(true);
    setModemStatus(null);
    try {
      await Parse.Cloud.run("switchMobileNetwork", {
        inversQNR: carSerialized.inversQNR,
      })
        .then((r) => {
          getInversModemStatus(carSerialized.inversQNR);
          setLoadingModem(false);
        })
        .catch((e) => {
          toaster.error(e.message);
          console.error(e.message);
        });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  useEffect(() => {
    getCar().then((r) => {
      getCarStatus(r);
      getActiveMaintenance();
      getJobs();
      getTotals().then(() => setLoadingTotals(false));
    });
  }, []);

  useEffect(() => {
    setRefreshMaintenances(false);
  }, [activeMaintenance]);

  useEffect(() => {
    setLoadingCarReroute(true);
    getCar().then(async (r) => {
      getCarStatus(r);
      getActiveMaintenance();
      getJobs();
      getTotals().then(() => setLoadingTotals(false));
      setLoadingCarReroute(false);
    });
  }, [carId]);

  return (
    <>
      {!carSerialized || isLoadingCarReroute ? (
        <Loader isLoading={isLoadingCar || isLoadingInvers || isLoadingCarReroute} />
      ) : (
        <CarPageView
          jobs={jobs}
          totals={totals}
          car={carSerialized}
          carStatus={carStatus}
          handleLock={handleLock}
          modemStatus={modemStatus}
          selectedJobs={selectedJobs}
          isLoadingModem={isLoadingModem}
          isLoadingTotals={isLoadingTotals}
          isLoadingInvers={isLoadingInvers}
          setSelectedJobs={setSelectedJobs}
          handleNotesSubmit={handleNotesSubmit}
          handleToggleRiding={handleToggleRiding}
          handleRefreshStatus={handleRefreshStatus}
          getInversModemStatus={getInversModemStatus}
          handleToggleAvailable={handleToggleAvailable}
          handleToggleBluetooth={handleToggleBluetooth}
          handleToggleChildSeat={handleToggleChildSeat}
          handleTogglePetFriendly={handleTogglePetFriendly}
          switchInversModemNetwork={switchInversModemNetwork}
          handleToggleUnderMaintenance={handleToggleUnderMaintenance}
          isLoading={isLoadingCar || isLoadingUpdate || isLoadingInvers}
          // ------ Maintenance logic ------ //
          activeMaintenance={activeMaintenance}
          refreshMaintenances={refreshMaintenances}
          isLoadingMaintenance={isLoadingMaintenance}
          handleStartMaintenance={handleStartMaintenance}
          handleCloseMaintenance={handleCloseMaintenance}
          handleChangeMaintainer={handleChangeMaintainer}
          // ------ Maintenance logic ------ //
        />
      )}
    </>
  );
};

export default CarPageState;
