import Parse from "parse";
import Utils from "../../../utils/Utils";
import useCar from "../../../hooks/useCar";
import Logger from "../../../models/Logger";
import { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import CarsPageView from "../view/CarsPageView";
import Loader from "../../../components/Loader";
import { BOOLEAN } from "../../../models/Baseline";
import {
  carSerializer,
  maintenanceSerializer,
  carProvidersSerializer,
} from "../../../models/serializers";
import dayjs from "dayjs";

const CarsPageState = () => {
  let liveQ = null;
  const carHook = useCar();
  const userHook = useUser();
  const [cars, setCars] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [errors, setErrors] = useState(null);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [carProviders, setCarProviders] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [inversDevices, setInversDevices] = useState([]);
  const [carProvidersOptions, setCarProvidersOptions] = useState([]);

  const yearOptions = Utils.getLastNYears(20).map((year) => ({
    value: year,
    label: year.toString(),
  }));
  const [pagination, setPagination] = useState({
    page: 0,
    size: 50,
  });

  const [form, setForm] = useState({
    brand: "",
    model: "",
    price: 0.2,
    city: null,
    owner: null,
    carPlate: "",
    inversQNR: "",
    kmTraveled: "",
    seatsNum: null,
    hasBluetooth: null,
    hasChildSeat: null,
    isPetFriendly: null,
    autoTransmission: false,
    manufacturingDate: null,
    isAvailable: BOOLEAN.YES.toUpperCase(),
  });

  const startLive = async () => {
    const q = new Parse.Query("Car");
    liveQ = await q.subscribe();

    liveQ.on("update", (object) => {
      setCars((prev) => {
        const newState = prev.map((c) => {
          if (c.id === object.id) return object;
          else return c;
        });
        return newState;
      });
    });
  };

  const getInversDevices = async () => {
    try {
      Parse.Cloud.run("getStoredDevices").then((r) => {
        if (r.data.length > 0) setInversDevices(r.data);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const getCarProviders = async () => {
    const CarProviders = Parse.Object.extend("CarProviders");
    const query = new Parse.Query(CarProviders);

    try {
      query
        .find()
        .then((r) => {
          setCarProviders(r);
          return r;
        })
        .then((r) => {
          setCarProvidersOptions(
            r.map((i) => {
              const provider = carProvidersSerializer(i);
              return { value: provider.id, label: provider.carProviderName };
            })
          );
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getCars = async () => {
    const Cars = Parse.Object.extend("Car");
    const query = new Parse.Query(Cars);
    if (process.env.REACT_APP_BACK4APP_ENVIRONMENT === "PROD") {
      query.notEqualTo("inversQNR", "ARISTEST");
    } else {
      query.equalTo("inversQNR", "ARISTEST");
    }
    query.include("owner");

    try {
      if (sorting.length > 0) {
        if (sorting[0]?.direction === "asc") {
          query.ascending(sorting[0].columnName);
        } else {
          query.descending(sorting[0].columnName);
        }
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query.find().then((cars) => {
        Promise.all(
          cars.results.map(async (c) => {
            return {
              car: c,
              rent: await new Parse.Query("Rentings")
                .equalTo("car", {
                  __type: "Pointer",
                  className: "Car",
                  objectId: c.id,
                })
                .descending("startTime")
                .first(),
              maintenance: await new Parse.Query("Maintenance")
                .equalTo("car", {
                  __type: "Pointer",
                  className: "Car",
                  objectId: c.id,
                })
                .greaterThanOrEqualTo("startTime", dayjs().startOf("day").toDate())
                .doesNotExist("endTime")
                .descending("startTime")
                .includeAll()
                .first({ useMasterKey: true }),
              damage: await new Parse.Query("Damage")
                .equalTo("car", {
                  __type: "Pointer",
                  className: "Car",
                  objectId: c.id,
                })
                .equalTo("isFixed", false)
                .includeAll()
                .count({ useMasterKey: true }),
            };
          })
        )
          .then((rents) => {
            return rents.flatMap((r) => {
              if (!r.rent) {
                return {
                  ...r.car,
                  attributes: r.car.attributes,
                  latestRide: null,
                  maintenance: r.maintenance,
                  damage: r.damage,
                };
              } else {
                return {
                  ...r.car,
                  attributes: r.car.attributes,
                  latestRide: r.rent.attributes.startTime,
                  maintenance: r.maintenance,
                  damage: r.damage,
                };
              }
            });
          })
          .then((data) => {
            let sorted = data
              .filter((d) => d.latestRide)
              .sort((a, b) => {
                return dayjs(b.latestRide).isAfter(dayjs(a.latestRide)) ? 1 : -1;
              });
            sorted.push(...data.filter((d) => !d.latestRide));

            setCars(sorted);
            setTotalElements(cars.count);
            setLoading(false);
          });
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleToggleAvailable = async (toggle, carId) => {
    setLoading(true);
    await carHook.handleToggleAvailable(toggle, carId).finally(() => {
      setCars(
        cars.map((c) => {
          if (c.id === carId) {
            return {
              ...c,
              attributes: { ...c.attributes, isAvailable: toggle },
            };
          } else {
            return c;
          }
        })
      );
      setLoading(false);
    });
  };

  const handleToggleBluetooth = async (toggle, carId) => {
    setLoading(true);
    await carHook.handleToggleBluetooth(toggle, carId).finally(() => {
      setCars(
        cars.map((c) => {
          if (c.id === carId) {
            return {
              ...c,
              attributes: { ...c.attributes, hasBluetooth: toggle },
            };
          } else {
            return c;
          }
        })
      );
      setLoading(false);
    });
  };

  const handleToggleChildSeat = async (toggle, carId) => {
    setLoading(true);
    await carHook.handleToggleChildSeat(toggle, carId).finally(() => {
      setCars(
        cars.map((c) => {
          if (c.id === carId) {
            return {
              ...c,
              attributes: { ...c.attributes, hasChildSeat: toggle },
            };
          } else {
            return c;
          }
        })
      );
      setLoading(false);
    });
  };

  const handleTogglePetFriendly = async (toggle, carId) => {
    setLoading(true);
    await carHook.handleTogglePetFriendly(toggle, carId).finally(() => {
      setCars(
        cars.map((c) => {
          if (c.id === carId) {
            return {
              ...c,
              attributes: { ...c.attributes, isPetFriendly: toggle },
            };
          } else {
            return c;
          }
        })
      );
      setLoading(false);
    });
  };

  const handleFormChange = (field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleExpandedRows = (rows) => {
    setExpandedRows(rows);

    cars
      .filter((c) => rows.includes(c.id))
      .map(async (c) => {
        const car = carSerializer(c);
        const status = await getCarStatus(car.inversQNR);

        setCars((prevState) => {
          const newState = prevState.map((n) => {
            if (car.id === n.id) {
              return {
                ...n,
                attributes: {
                  ...n.attributes,
                  inversStatus: status,
                },
              };
            } else {
              return n;
            }
          });
          return newState;
        });
      });
  };

  const getCarStatus = async (inversQNR) => {
    try {
      if (!Utils.isNull(inversQNR)) {
        const status = await Parse.Cloud.run("getInverseDeviceStatus", {
          inversQNR: inversQNR,
        });
        return status;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddCar = async () => {
    setErrors(null);
    setLoading(true);

    try {
      if (form.inversQNR) {
        const carStatus = await getCarStatus(form.inversQNR);

        if (carStatus?.data?.error) {
          setErrors(carStatus.data.error);
          setLoading(false);
        } else {
          const KMtraveled = !Utils.isEmpty(form.kmTraveled)
            ? Number(form.kmTraveled)
            : carStatus.mileage;

          const address = await Parse.Cloud.run("getAddress", {
            latitude: carStatus.position.lat,
            longitude: carStatus.position.lon,
          });

          const Car = new Parse.Object("Car");
          Car.set("brand", form.brand);
          Car.set("model", form.model);
          Car.set("carPlate", form.carPlate);
          Car.set("inversQNR", form.inversQNR);
          Car.set("price", Number(form.price));
          Car.set("city", form.city);
          Car.set("fuel", carStatus.fuel_level);
          Car.set("kmTraveled", KMtraveled);
          Car.set("manufacturingDate", form.manufacturingDate.toString());
          Car.set("seatsNum", Number(form.seatsNum));
          Car.set("isRiding", false);
          Car.set("isAvailable", BOOLEAN.getValue(form.isAvailable));
          Car.set("isPetFriendly", BOOLEAN.getValue(form.isPetFriendly));
          Car.set("hasBluetooth", BOOLEAN.getValue(form.hasBluetooth));
          Car.set("hasChildSeat", BOOLEAN.getValue(form.hasChildSeat));
          Car.set("autoTransmission", BOOLEAN.getValue(form.autoTransmission));
          Car.set(
            "owner",
            carProviders.find((i) => i.id === form.owner)
          );
          Car.set("address", address);
          Car.set("latitude", carStatus.position.lat);
          Car.set("longitude", carStatus.position.lon);
          Car.set(
            "location",
            new Parse.GeoPoint(Number(carStatus.position.lat), Number(carStatus.position.lon))
          );
          await Car.save().then(async () => {
            await Logger.addCar(session, Car);
            setIsAdding(!isAdding);
          });
          getCars();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    // startLive();
    getInversDevices();
    if (Utils.isEmpty(carProviders)) getCarProviders();
  }, []);

  useEffect(() => {
    getCars();
    if (expandedRows.length > 0) handleExpandedRows(expandedRows);
  }, [sorting, pagination]);

  return (
    <>
      {cars ? (
        <CarsPageView
          cars={cars}
          form={form}
          errors={errors}
          sorting={sorting}
          isAdding={isAdding}
          isLoading={isLoading}
          pagination={pagination}
          yearOptions={yearOptions}
          setIsAdding={setIsAdding}
          handleAddCar={handleAddCar}
          expandedRows={expandedRows}
          inversDevices={inversDevices}
          totalElements={totalElements}
          handleSorting={handleSorting}
          handlePageChange={handlePageChange}
          handleFormChange={handleFormChange}
          handleExpandedRows={handleExpandedRows}
          carProvidersOptions={carProvidersOptions}
          handlePageSizeChange={handlePageSizeChange}
          handleToggleAvailable={handleToggleAvailable}
          handleToggleBluetooth={handleToggleBluetooth}
          handleToggleChildSeat={handleToggleChildSeat}
          handleTogglePetFriendly={handleTogglePetFriendly}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default CarsPageState;
