const RideMetricsBlock = ({ rideTotals }) => {
  return (
    <div className='dashboard-block'>
      <div className='title'>
        <span>Rides</span>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Total time</span>
          </div>
          {rideTotals?.totalTime ? (
            <div>
              <span className='text-semibold'>{rideTotals?.totalTime}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Total distance</span>
          </div>
          {rideTotals?.totalDistance ? (
            <div>
              <span className='text-semibold'>{rideTotals?.totalDistance} km</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
      <div>
        <div className='flex justify-between mb-2'>
          <div>
            <span>Unique riders</span>
          </div>
          {rideTotals?.totalUnique ? (
            <div>
              <span className='text-semibold'>{rideTotals?.totalUnique}</span>
            </div>
          ) : (
            <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RideMetricsBlock;
