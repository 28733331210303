import { useState } from "react";
import CarKteo from "../components/CarKteo";
import { useNavigate } from "react-router-dom";
import CarLicense from "../components/CarLicense";
import CarContract from "../components/CarContract";
import CarInsurance from "../components/CarInsurance";
import { carSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";

const CarDocsPageView = ({
  car,
  kteo,
  license,
  contract,
  insurance,
  isLoading,
  markOutdated,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const carS = !!car ? carSerializer(car) : null;
  const [openTab, setTab] = useState(0);

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <div className='page-title'>
          <span className='title'>
            {carS?.carPlate} - {carS?.brand} {carS?.model} Documents
          </span>
          <div className='flex items-center gap-2'>
            <div
              className='flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer'
              onClick={() => navigate(`/cars/${carS?.id}`)}
            >
              <FontAwesomeIcon icon={faArrowRightToBracket} className='rotate-180' size='lg' />
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='tabs grid-cols-4'>
            <div
              onClick={() => setTab(0)}
              className={`tab ${openTab === 0 ? "active" : "inactive"}`}
            >
              <span>ΑΔΕΙΑ</span>
            </div>
            <div
              onClick={() => setTab(1)}
              className={`tab ${openTab === 1 ? "active" : "inactive"}`}
            >
              <span>ΣΥΜΒΟΛΑΙΟ</span>
            </div>
            <div
              onClick={() => setTab(2)}
              className={`tab ${openTab === 2 ? "active" : "inactive"}`}
            >
              <span>ΑΣΦΑΛΕΙΑ</span>
            </div>
            <div
              onClick={() => setTab(3)}
              className={`tab ${openTab === 3 ? "active" : "inactive"}`}
            >
              <span>ΚΤΕΟ</span>
            </div>
          </div>
          <div className='py-4'>
            {openTab === 0 ? (
              <CarLicense
                license={license}
                isLoading={isLoading}
                markOutdated={markOutdated}
                handleSubmit={handleSubmit}
              />
            ) : openTab === 1 ? (
              <CarContract
                contract={contract}
                isLoading={isLoading}
                markOutdated={markOutdated}
                handleSubmit={handleSubmit}
              />
            ) : openTab === 2 ? (
              <CarInsurance
                insurance={insurance}
                isLoading={isLoading}
                markOutdated={markOutdated}
                handleSubmit={handleSubmit}
              />
            ) : (
              <CarKteo
                kteo={kteo}
                isLoading={isLoading}
                markOutdated={markOutdated}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarDocsPageView;
