import User from "../../models/User";
import Authorizer from "../../components/Authorizer";
import SupportManual from "../../assets/documents/Caroo_Support_manual.pdf";

const SupportPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <div className='page-wrapper relative'>
        <div className='page-content'>
          <div className='page-title'>
            <div className='flex items-center gap-0 sm:gap-2 flex-col sm:flex-row'>
              <span className='title'>Support Manual</span>
            </div>
          </div>
          <div>
            <iframe className='w-full h-[80vh] border-0' src={SupportManual} />
          </div>
        </div>
      </div>
    </Authorizer>
  );
};

export default SupportPage;
