import Parse from "parse";
import { useCallback, useEffect, useState } from "react";
import ImageLightbox from "../../../components/ImageLightbox";
import { Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { incidentSerializer } from "../../../models/serializers";

const IncidentRowDetail = ({ row }) => {
  const incident = incidentSerializer(row);

  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [address, setAddress] = useState(null);

  const initializeSlides = useCallback(() => {
    if (Array.isArray(incident.files)) {
      incident.files.forEach((file) => {
        setSlides((prev) => [...prev, { src: file }]);
      });
    }
  });

  const handleOpen = (file) => {
    const index = slides.findIndex((item) => item.src === file);
    setIndex(index);
  };

  const resolveAddress = async () => {
    setAddress(
      await Parse.Cloud.run("getAddress", {
        latitude: incident.location.latitude,
        longitude: incident.location.longitude,
      })
    );
  };

  useEffect(() => {
    !!incident.location && resolveAddress();
    initializeSlides();
  }, []);

  return (
    <div className="p-2">
      <div>
        <span>{incident.description}</span>
      </div>
      {(!!incident.files || !!incident.location) && (
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
          {!!incident.location && (
            <div className="col-span-2 md:col-span-1">
              <div className="mb-1 text-center">
                <span className="text-base font-medium">Location</span>
              </div>
              <div className="h-80">
                <Map
                  defaultZoom={15}
                  disableDefaultUI={false}
                  gestureHandling={"greedy"}
                  mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                  defaultCenter={{
                    lat: incident.location.latitude,
                    lng: incident.location.longitude,
                  }}
                >
                  <AdvancedMarker
                    position={{
                      lat: incident.location.latitude,
                      lng: incident.location.longitude,
                    }}
                  >
                    <div className="incident-marker">
                      <div>
                        <span>{address}</span>
                      </div>
                    </div>
                  </AdvancedMarker>
                </Map>
              </div>
              <div className="flex gap-2 items-center mt-1">
                <span>
                  <b>lat:</b> {incident.location.latitude}
                </span>
                <span>
                  <b>lng:</b> {incident.location.longitude}
                </span>
              </div>
            </div>
          )}
          {!!incident.files && (
            <div className="col-span-2 md:col-span-1">
              <div className="mb-1 text-center">
                <span className="text-base font-medium">Incident Files</span>
              </div>
              <div className="grid grid-cols-4 gap-2">
                {incident.files.map((file) => {
                  return (
                    <div className="document-wrapper">
                      <div className="image-holder">
                        <img
                          src={file}
                          alt="user-id-front"
                          onClick={() => handleOpen(file)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </div>
  );
};

export default IncidentRowDetail;
