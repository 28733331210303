export const THEMES = {
  DARK: "DARK",
  LIGHT: "LIGHT",
};

export const ACTIONS = {
  setTheme: (theme) => ({
    type: theme,
  }),
};
