import { useState } from "react";
import Filters from "../components/Filters";
import DiscountsTable from "../components/DiscountsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import AddUserDiscountsModal from "../components/AddUserDiscountsModal";

const DiscountsPageView = ({
  filters,
  sorting,
  discounts,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleToggleUsed,
  isLoadingDiscount,
  handleAddDiscount,
  handleFilterChange,
  handleClearFilters,
  handlePageSizeChange,
}) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <div className='page-wrapper relative'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <>
            <div className='page-title'>
              <span className='title'>DISCOUNTS</span>
            </div>
            <Filters
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          </>
        )}
        <div className='table-wrapper'>
          <div className='external-header'>
            <div className='title-inner'>
              <span>Discounts</span>
            </div>
            <div
              className='flex items-center gap-1 text-text-main cursor-pointer'
              onClick={() => toggleModal(true)}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
              <span className='text-sm sm:text-base'>Add Discount</span>
            </div>
          </div>
          <DiscountsTable
            sorting={sorting}
            discounts={discounts}
            isLoading={isLoading}
            pagination={pagination}
            totalElements={totalElements}
            handleSorting={handleSorting}
            isExternalPage={isExternalPage}
            handlePageChange={handlePageChange}
            handleToggleUsed={handleToggleUsed}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <AddUserDiscountsModal
        users={null}
        setSelection={null}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        handleRemoveRecipient={null}
        isLoading={isLoadingDiscount}
        handleAddDiscounts={handleAddDiscount}
      />
    </div>
  );
};

export default DiscountsPageView;
