import Utils from "../../../utils/Utils";
import Toggler from "../../../components/Toggler";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdditionalInformation = ({ driver, renting, handleToggle }) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Addtional Information</span>
      </div>
      <div className='renting-details'>
        <span>Renting ID</span>
        <span>{renting.id}</span>
        <span>User ID</span>
        <span>{driver.id}</span>
        <span>Payment ID</span>
        <span>
          {Utils.isNull(renting.payment.id) ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            renting.payment.id
          )}
        </span>
        <span>Is Finished</span>
        <Toggler
          disabled={true}
          toggle={renting.isFinished}
          handleToggle={(toggle) => handleToggle("isFinished", toggle)}
        />
        <span>Is Paid</span>
        <Toggler
          disabled={true}
          toggle={renting.isPaid}
          handleToggle={(toggle) => handleToggle("isPaid", toggle)}
        />
        <span>Split Bill</span>
        <Toggler
          disabled={true}
          toggle={renting.isSplitBilling}
          handleToggle={(toggle) => handleToggle("isSplitBilling", toggle)}
        />
      </div>
    </>
  );
};

export default AdditionalInformation;
