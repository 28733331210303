import { useState } from "react";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import Comms from "../../../models/Communication";
import { isBelow } from "../../../utils/Viewport";
import { dateFormatter } from "../../../utils/Dates";
import { commSerializer } from "../../../models/serializers";
import DocumentViewer from "../../../components/DocumentViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PagingState, SortingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const UsersTable = ({
  comms,
  sorting,
  isLoading,
  pagination,
  handleEdit,
  handleDelete,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const sortableColumns = ["communicationDate", "followupDate"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveAdmin = (row) => {
    const comm = commSerializer(row);

    return (
      <div>
        <span>
          {comm.admin.firstName} {comm.admin.lastName}
        </span>
      </div>
    );
  };

  const resolveType = (row) => {
    const type = row.attributes.type;

    return <span>{Utils.underscoreToSpace(type)}</span>;
  };

  const resolvePriority = (row) => {
    const priority = row.attributes.priority;
    const color = Comms.getColor(priority);

    return (
      <div className={`inline-flex text-white px-1 rounded ${color}`}>
        <span>{priority}</span>
      </div>
    );
  };

  const resolveStatus = (row) => {
    const status = row.attributes.status;
    const color = Comms.getColor(Utils.underscoreToSpace(status));

    return (
      <div className={`inline-flex text-white px-1 rounded ${color}`}>
        <span>{Utils.underscoreToSpace(status)}</span>
      </div>
    );
  };

  const resolveTags = (row) => {
    const comm = commSerializer(row);

    return (
      <div className='flex flex-wrap items-center gap-1 text-white'>
        {comm.tags?.length > 0 &&
          comm.tags.map((t, i) => {
            return (
              <div key={i} className={`inline-flex px-1 rounded bg-gray-500`}>
                <span>{Utils.underscoreToSpace(t)}</span>
              </div>
            );
          })}
      </div>
    );
  };

  const resolveCommunicationDate = (data) => {
    return dateFormatter(data.attributes.communicationDate, true);
  };

  const resolveFollowupDate = (data) => {
    const date = data.attributes.followupDate;
    return date ? (
      dateFormatter(data.attributes.followupDate, true)
    ) : (
      <FontAwesomeIcon icon={faMinus} size='lg' />
    );
  };

  const resolveActions = (row) => {
    return (
      <div className='flex items-center justify-center gap-1'>
        <div
          className='flex items-center justify-center w-6 h-6 rounded bg-bg-card-main border border-border-input cursor-pointer'
          onClick={() => handleEdit(row)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div
          className='flex items-center justify-center w-6 h-6 rounded bg-bg-card-main border border-border-input cursor-pointer'
          onClick={() => handleDelete(row.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </div>
    );
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "admin",
          title: "Communication",
          getCellValue: resolveAdmin,
        },
      ];
    } else {
      return [
        {
          name: "admin",
          title: "Handler",
          getCellValue: resolveAdmin,
        },
        {
          name: "subject",
          title: "Subject",
          getCellValue: (data) => data.attributes.subject,
        },
        {
          name: "type",
          title: "Type",
          getCellValue: resolveType,
        },
        {
          name: "priority",
          title: "Priority",
          getCellValue: resolvePriority,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "tags",
          title: "Tags",
          getCellValue: resolveTags,
        },
        {
          name: "communicationDate",
          title: "Date",
          getCellValue: resolveCommunicationDate,
        },
        {
          name: "followupDate",
          title: "Followup Date",
          getCellValue: resolveFollowupDate,
        },
        {
          name: "actions",
          title: "",
          getCellValue: resolveActions,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    const comm = props.row;
    if (isBelow("SM")) {
      return (
        <Table.Cell {...props}>
          <div className='flex items-center flex-wrap gap-x-2 mb-1 border-b border-solid border-border-table-body pb-1'>
            <span className='font-bold'>Handler:</span> {resolveAdmin(comm)}
          </div>
          <div className='mb-1'>{resolveType(comm)}</div>
          <div className='flex items-center flex-wrap gap-x-2 gap-y-1 mb-1 border-b border-solid border-border-table-body pb-2'>
            <div>{resolvePriority(comm)}</div>
            <div>{resolveStatus(comm)}</div>
          </div>
          <div>
            <div>Date: {resolveCommunicationDate(comm)}</div>
            <div>Followup: {resolveFollowupDate(comm)}</div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  const RowDetail = ({ row }) => {
    const comm = commSerializer(row);
    const attachment = comm.attachment;

    return (
      <div className='p-2'>
        <span>{comm.content}</span>
        {attachment && (
          <div className='mt-4'>
            <DocumentViewer document={comm.attachment} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='user-messages-table'>
          <Grid rows={comms} columns={columns} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRows}
              onExpandedRowIdsChange={setExpandedRows}
            />
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              pageSize={pagination.size}
              currentPage={pagination.page}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "admin",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "subject",
                },
                {
                  width: "100",
                  wordWrapEnabled: true,
                  columnName: "type",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "admin",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "admin",
                },

                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "commDate",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "followupDate",
                },
                {
                  width: "70",
                  wordWrapEnabled: true,
                  columnName: "actions",
                  align: "center",
                },
              ]}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <PagingPanel pageSizes={[...(isExternalPage ? [3, 5] : []), 10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
