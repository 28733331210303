import "../../styles/styles.scss";
import { forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const FormInput = forwardRef(
  (
    {
      id,
      min,
      max,
      type,
      label,
      value,
      errors,
      maxLength,
      placeholder,
      handleChange,
      classes = null,
      disabled = false,
      required = false,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className='relative'>
        <div className='w-full form-control'>
          {label && <label className='pl-1 mb-1'>{label}</label>}
          <input
            id={id}
            ref={ref}
            value={value}
            min={min ?? null}
            max={max ?? null}
            disabled={disabled}
            required={required}
            maxLength={maxLength}
            onChange={handleChange}
            placeholder={placeholder}
            type={showPassword ? "text" : type}
            className={`${!!classes ? classes : ""} ${disabled ? "disabled" : ""} ${
              !!errors ? "is-invalid" : ""
            } w-full`}
          />
          {errors && (
            <div className='error'>
              <span>{errors}</span>
            </div>
          )}
        </div>
        {type === "password" && (
          <span
            className='absolute right-0 top-1/2 -translate-y-1/2 mr-2 cursor-pointer p-[2px]'
            onClick={handlePasswordVisibility}
          >
            <FontAwesomeIcon
              className='text-gray-400'
              icon={showPassword ? faEyeSlash : faEye}
              size='xl'
            />
          </span>
        )}
      </div>
    );
  }
);

export default FormInput;
