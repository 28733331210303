import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import Toggler from "../../../components/Toggler";
import { dateFormatter } from "../../../utils/Dates";
import { getViewportWidth } from "../../../utils/Viewport";
import { discountSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons";
import { SortingState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const DiscountsTable = ({
  sorting,
  discounts,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleToggleUsed,
  handlePageSizeChange,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = ["user", "amount", "type", "addedBy", "createdAt"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUsername = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.user?.id}`}>
        {data?.attributes?.user?.attributes?.username}
      </Link>
    );
  };

  const resolveIsUsed = (data) => {
    const isUsed = data.attributes?.isUsed;
    return (
      <div className='flex items-center justify-center'>
        <Toggler
          toggle={isUsed}
          handleToggle={(toggle) => handleToggleUsed(toggle, data.id)}
          disabled={isLoading}
        />
      </div>
    );
  };

  const resolveDiscount = (data) => {
    const discount = discountSerializer(data);
    return (
      <div>
        <span className={`px-1 py-0.5 rounded ${discount.isUsed ? "bg-red-500" : "bg-green-500"}`}>
          {discount.amount} {Utils.textFirstOnlyUpper(discount.type)}
        </span>
      </div>
    );
  };

  const resolveColumns = () => {
    let columns = [
      {
        name: "user",
        title: "User",
        getCellValue: resolveUsername,
      },
    ];

    switch (true) {
      case windowSize === "MOBILE":
        return columns;
      case windowSize === "SM" || windowSize === "MD":
        columns.push({
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        });
        columns.push({
          name: "createdAt",
          title: "Created At",
          getCellValue: (data) => dateFormatter(data.attributes.createdAt, true),
        });
        return columns;
      case windowSize === "LG":
        columns.push({
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        });
        columns.push({
          name: "addedBy",
          title: "Added By",
          getCellValue: (data) => data.attributes.addedBy,
        });
        columns.push({
          name: "createdAt",
          title: "Created At",
          getCellValue: (data) => dateFormatter(data.attributes.createdAt, true),
        });
        return columns;
      default:
        return [
          {
            name: "user",
            title: "User",
            getCellValue: resolveUsername,
          },
          {
            name: "amount",
            title: "Amount",
            getCellValue: (data) => data.attributes.amount,
          },
          {
            name: "type",
            title: "Type",
            getCellValue: (data) => data.attributes.type,
          },
          {
            name: "isUsed",
            title: "Is Used",
            getCellValue: resolveIsUsed,
          },
          {
            name: "addedBy",
            title: "Added By",
            getCellValue: (data) => data.attributes.addedBy,
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: (data) => dateFormatter(data.attributes.createdAt, true),
          },
        ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (windowSize === "MOBILE") {
      const discount = props.row;
      const discountSerialized = discountSerializer(discount);

      return (
        <Table.Cell {...props}>
          <div className='relative'>
            <div className='flex gap-2 items-center mb-2'>
              <div className='shrink-0'>{resolveDiscount(discount)}</div>
              <div>{resolveUsername(discount)}</div>
            </div>
            <div>Added by: {discountSerialized.addedBy}</div>
            <div>
              <FontAwesomeIcon icon={faCalendarPlus} /> {dateFormatter(discount.createdAt, true)}
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <Grid rows={discounts} columns={columns} getRowId={(row) => row.id}>
        <SortingState sorting={sorting} onSortingChange={handleSorting} />
        <PagingState
          currentPage={pagination.page}
          pageSize={pagination.size}
          onCurrentPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalElements} />
        <Table
          columnExtensions={[
            {
              columnName: "user",
              wordWrapEnabled: true,
            },
            {
              columnName: "amount",
              wordWrapEnabled: true,
              width: "100px",
              align: "center",
            },
            {
              columnName: "type",
              wordWrapEnabled: true,
              width: "140px",
              align: "center",
            },
            {
              columnName: "isUsed",
              wordWrapEnabled: true,
              width: "100px",
              align: "center",
            },
            {
              columnName: "addedBy",
              wordWrapEnabled: true,
              width: "200px",
              align: "center",
            },
            {
              columnName: "createdAt",
              wordWrapEnabled: true,
              width: "160px",
            },
            {
              columnName: "discount",
              wordWrapEnabled: true,
              width: windowSize === "MOBILE" ? "auto" : "180px",
              align: windowSize === "MOBILE" ? "left" : "center",
            },
          ]}
          cellComponent={cellComponent}
        />
        <TableHeaderRow
          sortLabelComponent={TableHeaderRowSortLabelComponent}
          showSortingControls={true}
        />
        <PagingPanel pageSizes={[...(isExternalPage ? [3, 5] : []), 10, 25, 50, 100, 1000]} />
      </Grid>
    </div>
  );
};

export default DiscountsTable;
