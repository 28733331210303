export const CITIES = {
  ATHENS: "Athens",
  THESSALONIKI: "Thessaloniki",
};

export const BOOLEAN = {
  YES: "Yes",
  NO: "No",
  getValue: (value) => {
    return value === BOOLEAN.YES.toUpperCase();
  },
};

export const GEOCORDS_OF_SERVICE = {
  ATHENS: [
    { lat: 37.965828, lng: 23.55598 },
    { lat: 37.972865, lng: 23.587566 },
    { lat: 37.967452, lng: 23.599926 },
    { lat: 37.980984, lng: 23.622585 },
    { lat: 38.003712, lng: 23.635631 },
    { lat: 38.015614, lng: 23.635631 },
    { lat: 38.022106, lng: 23.643184 },
    { lat: 38.041035, lng: 23.665844 },
    { lat: 38.054013, lng: 23.682323 },
    { lat: 38.05942, lng: 23.687816 },
    { lat: 38.096715, lng: 23.697429 },
    { lat: 38.121567, lng: 23.727642 },
    { lat: 38.145823, lng: 23.751924 },
    { lat: 38.132322, lng: 23.769777 },
    { lat: 38.10369, lng: 23.769777 },
    { lat: 38.107473, lng: 23.802049 },
    { lat: 38.094504, lng: 23.839814 },
    { lat: 38.080453, lng: 23.843934 },
    { lat: 38.070724, lng: 23.846681 },
    { lat: 38.063561, lng: 23.867109 },
    { lat: 38.049639, lng: 23.87758 },
    { lat: 38.040716, lng: 23.885476 },
    { lat: 38.010426, lng: 23.878953 },
    { lat: 38.006098, lng: 23.840844 },
    { lat: 37.990136, lng: 23.817498 },
    { lat: 37.970651, lng: 23.794839 },
    { lat: 37.964155, lng: 23.793123 },
    { lat: 37.957118, lng: 23.776986 },
    { lat: 37.935729, lng: 23.765313 },
    { lat: 37.925439, lng: 23.763597 },
    { lat: 37.910813, lng: 23.763253 },
    { lat: 37.905125, lng: 23.77321 },
    { lat: 37.892934, lng: 23.78248 },
    { lat: 37.884805, lng: 23.780076 },
    { lat: 37.879386, lng: 23.778016 },
    { lat: 37.866377, lng: 23.782136 },
    { lat: 37.849029, lng: 23.788316 },
    { lat: 37.83954, lng: 23.790376 },
    { lat: 37.833846, lng: 23.76806 },
    { lat: 37.849029, lng: 23.746087 },
    { lat: 37.849842, lng: 23.732354 },
    { lat: 37.866377, lng: 23.733384 },
    { lat: 37.890224, lng: 23.712785 },
    { lat: 37.90377, lng: 23.711068 },
    { lat: 37.921376, lng: 23.687036 },
    { lat: 37.935729, lng: 23.675706 },
    { lat: 37.928959, lng: 23.653047 },
    { lat: 37.92598, lng: 23.626611 },
    { lat: 37.940332, lng: 23.611505 },
    { lat: 37.949267, lng: 23.591592 },
    { lat: 37.956035, lng: 23.564126 },
    { lat: 37.965509, lng: 23.555887 },
  ],
};
