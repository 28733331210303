import { useState } from "react";
import "yet-another-react-lightbox/styles.css";
import DocumentWrapper from "./DocumentWrapper";
import ImageLightbox from "../../../components/ImageLightbox";
import ImageNotFound from "../../../assets/images/image_not_found.jpg";

const UserPersonalDocumentation = ({ id, license, isExpanded, handleSubmitDocument }) => {
  const [index, setIndex] = useState(-1);
  const slides = [
    { src: id.front },
    { src: id.back },
    { src: license.front },
    { src: license.back },
  ];

  const handleOpenImage = (imageUrl) => {
    let index = slides.findIndex((item) => item.src === imageUrl);
    setIndex(index);
  };

  return (
    <div>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Personal Documentation</span>
      </div>
      <div className={`grid ${isExpanded ? "grid-cols-1 md:grid-cols-2" : ""} gap-4`}>
        <div>
          <div>
            <span className='font-semibold'>ID:</span>
          </div>
          <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <DocumentWrapper
                title='Front'
                image={id.front}
                document='idFront'
                notFoundImage={ImageNotFound}
                handleOpenImage={handleOpenImage}
                handleSubmitDocument={handleSubmitDocument}
              />
            </div>
            <div className='col-span-1'>
              <DocumentWrapper
                title='Back'
                image={id.back}
                document='idBack'
                notFoundImage={ImageNotFound}
                handleOpenImage={handleOpenImage}
                handleSubmitDocument={handleSubmitDocument}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <span className='font-semibold'>License:</span>
          </div>
          <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <DocumentWrapper
                title='Front'
                image={license.front}
                document='licenseFront'
                notFoundImage={ImageNotFound}
                handleOpenImage={handleOpenImage}
                handleSubmitDocument={handleSubmitDocument}
              />
            </div>
            <div className='col-span-1'>
              <DocumentWrapper
                title='Back'
                image={license.back}
                document='licenseBack'
                notFoundImage={ImageNotFound}
                handleOpenImage={handleOpenImage}
                handleSubmitDocument={handleSubmitDocument}
              />
            </div>
          </div>
        </div>
      </div>
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </div>
  );
};

export default UserPersonalDocumentation;
