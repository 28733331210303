import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import LogsPageView from "../view/LogsPageView";
import Loader from "../../../components/Loader";
import { getViewportWidth } from "../../../utils/Viewport";

const LogsPageState = () => {
  const [logs, setLogs] = useState([]);
  const windowSize = getViewportWidth();
  const [filters, setFilters] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [distinctUsers, setDistinctUsers] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: windowSize === "MOBILE" ? 10 : 25,
  });

  const getLogs = async () => {
    const Logs = Parse.Object.extend("AdminLogger");
    const query = new Parse.Query(Logs);
    query.include("user");

    try {
      if (Object.keys(filters).length > 0) {
        filters.user &&
          query.equalTo(
            "user",
            distinctUsers.find((u) => u.attributes.username === filters.user)
          );
        filters.extraKeyword && query.contains("extra", filters.extraKeyword);
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
        filters.action && query.equalTo("action", filters.action);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find()
        .then((r) => {
          setLogs(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getUser = async (user) => {
    const U = Parse.Object.extend("_User");
    const query = new Parse.Query(U);
    query.equalTo("objectId", user.objectId);
    return await query.first();
  };

  const getDistinctUsers = async () => {
    const Logs = Parse.Object.extend("AdminLogger");
    const query = new Parse.Query(Logs);
    query
      .distinct("user")
      .then(async (r) => {
        return await Promise.all(r.map((u) => getUser(u)));
      })
      .then((r) => setDistinctUsers(r));
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    getDistinctUsers();
  }, []);

  useEffect(() => {
    if (filters?.username?.length > 0 || filters?.carPlate?.length > 0) {
      const timer = setTimeout(() => {
        getLogs();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getLogs();
    }
  }, [isLoading, filters, sorting, pagination]);

  return (
    <>
      {logs ? (
        <LogsPageView
          logs={logs}
          sorting={sorting}
          filters={filters}
          isLoading={isLoading}
          pagination={pagination}
          distinctUsers={distinctUsers}
          totalElements={totalElements}
          handleSorting={handleSorting}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default LogsPageState;
