import Filters from "../components/Filters";
import MaintenancesTable from "../components/MaintenancesTable";

const MaintenancesPageView = ({
  cars,
  users,
  filters,
  sorting,
  isOpened,
  isLoading,
  pagination,
  maintenances,
  totalElements,
  handleSorting,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        <Filters
          cars={cars}
          users={users}
          filters={filters}
          isOpened={isOpened}
          isLoading={isLoading}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
        <div className='table-wrapper'>
          <MaintenancesTable
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            maintenances={maintenances}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenancesPageView;
