import { useState } from "react";
import DamageCard from "./DamageCard";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import Toggler from "../../../components/Toggler";
import { dateFormatter } from "../../../utils/Dates";
import { getViewportWidth } from "../../../utils/Viewport";
import ImageLightbox from "../../../components/ImageLightbox";
import { damageSerializer } from "../../../models/serializers";
import { SortingState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const CarsTable = ({
  damages,
  sorting,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handleToggleFixed,
  handlePageSizeChange,
}) => {
  let slides = [];
  const windowSize = getViewportWidth();
  const [index, setIndex] = useState(-1);
  const sortableColumns = ["isFixed", "createdAt"];

  damages.map((d) => {
    if (!Utils.isNull(d.frontDamage)) {
      slides.push({ src: d.frontDamage });
    }
    if (!Utils.isNull(d.backDamage)) {
      slides.push({ src: d.backDamage });
    }
    if (!Utils.isNull(d.leftDamage)) {
      slides.push({ src: d.leftDamage });
    }
    if (!Utils.isNull(d.rightDamage)) {
      slides.push({ src: d.rightDamage });
    }
    if (!Utils.isNull(d.otherDamage)) {
      slides.push({ src: d.otherDamage });
    }
  });

  const handleOpen = (url) => {
    slides.find((i, n) => {
      if (i.src === url) {
        setIndex(n);
      }
    });
  };

  const handleChange = (prop, type) => {
    slides = [];
    if (type === "sort") {
      handleSorting(prop);
    } else if (type === "page") {
      handlePageChange(prop);
    } else if (type === "size") {
      handlePageSizeChange(prop);
    }
  };

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveIsFixed = (data) => {
    const isFixed = data.attributes.isFixed;
    return (
      <div className='flex items-center justify-center'>
        <Toggler
          toggle={isFixed}
          disabled={isLoading}
          handleToggle={(toggle) => handleToggleFixed(toggle, data.id)}
        />
      </div>
    );
  };

  const resolveDamages = (data) => {
    return (
      <div className='px-2 lg:px-0 xl:px-4'>
        <div className='grid grid-cols-5 gap-2 lg:gap-1 xl:gap-4'>
          <DamageCard side={"Front"} imgURL={data.frontDamage} handleOpen={handleOpen} />
          <DamageCard side={"Back"} imgURL={data.backDamage} handleOpen={handleOpen} />
          <DamageCard side={"Left"} imgURL={data.leftDamage} handleOpen={handleOpen} />
          <DamageCard side={"Right"} imgURL={data.rightDamage} handleOpen={handleOpen} />
          <DamageCard side={"Other"} imgURL={data.otherDamage} handleOpen={handleOpen} />
        </div>
        {data.description && (
          <div className='text-start'>
            <span className='underline font-semibold'>Description</span>
            <span className='font-semibold'>:</span>
            <span className='ml-1' style={{ textWrap: "wrap" }}>
              {data.description}
            </span>
          </div>
        )}
      </div>
    );
  };

  const resolveCreatedAt = (data) => {
    return dateFormatter(data.attributes.createdAt, true);
  };

  const resolveColumns = () => {
    switch (true) {
      case windowSize === "MOBILE" || windowSize === "SM" || windowSize === "MD":
        return [
          {
            name: "mobile",
            title: "Damages",
            getCellValue: (data) => resolveDamages(data),
          },
        ];
      default:
        return [
          {
            name: "isFixed",
            title: "Fixed",
            getCellValue: resolveIsFixed,
          },
          {
            name: "username",
            title: "Username",
            getCellValue: (data) => (
              <Link className='link font-bold' to={`/users/${data.attributes.user.id}`}>
                {data.attributes.user.attributes.username}
              </Link>
            ),
          },
          {
            name: "carPlate",
            title: "Car Plate",
            getCellValue: (data) => (
              <Link className='link font-bold' to={`/cars/${data.attributes.car.id}`}>
                {data.attributes.car.attributes.carPlate}
              </Link>
            ),
          },
          {
            name: "damages",
            title: "Damages",
            getCellValue: (data) => resolveDamages(data),
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: resolveCreatedAt,
          },
        ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    const damage = damageSerializer(props.row);
    if (windowSize === "MOBILE" || windowSize === "SM" || windowSize === "MD") {
      return (
        <Table.Cell {...props}>
          <div>
            <div className='flex flex-wrap items-center gap-2 mb-4'>
              <Link className='link' to={`/cars/${damage.car.id}`}>
                <span className='font-medium'>{damage.car.carPlate}</span>
              </Link>
              <Link className='link' to={`/users/${damage.user.id}`}>
                <span className='font-medium'>{damage.user.username}</span>
              </Link>
              <span>{damage.createdAt}</span>
            </div>
            <div className='text-center'>{resolveDamages(props.row)}</div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <Grid rows={damages} columns={columns} getRowId={(row) => row.id}>
          <SortingState
            columnExtensions={[
              { columnName: "mobile", sortingEnabled: true },
              { columnName: "isFixed", sortingEnabled: true },
              { columnName: "username", sortingEnabled: false },
              { columnName: "carPlate", sortingEnabled: false },
              { columnName: "damages", sortingEnabled: false },
              { columnName: "createdAt", sortingEnabled: true },
            ]}
            sorting={sorting}
            onSortingChange={(sort) => handleChange(sort, "sort")}
          />
          <PagingState
            currentPage={pagination.page}
            pageSize={pagination.size}
            onCurrentPageChange={(page) => handleChange(page, "page")}
            onPageSizeChange={(size) => handleChange(size, "size")}
          />
          <CustomPaging totalCount={totalElements} />
          <Table
            columnExtensions={[
              {
                columnName: "mobile",
                wordWrapEnabled: true,
                width: "auto",
                align: "left",
              },
              {
                columnName: "isFixed",
                wordWrapEnabled: false,
                width: windowSize === "LG" ? "70" : "100",
                align: windowSize === "LG" ? "left" : "center",
              },
              { columnName: "username", wordWrapEnabled: true, width: "auto" },
              {
                columnName: "carPlate",
                wordWrapEnabled: true,
                width: "80",
                align: "left",
              },
              {
                columnName: "damages",
                wordWrapEnabled: false,
                width: windowSize === "LG" ? "45%" : "50%",
                align: "center",
              },
              {
                columnName: "createdAt",
                wordWrapEnabled: true,
                width: windowSize === "LG" ? "140" : "200",
                align: "center",
              },
            ]}
            cellComponent={cellComponent}
          />
          <TableHeaderRow
            sortLabelComponent={TableHeaderRowSortLabelComponent}
            showSortingControls={true}
          />
          <PagingPanel pageSizes={[10, 25, 50, 100]} />
        </Grid>
      </div>
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </div>
  );
};

export default CarsTable;
