import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Button = ({
  classes,
  children,
  isLoading,
  handleClick,
  success = false,
  disabled = false,
}) => {
  return (
    <button
      type='button'
      className={`${
        isLoading || disabled ? "disabled bg-opacity-80 cursor-not-allowed" : "cursor-pointer"
      } relative hover:bg-opacity-80 rounded transition-all ease-in-out duration-200 ${classes}`}
      onClick={disabled ? null : handleClick}
      disabled={isLoading || disabled}
    >
      {children}
      {(isLoading || success) && (
        <div className='absolute top-1/2 transform -translate-y-1/2 right-2'>
          {success ? (
            <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />
          ) : (
            <FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-gray-100 ml-1' />
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
