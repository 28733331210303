import { THEMES } from "../actions/theme";

// initial state
const initialState = {
  theme: JSON.parse(localStorage.getItem("user-preferences"))?.["theme"]
    ? JSON.parse(localStorage.getItem("user-preferences"))["theme"]
    : localStorage.getItem("user-preferences", { theme: "LIGHT" }),
};

const theme = (state = initialState, action) => {
  switch (action.type) {
    case THEMES.DARK:
      return THEMES.DARK;
    case THEMES.LIGHT:
      return THEMES.LIGHT;
    default:
      return state;
  }
};

export default theme;
