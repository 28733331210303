import Parse from "parse";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormRadio from "../../../components/form/FormRadio";
import FormInput from "../../../components/form/FormInput";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormSelect from "../../../components/form/FormSelect";
import FormTextarea from "../../../components/form/FormTextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userMessageTemplateSerializer, userSerializer } from "../../../models/serializers";

const SendUserMessagesModal = ({
  users,
  isOpen,
  isLoading,
  toggleModal,
  setSelection,
  handleSendMessages,
  handleRemoveRecipient,
}) => {
  const [templates, setTemplates] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [submitMsgesSuccess, setSubmitMsgesSucces] = useState(false);
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState("EL");
  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });
  const [form, setForm] = useState({
    type: "INFO",
    title: "",
    message: "",
  });
  const userMessageTypeOptions = Object.keys(User.MESSAGE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));

  const getUserData = (userId) => {
    return userSerializer(new Parse.Object("_User").set("objectId", userId));
  };

  const getMessagesTemplates = async () => {
    const MessageTemplates = await new Parse.Query("UserMessageTemplates").findAll({
      useMasterKey: true,
    });
    const serialized = MessageTemplates.map((t) => userMessageTemplateSerializer(t));
    setTemplates(serialized);
  };

  const setTemplate = async (template) => {
    setSelectedTemplate(template);
  };

  const templateLanguageChange = (value) => {
    setSelectedTemplateLanguage(value);
  };

  const handleSubmit = async () => {
    await handleSendMessages(users, form).then((r) => {
      if (r) {
        setSubmitMsgesSucces(true);
        setTimeout(() => {
          setForm({
            type: "INFO",
            title: "",
            message: "",
          });
          toggleModal(false);
          setSelection && setSelection([]);
          setSubmitMsgesSucces(false);
        }, 1500);
      } else {
        setSubmitMsgesSucces(false);
        setResponseState({
          type: false,
          message: "Something went wrong. Try again.",
        });
      }
    });
  };

  const handleCancel = () => {
    toggleModal(false);
    setForm({
      type: "INFO",
      title: "",
      message: "",
    });
    setSubmitMsgesSucces(false);
    setResponseState({ type: null, message: null });
  };

  useEffect(() => {
    getMessagesTemplates();
  }, []);

  useEffect(() => {
    const tmpl = templates?.find((t) => t.id === selectedTemplate?.value);

    if (selectedTemplateLanguage === "EN") {
      setTemplateOptions(templates.map((t) => ({ value: t.id, label: t.en.title })));
      setForm({ type: tmpl?.type, title: tmpl?.en.title, message: tmpl?.en.message });
    } else {
      setTemplateOptions(templates.map((t) => ({ value: t.id, label: t.el.title })));
      setForm({ type: tmpl?.type, title: tmpl?.el.title, message: tmpl?.el.message });
    }
  }, [templates, selectedTemplate, selectedTemplateLanguage]);

  return (
    <Modal
      open={isOpen}
      title='Send user messages'
      classes={"w-4/5 md:w-1/2 xl:w-1/3"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div>
        {!!users && (
          <div className='mb-4'>
            <div className='mb-1'>
              <span className='text-text-main'>Recepients:</span>
            </div>
            <div className='flex flex-wrap items-center gap-1 mb-1 p-1 max-h-20 overflow-x-hidden overflow-y-scroll bg-bg-input border border-solid border-border-input rounded'>
              {users.map((id) => {
                const user = getUserData(id);
                return (
                  <div
                    key={id}
                    className='flex items-center rounded-full bg-gray-100 bg-opacity-70 px-2 py-1 transition-all ease-in-out duration-200 hover:bg-blue-100 hover:bg-opacity-50'
                  >
                    <span className='text-sm'>{user.username}</span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={`ml-1 w-[10px] h-[10px] ${
                        handleRemoveRecipient ? "cursor-pointer" : "cursor-not-allowed"
                      } p-1 rounded-full hover:bg-white`}
                      onClick={() => (handleRemoveRecipient ? handleRemoveRecipient(id) : null)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className='mb-1 relative'>
          <FormSelect
            label='Template'
            options={templateOptions}
            handleChange={setTemplate}
            value={
              !Utils.isNull(selectedTemplate)
                ? findOption(templateOptions, selectedTemplate.value)
                : null
            }
          />
          <div className='absolute left-20 top-0 flex items-center gap-2'>
            <FormRadio
              label='EL'
              value='EL'
              name='language'
              handleChange={templateLanguageChange}
              checked={selectedTemplateLanguage === "EL"}
            />
            <FormRadio
              label='EN'
              value='EN'
              name='language'
              handleChange={templateLanguageChange}
              checked={selectedTemplateLanguage === "EN"}
            />
          </div>
        </div>
        <div className='mb-2'>
          <FormSelect
            label='Type'
            options={userMessageTypeOptions}
            handleChange={(value) => setForm((prev) => ({ ...prev, type: value.value }))}
            value={!Utils.isNull(form.type) ? findOption(userMessageTypeOptions, form.type) : null}
          />
        </div>
        <div className='mb-2'>
          <FormInput
            type='text'
            label='Title'
            value={form.title}
            placeholder='Title'
            handleChange={(e) => setForm((prev) => ({ ...prev, title: e.target.value }))}
          />
        </div>
        <div className='mb-2'>
          <FormTextarea
            rows={4}
            label='Message'
            value={form.message}
            placeholder='Type the message to be sent to the users here..'
            handleChange={(value) => setForm((prev) => ({ ...prev, message: value }))}
          />
        </div>
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2' disabled={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            classes={`bg-blue-500 py-2`}
            success={submitMsgesSuccess}
            disabled={isLoading || submitMsgesSuccess}
          >
            <span className='text-white'>Send</span>
          </Button>
        </div>
        {!Utils.isNull(responseState.type) && (
          <div className='mt-1'>
            <span
              className={`text-sm font-bold ${
                responseState.type ? "text-green-400" : "text-red-400"
              }`}
            >
              {responseState.message}
            </span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SendUserMessagesModal;
