import Parse from "parse";
import "../styles/styles.scss";
import { useState, useEffect } from "react";
import { metersToKM } from "../../../utils/Units";
import DashboardPageView from "../view/DashboardPageView";
import { secondsToDaysHoursMinutes } from "../../../utils/Dates";

const DashboardPageState = () => {
  const [userTotals, setUserTotals] = useState(null);
  const [rideTotals, setRideTotals] = useState(null);
  const [rentingTotals, setRentingTotals] = useState(null);
  const [revenueTotals, setRevenueTotals] = useState(null);

  const getUserTotals = async () => {
    try {
      const totalUsers = await new Parse.Query("_User").count(null, {
        useMasterKey: true,
      });
      const totalPending = await new Parse.Query("_User")
        .equalTo("isAuthorized", false)
        .equalTo("isRejected", false)
        .count(null, {
          useMasterKey: true,
        });
      const totalAuthorized = await new Parse.Query("_User")
        .equalTo("isAuthorized", true)
        .equalTo("isRejected", false)
        .count(null, {
          useMasterKey: true,
        });
      const totalUnauthorized = await new Parse.Query("_User")
        .equalTo("isAuthorized", false)
        .equalTo("isRejected", true)
        .count(null, {
          useMasterKey: true,
        });
      const totalRejected = await new Parse.Query("_User")
        .equalTo("isAuthorized", true)
        .equalTo("isRejected", true)
        .count(null, {
          useMasterKey: true,
        });
      const totalLocked = await new Parse.Query("_User").equalTo("isLocked", true).count(null, {
        useMasterKey: true,
      });
      const totalReferred = await new Parse.Query("_User").exists("referredBy").count(null, {
        useMasterKey: true,
      });
      setUserTotals({
        totalUsers: totalUsers,
        totalPending: totalPending,
        totalAuthorized: totalAuthorized,
        totalUnauthorized: totalUnauthorized,
        totalRejected: totalRejected,
        totalLocked: totalLocked,
        totalReferred: totalReferred,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRentingTotals = async () => {
    try {
      const totalRentings = await new Parse.Query("Rentings").count(null, {
        useMasterKey: true,
      });
      const totalActive = await new Parse.Query("Rentings").doesNotExist("endTime").count(null, {
        useMasterKey: true,
      });
      const totalUnpaid = await new Parse.Query("Rentings").equalTo("isPaid", false).count(null, {
        useMasterKey: true,
      });
      const totalNoPhotos = await new Parse.Query("Rentings").doesNotExist("carFront").count(null, {
        useMasterKey: true,
      });
      const totalPhotos = await new Parse.Query("Rentings").equalTo("status", 1).count(null, {
        useMasterKey: true,
      });
      const totalRatings = await new Parse.Query("Rentings").equalTo("status", 2).count(null, {
        useMasterKey: true,
      });
      const totalSplitBill = await new Parse.Query("Rentings")
        .equalTo("isSplitBilling", true)
        .count(null, {
          useMasterKey: true,
        });
      setRentingTotals({
        totalRentings: totalRentings,
        totalActive: totalActive,
        totalUnpaid: totalUnpaid,
        totalNoPhotos: totalNoPhotos,
        totalPhotos: totalPhotos,
        totalRatings: totalRatings,
        totalSplitBill: totalSplitBill,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRideTotals = async () => {
    try {
      const totalTimeInSeconds = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$totalTime" },
          },
        })
      )[0].total;
      const totalDistance = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$distance" },
          },
        })
      )[0].total;
      const totalUnique = await new Parse.Query("Rentings").distinct("driver");

      setRideTotals({
        totalTime: secondsToDaysHoursMinutes(totalTimeInSeconds),
        totalDistance: metersToKM(totalDistance),
        totalUnique: totalUnique.length,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRevenueTotals = async () => {
    try {
      const totalBill = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;
      const totalUnpaid = (
        await new Parse.Query("Rentings").aggregate({
          match: { isPaid: false },
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;
      const totalPaid = (
        await new Parse.Query("Rentings").aggregate({
          match: { isPaid: true },
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;

      setRevenueTotals({
        totalBill: parseFloat(totalBill).toFixed(2),
        totalUnpaid: parseFloat(totalUnpaid).toFixed(2),
        totalPaid: parseFloat(totalPaid).toFixed(2),
      });

      // setTotalDamages(
      //   await new Parse.Query("Damage").count(null, { useMasterKey: true })
      // );
      // setTotalMaintenances(
      //   await new Parse.Query("Maintenance").count(null, { useMasterKey: true })
      // );
      // setTotalRevenue(
      //   (
      //     await new Parse.Query("Rentings").aggregate({
      //       group: {
      //         objectId: null,
      //         total: { $sum: "$bill" },
      //       },
      //     })
      //   )[0].total
      // );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUserTotals();
    getRentingTotals();
    getRideTotals();
    getRevenueTotals();
  }, []);

  return (
    <DashboardPageView
      userTotals={userTotals}
      rentingTotals={rentingTotals}
      rideTotals={rideTotals}
      revenueTotals={revenueTotals}
      // totalRevenue={totalRevenue}
    />
  );
};

export default DashboardPageState;
