import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import Loader from "../../../components/Loader";
import IncidentsPageView from "../view/IncidentsPageView";
import { getViewportWidth } from "../../../utils/Viewport";

const IncidentsPageState = ({ rentingId = null, hasFilters = true }) => {
  const windowSize = getViewportWidth();
  const [filters, setFilters] = useState({});
  const [incidents, setIncidents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [sorting, setSorting] = useState([{ columnName: "date", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: windowSize === "MOBILE" ? 10 : 25,
  });

  const getIncidents = async () => {
    const Incident = Parse.Object.extend("Incident");
    const query = new Parse.Query(Incident);
    query.includeAll();

    if (!!rentingId) {
      const rentingPointer = {
        __type: "Pointer",
        className: "Rentings",
        objectId: rentingId,
      };
      query.equalTo("renting", rentingPointer);
    }

    try {
      if (Object.keys(filters).length > 0) {
        filters.carDamage && query.equalTo("carDamage", filters.carDamage);
        filters.injury && query.equalTo("injury", filters.injury);
        filters.from && query.greaterThanOrEqualTo("date", filters.from);
        filters.to && query.lessThanOrEqualTo("date", filters.to);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then((r) => {
          setIncidents(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  useEffect(() => {
    if (filters.phone?.length > 0 || filters.username?.length > 0 || filters.lastName?.length > 0) {
      const timer = setTimeout(() => {
        getIncidents();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getIncidents();
    }
  }, [filters, sorting, pagination]);

  useEffect(() => {
    getIncidents();
  }, []);

  return (
    <>
      {incidents ? (
        <IncidentsPageView
          filters={filters}
          sorting={sorting}
          incidents={incidents}
          isLoading={isLoading}
          hasFilters={hasFilters}
          pagination={pagination}
          totalElements={totalElements}
          handleSorting={handleSorting}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default IncidentsPageState;
