import DocumentWrapper from "../../users/components/DocumentWrapper";
import ImageNotFound from "../../../assets/images/image_not_found.jpg";

const CarPhotos = ({ renting, handleOpenImage, handleSubmitDocument }) => {
  return (
    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
      <div className='col-span-1'>
        <DocumentWrapper
          title='Front'
          image={renting.carPhotos.front ?? null}
          document='carFront'
          notFoundImage={ImageNotFound}
          handleOpenImage={handleOpenImage}
          handleSubmitDocument={handleSubmitDocument}
        />
      </div>
      <div className='col-span-1'>
        <DocumentWrapper
          title='Back'
          image={renting.carPhotos.back ?? null}
          document='carBack'
          notFoundImage={ImageNotFound}
          handleOpenImage={handleOpenImage}
          handleSubmitDocument={handleSubmitDocument}
        />
      </div>
      <div className='col-span-1'>
        <DocumentWrapper
          title='Left'
          image={renting.carPhotos.left ?? null}
          document='carLeft'
          notFoundImage={ImageNotFound}
          handleOpenImage={handleOpenImage}
          handleSubmitDocument={handleSubmitDocument}
        />
      </div>
      <div className='col-span-1'>
        <DocumentWrapper
          title='Right'
          image={renting.carPhotos.right ?? null}
          document='carRight'
          notFoundImage={ImageNotFound}
          handleOpenImage={handleOpenImage}
          handleSubmitDocument={handleSubmitDocument}
        />
      </div>
    </div>
  );
};

export default CarPhotos;
