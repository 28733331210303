import dayjs from "dayjs";
import Utils from "../../../utils/Utils";
import { findOption } from "../../../utils/Forms";
import Maintenance from "../../../models/Maintenance";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({
  cars,
  users,
  filters,
  isOpened = false,
  handleFilterChange,
  handleClearFilters,
}) => {
  const userOptions = users.map((u) => ({
    value: u.id,
    label: u.attributes.name + " " + u.attributes.lastName,
  }));
  const userDefault = !Utils.isNull(filters?.user) ? findOption(userOptions, filters?.user) : null;

  const carOptions = cars.map((c) => ({
    value: c.id,
    label: c.attributes.carPlate + " - " + c.attributes.brand + " " + c.attributes.model,
  }));
  const carDefault = !Utils.isNull(filters?.car) ? findOption(carOptions, filters?.car) : null;

  const statusOptions = Object.keys(Maintenance.STATUS).map((s) => ({
    value: s,
    label: Utils.textFirstOnlyUpper(s),
  }));
  const statusDefault = !Utils.isNull(filters?.status)
    ? findOption(statusOptions, String(filters?.status)?.toUpperCase())
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={isOpened}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <span>
                <FormSelect
                  label='User'
                  classes='w-full'
                  isClearable={true}
                  options={userOptions}
                  value={userDefault}
                  placeholder='Select User'
                  handleChange={(option) => handleFilterChange("user", option)}
                />
              </span>
            </div>
            <div>
              <span>
                <FormSelect
                  label='Car'
                  classes='w-full'
                  isClearable={true}
                  options={carOptions}
                  value={carDefault}
                  placeholder='Select Car'
                  handleChange={(option) => handleFilterChange("car", option)}
                />
              </span>
            </div>
            <div>
              <FormSelect
                label='Status'
                classes='w-full'
                isClearable={true}
                options={statusOptions}
                value={statusDefault}
                placeholder='Status'
                handleChange={(option) => handleFilterChange("status", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
