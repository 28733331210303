import "../styles/styles.scss";
import MUIModal from "@mui/material/Modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({ children, classes, title, open, handleToggle }) => {
  return (
    <MUIModal
      open={open}
      disablePortal
      onClose={() => handleToggle(!open)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className={`p-2 sm:p-4 absolute max-h-[85vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-bg-modal rounded shadow-lg ${classes}`}
      >
        <div className='relative text-center mb-4'>
          <span className='text-lg font-semibold text-text-main'>{title}</span>
          <FontAwesomeIcon
            size='lg'
            icon={faTimes}
            onClick={() => handleToggle(false)}
            className='absolute right-0 cursor-pointer text-text-main'
          />
        </div>
        <div className='bg-bg-input p-4 rounded'>{children}</div>
      </div>
    </MUIModal>
  );
};

export default Modal;
