import "dayjs/locale/el";
import Parse from "parse";
import "./styles/global.css";
import "./styles/styles.scss";
import Root from "./pages/Root";
import store from "./store/store";
import { Provider } from "react-redux";
import { MuiTheme } from "./assets/MuiTheme";
import { ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

Parse.setAsyncStorage(AsyncStorage);
Parse.initialize(
  process.env.REACT_APP_BACK4APP_APPLICATION_KEY,
  process.env.REACT_APP_BACK4APP_JS_KEY
);
Parse.masterKey = process.env.REACT_APP_BACK4APP_MASTER_KEY;
Parse.serverURL = process.env.REACT_APP_BACK4APP_SERVER_URL;
Parse.liveQueryServerURL = process.env.REACT_APP_BACK4APP_LIVE_QUERY_SERVER_URL;

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='el'>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
            <ThemeProvider theme={MuiTheme}>
              <ToastContainer />
              <Root />
            </ThemeProvider>
          </APIProvider>
          <Analytics />
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
