import Parse from "parse";
import dayjs from "dayjs";
import Utils from "../utils/Utils";
import Logger from "../models/Logger";
import useToaster from "./useToaster";
import UserModel from "../models/User";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userMessageSerializer } from "../models/serializers";
import { decryptMessage, encryptMessage } from "../utils/Crypto";
import { ACTIONS as SESSION_ACTIONS } from "../store/actions/session";

const useUser = () => {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state) =>
    state?.session?.session ? state?.session?.session : state?.session ? state?.session : null
  );

  const getCurrentSession = () => {
    return session;
  };

  const getUserRoles = () => {
    if (Utils.isDefined(session?.roles)) return session.roles;
    else return [];
  };

  const login = async (username, password) => {
    try {
      const session = await Parse.Cloud.run("loginWithRoles", {
        email: username,
        password: password,
      });

      if (!session.roles.includes("Admin") && !session.roles.includes("Viewer")) {
        return false;
      } else {
        await Parse.User.logIn(username, password);
        let uS = JSON.stringify(session);
        uS = JSON.parse(uS);
        dispatch(SESSION_ACTIONS.userLogin(uS));
        await Logger.login(session);
        return true;
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const logout = async () => {
    try {
      if (!Utils.isNull(session.user))
        await Logger.logout(session).finally(async () => {
          await Parse.User.logOut();
          dispatch(SESSION_ACTIONS.userLogout());
          navigate("/login");
        });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleUserAuthorization = async (action, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    User.unset("selfie");

    if (action === UserModel.ACTIONS.AUTHORIZE) {
      User.set("isRejected", false);
      User.set("isAuthorized", true);
    } else if (action === UserModel.ACTIONS.REJECT) {
      User.set("isRejected", true);
      User.set("isAuthorized", false);
    } else if (action === UserModel.ACTIONS.REAUTHORIZE) {
      User.set("isRejected", true);
      User.set("isAuthorized", true);
    }

    try {
      await User.save(null, { useMasterKey: true }).then(async () => {
        await Logger.authorizeUser(session, User, action);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleToggleEnabled = async (toggle, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    User.set("isEnabled", toggle);
    try {
      await User.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editUser(session, User, toggle ? "ENABLE" : "DISABLE");
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleToggleLocked = async (toggle, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    User.set("isLocked", toggle);
    try {
      await User.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editUser(session, User, toggle ? "LOCK" : "UNLOCK");
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleToggleEmailVerified = async (toggle, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    User.set("emailVerified", toggle);
    try {
      await User.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editUser(session, User, toggle ? "VERIFY EMAIL" : "UNVERIFY EMAIL");
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleTogglePhoneVerified = async (toggle, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    User.set("phoneVerified", toggle);
    try {
      await User.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editUser(session, User, toggle ? "VERIFY PHONE" : "UNVERIFY PHONE");
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleNotesSubmit = async (value, userId) => {
    const User = new Parse.Object("_User");
    User.set("objectId", userId);
    !!value ? User.set("notes", value) : User.unset("notes");
    try {
      await User.save(null, { useMasterKey: true });
      await Logger.addUserNotes(session, User);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSendMessages = async (users, form) => {
    try {
      const sender = new Parse.Object("_User").set("objectId", session.user.objectId);
      let messages = [];
      users.forEach((user) => {
        const recipient = new Parse.Object("_User").set("objectId", user);

        const ACL = new Parse.ACL();
        ACL.setReadAccess(user, true);
        ACL.setWriteAccess(user, true);

        messages.push(
          new Parse.Object("UserMessage")
            .set("user", recipient)
            .set("sentBy", sender)
            .set("type", form.type)
            .set("title", form.title)
            .set("message", encryptMessage(form.message))
            .set("seen", false)
            .setACL(ACL)
        );
      });

      return await Parse.Object.saveAll(messages)
        .then((deliveredMessages) => {
          deliveredMessages.map(async (userMessage) => {
            const ums = userMessageSerializer(userMessage);

            await Parse.Cloud.run("userNotification", {
              userId: ums.reciever.id,
              title: ums.title,
              message: decryptMessage(ums.message),
            });
          });
        })
        .then(() => {
          Promise.all(
            messages.map(async (m) => {
              await Logger.sendMessage(session, m);
            })
          );
        });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleAddDiscounts = async (users, form) => {
    try {
      let discounts = [];
      users.forEach((user) => {
        const addTo = new Parse.Object("_User").set("objectId", user);

        const ACL = new Parse.ACL();
        ACL.setReadAccess(user, true);
        ACL.setWriteAccess(user, true);

        discounts.push(
          new Parse.Object("Discount")
            .set("user", addTo)
            .set("addedBy", session.user.username)
            .set("type", form.type)
            .set("amount", Number(form.amount))
            .set("isUsed", false)
        );
      });

      return await Parse.Object.saveAll(discounts, { useMasterKey: true }).then(() => {
        Promise.all(
          discounts.map(async (d) => {
            await Logger.addDiscount(session, d);
          })
        );
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleAddComm = async (user, form, commId) => {
    try {
      const userPointer = new Parse.Object("_User").set("objectId", user);
      const adminPointer = new Parse.Object("_User").set("objectId", session.user.objectId);

      const Comm = new Parse.Object("CommunicationHistory");
      commId && Comm.set("objectId", commId);
      Comm.set("user", userPointer);
      Comm.set("admin", adminPointer);
      Comm.set("type", form.type);
      Comm.set("priority", form.priority);
      Comm.set("status", form.status);
      Comm.set("subject", form.subject);
      Comm.set("communicationDate", dayjs().toDate());
      form.tags.length > 0 && Comm.set("tags", form.tags);
      form.content && Comm.set("content", form.content);
      form.followup && Comm.set("followupDate", dayjs(form.followup).toDate());

      if (form.attachment) {
        let fileBase64 = null;
        let fileReader = new FileReader();

        return new Promise((resolve, reject) => {
          fileReader.onerror = () => {
            fileReader.abort();
            reject(console.error("Problem parsing input file."));
          };

          fileReader.onload = async (fileLoadedEvent) => {
            fileBase64 = fileLoadedEvent.target.result;
            const data = fileBase64.split(",")[1];
            const parseFile = new Parse.File(form.attachment.name, { base64: data });

            Comm.set("attachment", parseFile);
            return await Comm.save(null, { useMasterKey: true }).then(async () => {
              await Logger.addUserComm(session, Comm, !!commId);
              resolve("ok");
            });
          };

          fileReader.readAsDataURL(form.attachment);
        });
      } else {
        await Logger.addUserComm(session, Comm, !!commId);
        return await Comm.save(null, { useMasterKey: true });
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleDeleteStripeCustomer = async (user) => {
    try {
      const result = await Parse.Cloud.run("deleteCustomer", { customerId: user.customerId });
      // If result.deleted is undefined, then that means that the customer ID does not exist on stripe, therefore there is no customer in stripe
      // so we return true as no customer was found
      if (!!result.deleted) return true;
      if (result.deleted) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
      return false;
    }
  };

  const handleDelete = async (user) => {
    try {
      const User = new Parse.Object("_User").set("objectId", user.id);
      await User.destroy({ useMasterKey: true });
      await Logger.deleteUser(session, User);
      toaster.success("User deleted successfully.");
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  return {
    login,
    logout,
    getUserRoles,
    handleDelete,
    handleAddComm,
    handleNotesSubmit,
    getCurrentSession,
    handleToggleLocked,
    handleSendMessages,
    handleAddDiscounts,
    handleToggleEnabled,
    handleUserAuthorization,
    handleToggleEmailVerified,
    handleTogglePhoneVerified,
    handleDeleteStripeCustomer,
  };
};

export default useUser;
