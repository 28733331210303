import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModemSignal = ({ signal }) => {
  const getColor = () => {
    if (signal > 0 && signal <= 1) return "bg-red-500";
    else if (signal > 1 && signal <= 2) return "bg-orange-500";
    else if (signal > 2 && signal <= 3) return "bg-orange-500";
    else if (signal > 3 && signal <= 4) return "bg-green-500";
    else if (signal > 4 && signal <= 5) return "bg-green-500";
    else return "bg-gray-700";
  };

  return (
    <div className='flex gap-0.5 items-end h-5 scale-75'>
      {(signal === 0 || signal === 255) && (
        <FontAwesomeIcon icon={faXmark} className='text-red-500' />
      )}
      {signal >= 1 && <div className={`w-1 h-1 ${getColor()}`}></div>}
      {signal >= 2 && <div className={`w-1 h-2 ${getColor()}`}></div>}
      {signal >= 3 && <div className={`w-1 h-3 ${getColor()}`}></div>}
      {signal >= 4 && <div className={`w-1 h-4 ${getColor()}`}></div>}
      {signal >= 5 && <div className={`w-1 h-5 ${getColor()}`}></div>}
    </div>
  );
};

export default ModemSignal;
