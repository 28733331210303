import { useLocation } from "react-router-dom";

const NotFoundPage = () => {
  const location = useLocation();

  return (
    <div className='page-wrapper rounded'>
      <div className='flex justify-center'>
        <span>
          Page <span className='font-bold'>{location.pathname}</span> not found
        </span>
      </div>
    </div>
  );
};

export default NotFoundPage;
