import { useEffect, useState } from "react";
import CarsTable from "../components/CarsTable";
import Loader from "../../../components/Loader";
import AddCarForm from "../components/AddCarForm";
import MapTracker from "./../components/MapTracker";
import Button from "../../../components/form/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const CarsPageView = ({
  cars,
  form,
  errors,
  sorting,
  isAdding,
  isLoading,
  pagination,
  yearOptions,
  setIsAdding,
  handleAddCar,
  expandedRows,
  inversDevices,
  totalElements,
  handleSorting,
  handleFormChange,
  handlePageChange,
  handleExpandedRows,
  carProvidersOptions,
  handlePageSizeChange,
  handleToggleAvailable,
  handleToggleBluetooth,
  handleToggleChildSeat,
  handleTogglePetFriendly,
}) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [carHovered, setCarHovered] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [carStateCounters, setCarStateCounters] = useState({});

  const handleMarkerClick = (carId) => {
    setInfoOpen(true);
    setCarHovered(carId);
    setSelectedMarker(carId);
  };

  const handleCloseMarker = () => {
    setInfoOpen(false);
    setCarHovered(null);
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (cars?.length > 0) {
      let riding = 0;
      let available = 0;
      let maintenance = 0;
      let unavailable = 0;

      riding = cars.filter((c) => c.attributes.isRiding).length;
      maintenance = cars.filter((c) => c.attributes.underMaintenance).length;
      unavailable = cars.filter(
        (c) => !c.attributes.isAvailable && !c.attributes.underMaintenance
      ).length;
      available = cars.filter(
        (c) => c.attributes.isAvailable && !c.attributes.isRiding && !c.attributes.underMaintenance
      ).length;

      setCarStateCounters({
        riding: riding,
        available: available,
        unavailable: unavailable,
        maintenance: maintenance,
      });
    }
  }, [cars]);

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'></div>
      <div className='page-wrapper rounded'>
        <div className='grid grid-cols-2 sm:flex items-center sm:gap-4 justify-end py-2 sm:py-0'>
          <div className='flex items-center gap-2 justify-center'>
            <div className='w-4 h-4 text-gray-900 bg-gray-900 rounded'></div>
            <span className='text-text-main'>{carStateCounters.available} Available</span>
          </div>
          <div className='flex items-center gap-2 justify-center'>
            <div className='w-4 h-4 text-gray-900 bg-green-600 rounded'></div>
            <span className='text-text-main'>{carStateCounters.riding} Riding</span>
          </div>
          <div className='flex items-center gap-2 justify-center'>
            <div className='w-4 h-4 text-gray-900 bg-orange-500 rounded'></div>
            <span className='text-text-main'>{carStateCounters.maintenance} Under Maintenance</span>
          </div>
          <div className='flex items-center gap-2 justify-center'>
            <div className='w-4 h-4 text-gray-900 bg-red-500 rounded'></div>
            <span className='text-text-main'>{carStateCounters.unavailable} Unavailable</span>
          </div>
        </div>
        <div className={`map-tracker-wrapper`}>
          <Loader isLoading={isLoading} />
          <MapTracker
            cars={cars}
            isInfoOpen={isInfoOpen}
            carHovered={carHovered}
            setCarHovered={setCarHovered}
            selectedMarker={selectedMarker}
            handleMarkerClick={handleMarkerClick}
            handleCloseMarker={handleCloseMarker}
          />
        </div>
        {isAdding && (
          <div className='page-content'>
            <AddCarForm
              form={form}
              errors={errors}
              yearOptions={yearOptions}
              inversDevices={inversDevices}
              handleFormChange={handleFormChange}
              carProvidersOptions={carProvidersOptions}
            />
            <div className='w-full flex gap-2 justify-end mt-4'>
              <Button
                classes='bg-bg-btn-cancel px-2 py-1'
                handleClick={() => setIsAdding(!isAdding)}
              >
                <div className='flex gap-1 items-center text-text-main'>
                  <FontAwesomeIcon icon={faXmark} size='sm' />
                  <span>Cancel</span>
                </div>
              </Button>
              {isAdding && (
                <Button handleClick={handleAddCar} classes='bg-blue-500 px-2 py-1'>
                  <div className='flex gap-1 items-center text-white'>
                    <FontAwesomeIcon icon={faCheck} size='sm' />
                    <span>Add</span>
                  </div>
                </Button>
              )}
            </div>
          </div>
        )}
        <div className='page-content rounded'>
          {!isAdding && (
            <div className='flex justify-end'>
              <div
                onClick={() => setIsAdding(!isAdding)}
                className='flex gap-2 items-center justify-end text-text-main cursor-pointer'
              >
                <FontAwesomeIcon icon={faSquarePlus} size='sm' />
                <span>Add Car</span>
              </div>
            </div>
          )}
          <div className='table-wrapper mt-2'>
            <CarsTable
              cars={cars}
              sorting={sorting}
              isLoading={isLoading}
              pagination={pagination}
              carHovered={carHovered}
              expandedRows={expandedRows}
              totalElements={totalElements}
              handleSorting={handleSorting}
              handlePageChange={handlePageChange}
              handleMarkerClick={handleMarkerClick}
              handleExpandedRows={handleExpandedRows}
              handlePageSizeChange={handlePageSizeChange}
              handleToggleAvailable={handleToggleAvailable}
              handleToggleBluetooth={handleToggleBluetooth}
              handleToggleChildSeat={handleToggleChildSeat}
              handleTogglePetFriendly={handleTogglePetFriendly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarsPageView;
