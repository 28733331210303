import User from "../../models/User";
import Authorizer from "../../components/Authorizer";
import RentingPageState from "./state/RentingPageState";

const RentingPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <RentingPageState {...props} />
    </Authorizer>
  );
};

export default RentingPage;
