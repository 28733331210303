const Selfie = ({ picture }) => {
  return (
    <div>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Selfie</span>
      </div>
      <div className='flex items-center justify-center rounded-lg aspect-square'>
        <img
          className='rounded-lg aspect-square object-contain object-center w-full'
          src={picture}
          alt='user-profile'
        />
      </div>
    </div>
  );
};

export default Selfie;
