const ForbiddenAccessPage = () => {
  return (
    <div className="page-wrapper rounded">
      <div className="flex justify-center">
        <span>You do not have access to this page</span>
      </div>
    </div>
  );
};

export default ForbiddenAccessPage;
