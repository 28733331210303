import { useEffect } from "react";
import ModemSignal from "./ModemSignal";
import useToaster from "../../../hooks/useToaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faMinus } from "@fortawesome/free-solid-svg-icons";

const CarInversStatus = ({
  car,
  modemStatus,
  isLoadingModem,
  getInversModemStatus,
  switchInversModemNetwork,
}) => {
  const toaster = useToaster();

  const handleSwitch = async () => {
    if (window.confirm(`Are you sure you want to switch the car's network?`)) {
      switchInversModemNetwork();
    }
  };

  useEffect(() => {
    getInversModemStatus();
  }, [car]);

  useEffect(() => {
    if (!!modemStatus?.data?.error) {
      toaster.error(modemStatus.data.error + " Refreshing the page might work.");
    }
  }, [modemStatus]);

  return (
    <>
      <div className='flex items-center gap-0.5 scale-75 sm:scale-100 text-text-main'>
        {isLoadingModem ? (
          <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : !!!modemStatus ? (
          <div>
            <FontAwesomeIcon icon={faMinus} size='xl' />
          </div>
        ) : (
          <span>
            <ModemSignal signal={modemStatus?.signal_quality} />
          </span>
        )}
        {isLoadingModem ? (
          <div className='w-10 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : !!!modemStatus ? (
          <div>
            <FontAwesomeIcon icon={faMinus} size='xl' />
          </div>
        ) : (
          <span className="text-lg sm:text-base">{modemStatus?.provider_name}</span>
        )}

        <div onClick={isLoadingModem ? null : handleSwitch} className='ml-1 text-text-main'>
          <FontAwesomeIcon
            icon={faArrowsRotate}
            className={`${
              isLoadingModem
                ? "animate-spin cursor-not-allowed"
                : "hover:animate-spin cursor-pointer"
            }`}
          />
        </div>
      </div>
    </>
  );
};

export default CarInversStatus;
