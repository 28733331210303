import dayjs from "dayjs";
import Utils from "../../../utils/Utils";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFileDownload, faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({
  filters,
  isLoading,
  isLoadingTotals,
  handleClearFilters,
  handleFilterChange,
  handleApplyFilters,
  handleOpenExportModal,
}) => {
  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
              <div className='flex items-center gap-2 z-20' onClick={handleOpenExportModal}>
                <FontAwesomeIcon icon={faFileDownload} />
                Export
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <span className='text-lg font-semibold'>
                <FormInput
                  type='text'
                  label='Username'
                  classes='w-full'
                  placeholder='Username'
                  value={Utils.isNull(filters?.username) ? "" : filters?.username}
                  handleChange={(e) => handleFilterChange("username", e.target.value)}
                />
              </span>
            </div>
            <div>
              <span className='text-lg font-semibold'>
                <FormInput
                  type='text'
                  label='Phone'
                  classes='w-full'
                  placeholder='Phone'
                  value={Utils.isNull(filters?.phone) ? "" : filters?.phone}
                  handleChange={(e) => handleFilterChange("phone", e.target.value)}
                />
              </span>
            </div>
            <div>
              <span className='text-lg font-semibold'>
                <FormInput
                  type='text'
                  label='Last Name'
                  classes='w-full'
                  placeholder='Last Name'
                  value={Utils.isNull(filters?.lastName) ? "" : filters?.lastName}
                  handleChange={(e) => handleFilterChange("lastName", e.target.value)}
                />
              </span>
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
            <div className='col-span-1 md:col-span-2 lg:col-span-4 flex items-end gap-2'>
              <FormCheckbox
                label='Locked'
                value={filters.isLocked}
                handleChange={(checked) => handleFilterChange("isLocked", checked)}
              />
              <FormCheckbox
                label='Has Note'
                value={filters.hasNotes}
                handleChange={(checked) => handleFilterChange("hasNotes", checked)}
              />
              <FormCheckbox
                label='Is Disabled'
                value={filters.isDisabled}
                handleChange={(checked) => handleFilterChange("isDisabled", checked)}
              />
            </div>
            <Button
              classes={`bg-blue-500 text-white px-2 py-1`}
              handleClick={handleApplyFilters}
              disabled={isLoading || isLoadingTotals}
              isLoading={isLoading || isLoadingTotals}
            >
              Apply
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
