export default class User {
  static TYPES = {
    SMS: "SMS",
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    IN_APP_MESSAGE: "IN APP MESSAGE",
  };

  static STATUS = {
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    FOLLOW_UP: "FOLLOW UP",
  };

  static PRIORITY = {
    LOW: "LOW",
    STANDARD: "STANDARD",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
    URGENT: "URGENT",
  };

  static TAGS = {
    SUPPORT: "SUPPORT",
    SALES: "SALES",
    ACCIDENT: "ACCIDENT",
    FINE: "FINE",
    UNPAID_TRIP: "UNPAID TRIP",
  };

  static getColor = (status) => {
    switch (status) {
      case this.STATUS.PENDING:
        return "bg-gray-500";
      case this.PRIORITY.LOW:
        return "bg-gray-500";
      case this.STATUS.FOLLOW_UP:
        return "bg-blue-500";
      case this.PRIORITY.STANDARD:
        return "bg-blue-500";
      case this.PRIORITY.MEDIUM:
        return "bg-yellow-500";
      case this.STATUS.COMPLETED:
        return "bg-green-500";
      case this.PRIORITY.HIGH:
        return "bg-orange-500";
      case this.PRIORITY.URGENT:
        return "bg-red-500";
      default:
        break;
    }
  };
}
