import { useCallback } from "react";

const useStorage = (name) => {
  // Read data from localStorage
  const read = useCallback(
    (key) => {
      try {
        const data = localStorage.getItem(name);
        const obj = JSON.parse(data) ?? {};
        const retrieve = obj[key] ?? {};

        return retrieve;
      } catch (error) {
        console.error("Error reading from localStorage", error);
        return {};
      }
    },
    [name]
  );

  // Save data to localStorage
  const save = useCallback(
    (data) => {
      try {
        localStorage.setItem(name, JSON.stringify(data ?? null));
      } catch (error) {
        console.error("Error writing to localStorage", error);
      }
    },
    [name]
  );

  // Add data to the existing data in localStorage
  const add = useCallback(
    (data) => {
      const currentData = read();
      const newData = { ...currentData, ...(data ?? {}) };
      save(newData);
    },
    [read, save]
  );

  return { read, save, add };
};

export default useStorage;
