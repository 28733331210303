import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils/Dates";
import Button from "../../../components/form/Button";
import DocumentViewer from "../../../components/DocumentViewer";
import FormTextarea from "../../../components/form/FormTextarea";
import FormDatepicker from "../../../components/form/FormDatepicker";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";

const CarContract = ({ contract, isLoading, markOutdated, handleSubmit }) => {
  const comments = contract?.attributes.comments;
  const [canSubmit, setCanSubmit] = useState(false);
  const [form, setForm] = useState({
    file: null,
    startDate: null,
    endDate: null,
    comments: "",
  });

  const handleOutdate = () => {
    setForm({
      file: null,
      startDate: null,
      endDate: null,
      comments: "",
    });
    markOutdated("contract", contract.id);
  };

  useEffect(() => {
    Object.keys(form)
      .filter((k) => k !== "comments")
      .filter((k) => Utils.isNull(form[k])).length === 0
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [form]);

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className={`car-document ${isLoading && "blur-sm"}`}>
        {!!contract ? (
          <div>
            <div className='info'>
              <div>
                <span>Εταιρεία:</span>
                <span>{contract.attributes.provider.attributes.carProviderName}</span>
              </div>
              <div>
                <span>Έναρξη:</span>
                <span>{dateFormatter(contract.attributes.startDate)}</span>
              </div>
              <div>
                <span>Λήξη:</span>
                <span>{dateFormatter(contract.attributes.endDate)}</span>
              </div>
              <div className='absolute right-0 top-0 md:static'>
                <Button
                  classes='bg-red-600 py-1 px-2'
                  disabled={isLoading}
                  handleClick={handleOutdate}
                >
                  <span className='text-white'>Διαγραφή</span>
                </Button>
              </div>
            </div>
            <div className='document'>
              <DocumentViewer document={contract.attributes.document._url} />
            </div>
            {!!comments && <div className='comments'>{comments}</div>}
          </div>
        ) : (
          <div className='relative'>
            <Loader isLoading={isLoading} />
            <div className={`${isLoading && "blur-sm"}`}>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4 items-center'>
                <div className='col-span-1'>
                  <FileUploadDropzone
                    isMultifile={false}
                    acceptedTypes={["pdf", "jpg", "jpeg", "png"]}
                    title='Drag and drop the contract file here.'
                    emitFiles={(file) => setForm((prevState) => ({ ...prevState, file: file }))}
                  />
                </div>
                <div className='col-span-1 flex flex-col gap-2'>
                  <FormDatepicker
                    label='Start Date'
                    value={form.startDate}
                    isClearable={true}
                    handleChange={(value) =>
                      setForm((prevState) => ({
                        ...prevState,
                        startDate: value,
                      }))
                    }
                  />
                  <FormDatepicker
                    label='End Date'
                    value={form.endDate}
                    isClearable={true}
                    handleChange={(value) =>
                      setForm((prevState) => ({ ...prevState, endDate: value }))
                    }
                  />
                </div>
                <div className='col-span-1'>
                  <FormTextarea
                    rows={7}
                    label='Comments'
                    value={form.comments}
                    placeholder='Type comments'
                    handleChange={(value) =>
                      setForm((prevState) => ({
                        ...prevState,
                        comments: value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className='mt-2 flex gap-2 justify-end'>
                <Button
                  classes='bg-blue-500 py-2 px-3'
                  isLoading={isLoading}
                  disabled={!canSubmit || isLoading}
                  handleClick={() => handleSubmit("contract", form)}
                >
                  <span className='text-white'>Submit</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarContract;
