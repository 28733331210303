import Utils from "../../../utils/Utils";
import Comms from "../../../models/Communication";
import { findOption } from "../../../utils/Forms";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const typeOptions = Object.keys(Comms.TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(filters.type)
    ? findOption(typeOptions, String(filters.type)?.toUpperCase())
    : null;

  const priorityOptions = Object.keys(Comms.PRIORITY).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const priorityDefaultOption = !Utils.isNull(filters.priority)
    ? findOption(priorityOptions, String(filters.priority)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Comms.STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(filters.status)
    ? findOption(statusOptions, String(filters.status)?.toUpperCase())
    : null;

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormSelect
                label='Type'
                classes='w-full'
                isClearable={true}
                options={typeOptions}
                value={typeDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("type", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Priority'
                classes='w-full'
                isClearable={true}
                options={priorityOptions}
                value={priorityDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("priority", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Status'
                classes='w-full'
                isClearable={true}
                options={statusOptions}
                value={statusDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("status", option)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
