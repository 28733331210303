import Filters from "../components/Filters";
import RentingsTable from "../components/RentingsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { secondsToDaysHoursMinutes } from "../../../utils/Dates";
import { faCircleNotch, faEuro, faMinus } from "@fortawesome/free-solid-svg-icons";

const RentingsPageView = ({
  totals,
  sorting,
  filters,
  fromCar,
  fromUser,
  rentings,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  isLoadingTotals,
  handleExportData,
  handleAddRenting,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handleApplyFilters,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <div className='page-title'>
            <span className='title'>RENTINGS</span>
          </div>
        )}
        <Filters
          filters={filters}
          fromCar={fromCar}
          fromUser={fromUser}
          isLoading={isLoading}
          isLoadingTotals={isLoadingTotals}
          handleExportData={handleExportData}
          handleAddRenting={handleAddRenting}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
        />
        {!isExternalPage && (
          <div className='page-totals'>
            <div className='relative'>
              <span>{!!totals.dailyRentings ? "Daily Rentings" : "Rentings"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyRentings ? (
                    totals.dailyRentings
                  ) : !!totals.rentings ? (
                    totals.rentings
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
            <div>
              <span>{!!totals.dailyTimeSpent ? "Daily Time Spent" : "Time Spent"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyTimeSpent ? (
                    secondsToDaysHoursMinutes(totals.dailyTimeSpent)
                  ) : !!totals.timeSpent ? (
                    secondsToDaysHoursMinutes(totals.timeSpent)
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
            <div>
              <span>{!!totals.dailyRevenue ? "Daily Revenue" : "Revenue"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyRevenue ? (
                    <>
                      {parseFloat(totals.dailyRevenue).toFixed(2)} <FontAwesomeIcon icon={faEuro} />
                    </>
                  ) : !!totals.revenue ? (
                    <>
                      {parseFloat(totals.revenue).toFixed(2)} <FontAwesomeIcon icon={faEuro} />
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
            <div>
              <span>Daily OPEX</span>
              <span>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            </div>
          </div>
        )}
        <div className='table-wrapper'>
          <RentingsTable
            sorting={sorting}
            rentings={rentings}
            isLoading={isLoading}
            pagination={pagination}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RentingsPageView;
