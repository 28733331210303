import Car from "../../../models/Car";
import { Box, CircularProgress } from "@mui/material";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarFuelIndicator = ({ fuel, isCircular = false }) => {
  const color = Car.getFuelColor(fuel);

  return (
    <div className='flex items-center'>
      {isCircular ? (
        <div className='relative inline-flex'>
          <CircularProgress
            variant='determinate'
            value={fuel > 100 ? 100 : fuel}
            color={Car.getFuelColorMUI(fuel)}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className='font-semibold tracking-tighter'>{fuel > 100 ? "100" : fuel}%</span>
          </Box>
        </div>
      ) : (
        <div className='relative w-full bg-gray-200 rounded-full h-4 sm:h-3.5 md:h-4 dark:bg-gray-700'>
          <div
            className={`${color} h-4 sm:h-3.5 md:h-4 rounded-full`}
            style={{ width: fuel > 100 ? 100 + "%" : fuel + "%" }}
          >
            <div className='absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 flex items-center justify-center text-gray-50 text-sm'>
              <span className='font-semibold mr-1'>{fuel > 100 ? "100" : fuel}</span>
              <FontAwesomeIcon icon={faPercent} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarFuelIndicator;
