import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Button from "../../../components/form/Button";
import { Map, Marker } from "@vis.gl/react-google-maps";
import FormTextarea from "../../../components/form/FormTextarea";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";
import { faCheckCircle, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const LogIncidentModal = ({ renting, logIncident, toggleLogIncident }) => {
  const userHook = useUser();
  const [zoom, setZoom] = useState(11);
  const session = userHook.getCurrentSession();
  const [isLoadingIncident, setLoadingIncident] = useState(false);
  const [canSubmitIncident, setCanSubmitIncident] = useState(false);
  const [incidentSubmitSuccess, setIncidentSubmitSuccess] = useState(false);
  const [incidentForm, setIncidentForm] = useState({
    files: null,
    injury: false,
    address: null,
    datetime: null,
    description: "",
    carDamage: false,
    geolocation: null,
  });

  const handleSubmitIncident = async (form) => {
    setLoadingIncident(true);
    try {
      const rentingPointer = {
        __type: "Pointer",
        className: "Rentings",
        objectId: renting.id,
      };
      const submittedByPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: session.user.objectId,
      };

      const incident = new Parse.Object("Incident");
      incident.set("renting", rentingPointer);
      incident.set("submittedBy", submittedByPointer);
      incident.set("description", form.description);
      incident.set("date", form.datetime);

      if (!!form.files) {
        let parseFileArray = [];
        form.files.map(async (file) => {
          let parseFile = new Parse.File(file.name, file);
          parseFileArray.push(parseFile);
        });

        let fileURLS = [];
        Parse.Object.saveAll(parseFileArray)
          .then(async (r) => {
            r.forEach((file) => {
              fileURLS.push(file._url);
            });

            incident.set("files", fileURLS);
            form.injury && incident.set("injury", form.injury);
            form.carDamage && incident.set("carDamage", form.carDamage);
            form.geolocation && incident.set("location", form.geolocation);

            await incident.save();

            const Renting = new Parse.Object("Rentings").set("objectId", renting.id);
            const relation = Renting.relation("incident");
            relation.add(incident);
            await Renting.save();
          })
          .finally(() => {
            fileURLS = [];
            parseFileArray = [];
            setLoadingIncident(false);
            setIncidentSubmitSuccess(true);
            setTimeout(() => {
              toggleLogIncident(false);
              setIncidentSubmitSuccess(false);
            }, 1500);
          })
          .finally(() => {
            setIncidentForm({
              files: null,
              injury: false,
              address: null,
              datetime: null,
              description: "",
              carDamage: false,
              geolocation: null,
            });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        form.injury && incident.set("injury", form.injury);
        form.carDamage && incident.set("carDamage", form.carDamage);
        form.geolocation && incident.set("location", form.geolocation);

        await incident
          .save()
          .then(async (r) => {
            const Renting = new Parse.Object("Rentings").set("objectId", renting.id);
            const relation = Renting.relation("incident");
            relation.add(incident);
            await Renting.save();

            setLoadingIncident(false);
            setIncidentSubmitSuccess(true);
            setTimeout(() => {
              toggleLogIncident(false);
              setIncidentSubmitSuccess(false);
            }, 1500);
          })
          .finally(() => {
            setIncidentForm({
              files: null,
              injury: false,
              address: null,
              datetime: null,
              description: "",
              carDamage: false,
              geolocation: null,
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancelSubmitIncident = () => {
    setIncidentForm({
      files: null,
      injury: false,
      address: null,
      datetime: null,
      description: "",
      carDamage: false,
      geolocation: null,
    });
    toggleLogIncident(false);
    setLoadingIncident(false);
  };

  const handleMapClick = (click) => {
    const latitude = click.detail.latLng.lat;
    const longitude = click.detail.latLng.lng;
    const geolocation = new Parse.GeoPoint(Number(latitude), Number(longitude));

    Parse.Cloud.run("getAddress", {
      latitude: latitude,
      longitude: longitude,
    }).then((address) => {
      setIncidentForm((prev) => ({
        ...prev,
        address: address,
        geolocation: geolocation,
      }));
    });
  };

  useEffect(() => {
    if (!Utils.isEmpty(incidentForm.description) && !Utils.isNull(incidentForm.datetime)) {
      setCanSubmitIncident(true);
    } else {
      setCanSubmitIncident(false);
    }
  }, [incidentForm]);

  return (
    <Modal
      open={logIncident}
      title='Log Incident'
      classes={"w-11/12 md:w-2/3 xl:w-2/3 2xl:w"}
      handleToggle={() => toggleLogIncident(!logIncident)}
    >
      <div>
        <div>
          <span>Where?</span>
        </div>
        <div className='w-full h-60 mb-2 border border-solid border-gray-300 rounded overflow-hidden'>
          <Map
            defaultZoom={zoom}
            disableDefaultUI={false}
            onClick={handleMapClick}
            gestureHandling={"greedy"}
            mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
            defaultCenter={{ lat: 37.9753151, lng: 23.7277144 }}
          >
            {!!incidentForm.geolocation && (
              <Marker
                position={{
                  lat: incidentForm.geolocation.latitude,
                  lng: incidentForm.geolocation.longitude,
                }}
              />
            )}
          </Map>
        </div>
        {!!incidentForm.address && (
          <div className='flex items-center justify-center mt-2 pb-2'>
            <FontAwesomeIcon icon={faLocationDot} size='lg' className='text-red-700' />
            <span className='ml-1'>{incidentForm.address}</span>
          </div>
        )}
        <hr />
        <div className='mb-2'>
          <FormTextarea
            rows={4}
            label='Description'
            disabled={isLoadingIncident}
            value={incidentForm.description}
            placeholder='Describe the incident...'
            handleChange={(value) =>
              setIncidentForm((prevState) => ({
                ...prevState,
                description: value,
              }))
            }
          />
        </div>
        <div className='grid grid-cols-2 items-center gap-2 mb-2'>
          <div className='col-span-2 md:col-span-1'>
            <FileUploadDropzone
              isMultifile={true}
              disabled={isLoadingIncident}
              acceptedTypes={["jpg", "jpeg", "png"]}
              title='Drag and drop the incident images here.'
              emitFiles={(files) =>
                setIncidentForm((prevState) => ({
                  ...prevState,
                  files: Array.isArray(files) ? files : [files],
                }))
              }
            />
          </div>
          <div className='col-span-2 md:col-span-1'>
            <FormDatepicker
              label='When?'
              withTime={true}
              isClearable={true}
              disabled={isLoadingIncident}
              value={incidentForm.datetime}
              handleChange={(value) =>
                setIncidentForm((prevState) => ({
                  ...prevState,
                  datetime: value,
                }))
              }
            />
            <div className='flex items-center gap-2 mt-2'>
              <FormCheckbox
                label='Car Damaged?'
                disabled={isLoadingIncident}
                value={incidentForm.carDamage}
                handleChange={(checked) =>
                  setIncidentForm((prevState) => ({
                    ...prevState,
                    carDamage: checked,
                  }))
                }
              />
              <FormCheckbox
                label='Injury?'
                value={incidentForm.injury}
                disabled={isLoadingIncident}
                handleChange={(checked) =>
                  setIncidentForm((prevState) => ({
                    ...prevState,
                    injury: checked,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className='flex items-center justify-end gap-2 mt-2'>
          <Button
            disabled={isLoadingIncident || incidentSubmitSuccess}
            classes='bg-gray-200 py-1 px-2'
            handleClick={handleCancelSubmitIncident}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoadingIncident || !canSubmitIncident || incidentSubmitSuccess}
            isLoading={isLoadingIncident}
            classes={`bg-blue-500 py-1 px-2 ${isLoadingIncident && "pr-7"}`}
            handleClick={() => handleSubmitIncident(incidentForm)}
          >
            <span className='text-white'>Submit</span>
            {incidentSubmitSuccess && (
              <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogIncidentModal;
