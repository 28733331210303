import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DamageCard = ({ side, imgURL, handleOpen }) => {
  return (
    <div>
      <div className="mb-2">
        <span>{side}</span>
      </div>
      {imgURL ? (
        <div className="flex items-center w-full aspect-square">
          <img
            src={imgURL}
            alt="front-damage-picture"
            className="aspect-square rounded hover:opacity-80 transition-all ease-in-out duration-150 cursor-pointer object-cover object-center"
            onClick={() => handleOpen(imgURL)}
          />
        </div>
      ) : (
        <div className="block w-full h-full">
          <div className="flex w-full h-full items-center justify-center">
            <FontAwesomeIcon icon={faMinus} size="lg" className="-mt-4" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DamageCard;
