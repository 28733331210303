import Utils from "../../../utils/Utils";
import "yet-another-react-lightbox/styles.css";
import DocumentWrapper from "./DocumentWrapper";
import { useCallback, useEffect, useState } from "react";
import ImageLightbox from "../../../components/ImageLightbox";
import ImageNotFound from "../../../assets/images/image_not_found.jpg";

const UserAdditionalDocumentation = ({ licenseIntl, residencyProof, handleSubmitDocument }) => {
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState(null);
  const initializeSlides = useCallback(() => {
    let slds = [];
    !Utils.isNull(licenseIntl.front) && slds.push({ src: licenseIntl.front });
    !Utils.isNull(licenseIntl.back) && slds.push({ src: licenseIntl.back });
    !Utils.isNull(residencyProof.proof1) && slds.push({ src: residencyProof.proof1 });
    !Utils.isNull(residencyProof.proof2) && slds.push({ src: residencyProof.proof2 });
    setSlides(slds);
  });

  const hasIntlDocs =
    !Utils.isNull(licenseIntl.front) ||
    !Utils.isNull(licenseIntl.back) ||
    !Utils.isNull(residencyProof.proof1) ||
    !Utils.isNull(residencyProof.proof2);

  const handleOpenImage = (imageUrl) => {
    let index = slides.findIndex((item) => item.src === imageUrl);
    setIndex(index);
  };

  useEffect(() => {
    initializeSlides();
  }, []);

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Additional Documentation</span>
      </div>
      <div className='flex flex-col gap-2'>
        {hasIntlDocs && (
          <>
            <div>
              <div>
                <span className='font-semibold'>Intl License:</span>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <div className='col-span-1'>
                  <DocumentWrapper
                    title='Front'
                    image={licenseIntl.front}
                    document='licenseIntlFront'
                    notFoundImage={ImageNotFound}
                    handleOpenImage={handleOpenImage}
                    handleSubmitDocument={handleSubmitDocument}
                  />
                </div>
                <div className='col-span-1'>
                  <DocumentWrapper
                    title='Back'
                    image={licenseIntl.back}
                    document='licenseIntlBack'
                    notFoundImage={ImageNotFound}
                    handleOpenImage={handleOpenImage}
                    handleSubmitDocument={handleSubmitDocument}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <span className='font-semibold'>Residency Proof:</span>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <div className='col-span-1'>
                  <DocumentWrapper
                    document='proof1'
                    image={residencyProof.proof1}
                    notFoundImage={ImageNotFound}
                    handleOpenImage={handleOpenImage}
                    handleSubmitDocument={handleSubmitDocument}
                  />
                </div>
                <div className='col-span-1'>
                  <DocumentWrapper
                    document='proof2'
                    image={residencyProof.proof2}
                    notFoundImage={ImageNotFound}
                    handleOpenImage={handleOpenImage}
                    handleSubmitDocument={handleSubmitDocument}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </>
  );
};

export default UserAdditionalDocumentation;
