const CarAdditionalInfo = ({ car }) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Additional Information</span>
      </div>
      <div className='car-details'>
        <span>ID</span>
        <span>{car.id}</span>
        <span>Transmission</span>
        <span>{car.autoTransmission ? "Automatic" : "Manual"}</span>
        <span>Seats</span>
        <span>{car.seatsNum}</span>
        <span>Manufacturing Date</span>
        <span>{car.manufacturingDate}</span>
        <span>Mileage</span>
        <span>{car.kmTraveled} km</span>
        <span>Owner</span>
        <span>{car.owner.carProviderName}</span>
        <span>Invers QNR</span>
        <span>{car.inversQNR}</span>
        <span>Created</span>
        <span>{car.createdAt}</span>
      </div>
    </>
  );
};

export default CarAdditionalInfo;
