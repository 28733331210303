import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#fff",
    maxWidth: 220,
    fontSize: "12px",
    padding: "8px 10px",
    backgroundColor: "#222222F2",
  },
}));

export default HtmlTooltip;
