import Parse from "parse";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Utils from "../../../utils/Utils";
import { pdf } from "@react-pdf/renderer";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Button from "../../../components/form/Button";
import { useCallback, useEffect, useState } from "react";
import FormInput from "../../../components/form/FormInput";
import useRentalReport from "../../../hooks/useRentalReport";

const DeleteUserModal = ({
  user,
  isOpen,
  toggleModal,
  isPaymentMethodsModalOpen,
  togglePaymentMethodsModal,
}) => {
  const userHook = useUser();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const rentalReportHook = useRentalReport();
  const [rentings, setRentings] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();
  const [isLoadingPaymentMethods, setLoadingPaymentMethods] = useState(false);

  const checkRentings = useCallback(async () => {
    const r = await new Parse.Query("Rentings")
      .equalTo("driver", {
        __type: "Pointer",
        className: "_User",
        objectId: user.id,
      })
      .findAll({ useMasterKey: true });
    setRentings(r);
  }, []);

  const checkPaymentMethods = async () => {
    try {
      if (!!user.customerId) {
        const PM = await Parse.Cloud.run("listPaymentMethods", {
          customerId: user.customerId,
        });

        setPaymentMethods(PM.data);
        setLoadingPaymentMethods(false);
      } else {
        setPaymentMethods(0);
        setLoadingPaymentMethods(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // -- Delete User Functions -- //
  const downloadRideReports = async () => {
    try {
      if (rentings.length > 0) {
        const files = await Promise.all(
          rentings.map(async (rent) => {
            const PDF = await rentalReportHook.generate(rent, false);
            const blob = await pdf(PDF).toBlob();
            return blob;
          })
        );

        const zip = new JSZip();

        files.forEach((f, i) => {
          zip.file(`Rental Report ${i + 1}.pdf`, f);
        });

        const completeZip = await zip.generateAsync({ type: "blob" });
        saveAs(completeZip, `${user.username} Rental Reports.zip`);
        if (Object.keys(zip.files).length === rentings.length) return true;
        else return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const deleteStripeCustomer = async () => {
    if (!!user.customerId && !Utils.isEmpty(user.customerId)) {
      return await userHook.handleDeleteStripeCustomer(user).then(async (deleted) => {
        if (deleted) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    downloadRideReports().then((reportSuccess) => {
      if (reportSuccess) {
        deleteStripeCustomer().then((stripeSuccess) => {
          if (stripeSuccess) {
            userHook.handleDelete(user).then(() => {
              setInputValue("");
              toggleModal(false);
              setVerified(false);
              setLoading(false);
              navigate("/users");
            });
          } else {
            setErrors(
              "There was a problem deleting the user's stripe customer and the user deletion process has failed. Try to initiate the deletion procedure again by deleting the user. If the issue persists, contact the engineering team."
            );
            setLoading(false);
          }
        });
      } else {
        setErrors(
          "There was a problem downloading the user's rental reports. Try to initiate the deletion procedure again by deleting the user. If the issue persists, contact the engineering team."
        );
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setInputValue("");
    toggleModal(false);
    setVerified(false);
  };

  useEffect(() => {
    const checkMark = "delete " + user.username;

    if (inputValue === checkMark) setVerified(true);
    else setVerified(false);
  }, [inputValue]);

  useEffect(() => {
    setLoadingPaymentMethods(true);
    checkPaymentMethods();
  }, [isPaymentMethodsModalOpen]);

  useEffect(() => {
    checkRentings();
  }, []);

  return (
    <Modal
      open={isOpen}
      title='USER DELETION'
      classes={"w-4/5 md:w-1/2 xl:w-1/3 text-text-main"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='relative'>
        <Loader isLoading={isLoadingPaymentMethods} />
        {paymentMethods?.length > 0 ? (
          <>
            <div>
              <span>This user has payment methods set.</span>
            </div>
            <div>
              <span>In order to delete the user, the payments methods must be removed first.</span>
            </div>
            <div>
              Review them{" "}
              <span
                className='link underline'
                onClick={() => {
                  setLoadingPaymentMethods(true);
                  togglePaymentMethodsModal(true);
                }}
              >
                here
              </span>
              .
            </div>
          </>
        ) : (
          <>
            <div className='mb-4'>
              <span className='text-xl'>Please review below your action carefully.</span>
            </div>
            <div className='mb-4'>
              <span>
                Are you sure you want to delete <span className='font-bold'>{user.username}</span>?
              </span>
            </div>
            <div className='mb-4'>
              <span>
                Deleting will result to the following actions:
                <ol>
                  <li className='pl-4'>• Delete User's Referrals</li>
                  <li className='pl-4'>• Delete User's Discounts</li>
                  <li className='pl-4'>• Delete User's Messages</li>
                  <li className='pl-4'>• Delete User's Rentings data</li>
                  <li className='pl-4'>• Delete User's Payments data</li>
                  <li className='pl-4'>• Delete User's Communication History</li>
                </ol>
              </span>
            </div>
            <div className='mb-2'>
              <span>To delete the user type in "delete email" (the user's email)</span>
            </div>
            <div>
              <FormInput
                type='text'
                placeholder='delete username'
                value={inputValue}
                handleChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            {isVerified && (
              <div className='mt-2 mb-2 text-center'>
                <span className='text-lg font-bold text-red-500'>
                  Be careful! The action is irreversible
                </span>
              </div>
            )}
            <div className='grid grid-cols-2 gap-2 mt-4'>
              <Button
                classes='bg-gray-200 py-2 text-gray-900'
                disabled={isLoading}
                handleClick={handleCancel}
              >
                <span>Cancel</span>
              </Button>
              <Button
                disabled={isLoading || !isVerified}
                isLoading={isLoading}
                handleClick={handleSubmit}
                classes={`bg-red-500 py-2`}
              >
                <span className='text-white'>DELETE</span>
              </Button>
            </div>
          </>
        )}
        {!!errors && (
          <div className='mt-4'>
            <span className='text-red-400 text-base'>{errors}</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
