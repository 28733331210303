import Parse from "parse";
import "../styles/styles.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import Renting from "../../../models/Renting";
import UserPageView from "../view/UserPageView";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import { userSerializer } from "../../../models/serializers";

const UserPageState = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [totals, setTotals] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState({ licenseId: null });
  const [isLoadingForm, setLoadingForm] = useState(false);
  const [isLoadingUser, setLoadingUser] = useState(false);
  const [duplicateLicenseNo, setDuplicateLicenseNo] = useState(null);

  const getUser = async () => {
    const Users = Parse.Object.extend("_User");
    const query = new Parse.Query(Users);
    query.equalTo("objectId", userId);
    query.include("country");

    try {
      const u = await query.first();
      const uS = userSerializer(u);

      if (!!uS.license.id) {
        const checkDuplicate = await new Parse.Query("_User")
          .includeAll()
          .notEqualTo("objectId", uS.id)
          .equalTo("licenseNo", uS.license.id)
          .first({ useMasterKey: true });
        setDuplicateLicenseNo(userSerializer(checkDuplicate));
      }

      setUser(uS);
      setForm((prev) => ({
        ...prev,
        licenseId: userSerializer(u).license?.id,
      }));
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const getTotals = async () => {
    try {
      const totalRentings = await new Parse.Query("Rentings")
        .equalTo("driver", {
          __type: "Pointer",
          className: "_User",
          objectId: userId,
        })
        .includeAll()
        .findAll({
          useMasterKey: true,
        });

      const discountsCalculated = await Renting.provideTotalBill(totalRentings);

      setTotals({
        rentings: totalRentings.length,
        revenue: discountsCalculated
          .filter((r) => r.attributes.isPaid)
          .reduce((a, b) => a + (b.attributes.totalBill || 0), 0),
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const checkSetReviewed = async () => {
    if (!!user) {
      const isReviewed = user.isReviewed;

      if (!isReviewed) {
        const isEnabled = user.isEnabled;
        const isRejected = user.isRejected;
        const isAuthorized = user.isAuthorized;

        if (isEnabled && !isRejected && !isAuthorized) {
          await new Parse.Object("_User")
            .set("objectId", user.id)
            .set("isReviewed", true)
            .save(null, { useMasterKey: true });
        }
      }
    }
  };

  const handleToggleEmailVerified = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleEmailVerified(toggle, userId);
  };

  const handleTogglePhoneVerified = async (toggle) => {
    setLoading(true);
    await userHook.handleTogglePhoneVerified(toggle, userId);
  };

  const handleUserAuthorization = async (action, _) => {
    setLoading(true);
    await userHook.handleUserAuthorization(action, userId);
  };

  const handleToggleEnabled = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleEnabled(toggle, userId);
  };

  const handleToggleLocked = async (toggle) => {
    setLoading(true);
    await userHook.handleToggleLocked(toggle, userId);
  };

  const handleNotesSubmit = async (value) => {
    setLoading(true);
    await userHook.handleNotesSubmit(value, userId);
  };

  const handleFormChange = (field, value) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setLoadingForm(true);
    try {
      const NewUser = new Parse.Object("_User");
      NewUser.set("objectId", userId);
      NewUser.set("licenseNo", form.licenseId);
      await NewUser.save(null, { useMasterKey: true }).then(() => {
        setUser((prev) => ({
          ...prev,
          license: { ...prev.license, id: form.licenseId },
        }));
        setIsEditing(false);
        setLoadingForm(false);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmitDocument = async (document, file) => {
    try {
      let fileBase64 = null;
      let fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(console.error("Problem parsing input file."));
          toaster.error("Problem parsing input file.");
        };

        fileReader.onload = async (fileLoadedEvent) => {
          fileBase64 = fileLoadedEvent.target.result;
          const data = fileBase64.split(",")[1];

          const parseFile = new Parse.File(file.name, { base64: data });

          const newDocument = new Parse.Object("_User");
          newDocument.set("objectId", userId);
          newDocument.set(document, parseFile);

          await newDocument.save(null, { useMasterKey: true }).then(() => {
            getUser().then(() => {
              resolve("ok");
            });
          });
        };

        fileReader.readAsDataURL(file);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    getTotals();
  }, []);

  useEffect(() => {
    checkSetReviewed();
  }, [user]);

  useEffect(() => {
    getUser().finally(() => {
      setLoading(false);
    });
  }, [isLoading]);

  useEffect(() => {
    setLoadingUser(true);
    getUser().finally(() => {
      setLoadingUser(false);
    });
  }, [userId]);

  return (
    <>
      {!user || isLoadingUser ? (
        <Loader isLoading={isLoading || isLoadingUser} />
      ) : (
        <UserPageView
          user={user}
          form={form}
          totals={totals}
          isEditing={isEditing}
          isLoading={isLoading}
          handleEdit={handleEdit}
          handleSave={handleSave}
          isLoadingForm={isLoadingForm}
          handleFormChange={handleFormChange}
          handleNotesSubmit={handleNotesSubmit}
          duplicateLicenseNo={duplicateLicenseNo}
          handleToggleLocked={handleToggleLocked}
          handleToggleEnabled={handleToggleEnabled}
          handleSubmitDocument={handleSubmitDocument}
          handleUserAuthorization={handleUserAuthorization}
          handleToggleEmailVerified={handleToggleEmailVerified}
          handleTogglePhoneVerified={handleTogglePhoneVerified}
        />
      )}
    </>
  );
};

export default UserPageState;
