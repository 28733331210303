import Filters from "../components/Filters";
import LogsTable from "../components/LogsTable";

const LogsPageView = ({
  logs,
  filters,
  sorting,
  isLoading,
  pagination,
  totalElements,
  distinctUsers,
  handleSorting,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        <Filters
          filters={filters}
          isLoading={isLoading}
          distinctUsers={distinctUsers}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
        <div className='table-wrapper'>
          <LogsTable
            logs={logs}
            isLoading={isLoading}
            pagination={pagination}
            totalElements={totalElements}
            sorting={sorting}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default LogsPageView;
