import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import { dateFormatter } from "../../../utils/Dates";
import ImageLightbox from "../../../components/ImageLightbox";
import DocumentViewer from "../../../components/DocumentViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import SliderPhoto from "../../../components/SliderPhoto";

const MaintenancesRowDetails = ({ maintenance, jobs }) => {
  let slides = [];
  let jobCounter = 0;
  const [index, setIndex] = useState(-1);

  if (!Utils.isNull(maintenance.photos.one)) {
    slides.push({ src: maintenance.photos.one });
  }
  if (!Utils.isNull(maintenance.photos.two)) {
    slides.push({ src: maintenance.photos.two });
  }
  if (!Utils.isNull(maintenance.photos.three)) {
    slides.push({ src: maintenance.photos.three });
  }
  if (!Utils.isNull(maintenance.photos.four)) {
    slides.push({ src: maintenance.photos.four });
  }

  const handleOpen = (url) => {
    slides.find((i, n) => {
      if (i.src === url) {
        setIndex(n);
      }
    });
  };

  return (
    <>
      <div className='flex gap-2 items-center flex-wrap justify-center md:justify-start'>
        {jobs?.map((job) => {
          jobCounter++;
          return (
            <div key={job.id}>
              <div
                className={`p-2 rounded-lg border border-solid ${
                  job.attributes.isDone
                    ? "bg-green-500/50 border-green-500"
                    : "bg-bg-card-main border-border-card-main"
                }`}
              >
                <div className='pl-1 text-text-main flex items-center gap-1'>
                  <span>{jobCounter}.</span>
                  <span>{job.attributes.job.attributes.nameEL}</span>
                  {!!job.attributes.doneDate && (
                    <span> - {dateFormatter(job.attributes.doneDate)}</span>
                  )}
                  {job.attributes.image && (
                    <DocumentViewer asIcon={true} document={job.attributes.image._url} />
                  )}
                </div>
                {job.attributes.comments && (
                  <div className='pl-4'>
                    <FontAwesomeIcon icon={faCommentDots} size='sm' className='text-text-500' />
                    <span className='text-sm pl-1'>{job.attributes.comments}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {slides.length > 0 && (
        <div className="grid grid-cols-4 gap-2 mt-2">
          {slides.map((s) => (
            <SliderPhoto imgURL={s.src} handleOpen={handleOpen} />
          ))}
        </div>
      )}
      <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
    </>
  );
};

export default MaintenancesRowDetails;
