import { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageMenu = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuHide = () => {
    setTimeout(() => {
      setMenuOpen(false);
    }, 500);
  };

  return (
    <div className="relative">
      <div
        className="flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer transition-all ease-in-out duration-100"
        onClick={() => setMenuOpen(!isMenuOpen)}
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </div>
      {isMenuOpen && (
        <div
          className="absolute z-100 w-max grid grid-cols-1 gap-4 top-9 right-0 p-4 bg-white border border-solid border-gray-200 rounded shadow-lg"
          onMouseLeave={handleMenuHide}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default PageMenu;
