import { useState } from "react";
import Loader from "../../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";

const DocumentWrapper = ({
  title,
  image,
  document,
  notFoundImage,
  handleOpenImage,
  handleSubmitDocument,
}) => {
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleAddFile = (file) => {
    setFile(file);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      handleSubmitDocument(document, file).then((r) => {
        setFile(null);
        setIsEditing(false);
        setLoading(false);
      });
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className='document-wrapper'>
      <Loader isLoading={isLoading} />
      <div className='image-holder'>
        {!!title && (
          <div className='overlay left-2'>
            <span>{title}</span>
          </div>
        )}
        {isEditing ? (
          <>
            <div
              className={`overlay ${
                !!file ? "right-11" : "right-2"
              } cursor-pointer hover:scale-110`}
              onClick={() => setIsEditing(false)}
            >
              <span>
                <FontAwesomeIcon icon={faTimes} className='w-4 h-4' />
              </span>
            </div>
            {!!file && (
              <div
                className='overlay right-2 cursor-pointer hover:scale-110'
                onClick={handleSubmit}
              >
                <span>
                  <FontAwesomeIcon icon={faCheck} className='w-4 h-4' />
                </span>
              </div>
            )}
          </>
        ) : (
          <div
            className='overlay right-2 cursor-pointer hover:scale-110'
            onClick={() => setIsEditing(true)}
          >
            <span>
              <FontAwesomeIcon icon={faPenToSquare} />
            </span>
          </div>
        )}
        {isEditing ? (
          <FileUploadDropzone
            title={null}
            isMultifile={false}
            emitFiles={(file) => handleAddFile(file)}
            acceptedTypes={["jpg", "jpeg", "png"]}
            classes={"absolute h-full md:px-2 md:pt-10 w-full"}
          />
        ) : (
          <img
            src={image ?? notFoundImage}
            alt='user-id-front'
            onClick={() => image && handleOpenImage(image)}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentWrapper;
