import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Renting from "../../../models/Renting";
import ActiveStatusBlinker from "../../../components/ActiveStatusBlinker";

const Status = ({ car }) => {
  const [color, setColor] = useState("");
  const [status, setStatus] = useState("");

  const setCarStatus = () => {
    if (car.isRiding) {
      setStatus("Riding");
      setColor("bg-blue-500");
    } else if (car.underMaintenance) {
      setStatus("Maintenance");
      setColor("bg-orange-500");
    } else if (!car.isAvailable) {
      setStatus("Unavailable");
      setColor("bg-red-500");
    } else {
      setStatus("Available");
      setColor("bg-green-500");
    }
  };

  useEffect(() => {
    setCarStatus();
  }, [car]);

  return (
    <>
      <div className='block'>
        <span
          className={`rounded text-white font-bold p-1 sm:p-1.5 ${color} flex items-center gap-0.5 w-max mx-auto`}
        >
          {status === Renting.STATUS.ACTIVE && <ActiveStatusBlinker color='white' />}
          {Utils.textFirstOnlyUpper(status)}
        </span>
      </div>
    </>
  );
};

export default Status;
